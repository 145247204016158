ul.tabs--primary {
  overflow: hidden;
  text-align: center;
  font-size: 0;
  margin: 20px 0 15px;
  border: 0;

  @include breakpoint($desk) {
    margin: 30px 0 45px;
  }

  li {
    list-style: none;
    float: none;
    list-style-position: outside;
    display: inline-block;


    a {
      display: block;
      text-decoration: none;
      color: #000;
      background-color: $grey;
      border-bottom: 1px solid #000;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 10px 10px;

      &:hover {
        color: $blue;
        background-color: $grey;
        border-bottom: 1px solid $blue;
      }

      @include breakpoint($desk) {
        padding: 13px 20px;
      }
    }

    &.active a {
      background-color: transparent;
      border: 0;
    }
  }
}
