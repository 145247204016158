.node-type-product {
    .stock-notification-btn {
        // margin-bottom: 15px;
        // color: $blue;
        // font-size: 14px;
        // text-decoration: underline;
        background: #8A733D;
        color: white !important;
        border-radius: 2px;
        font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        border: 0;
        outline: none;
        border: 0;
        outline: none;
        width: 200px;
        display: block;
        text-align: center;
        // margin: 0 20px;
        // padding: 0 20px;
        margin-top: 33px;
    }
    .rocket-path-stock-notifications-customer-form {
        position: fixed;
        z-index: 499;
        background: rgba(228, 228, 228, 0.6);
        border: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none!important;
        &.opened {
            display: block!important;
        }
        .rocket_path_stock_notifications_fieldset {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            background: #fff;
            border: 1px solid #E1E1E1;
            padding: 30px;
            // top: 20%;
            max-width: 95%;
            @media only screen and (min-width: 768px) {
                top: 60%;
                max-width: 50%;
            }
            .stock-pop-up {
                h2 {
                    line-height: 27px;
                    font-size: 18px;
                    padding: 5px 5px 5px 5px;
                    border-bottom: 1px solid #212C4B;
                    margin-bottom: 10px;
                    font-weight: bold;
                    color: #212C4B;
                    display: block;
                    position: relative;
                    .close-pop-up {
                        display: block;
                        text-decoration: none;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 10;
                        .line {
                            display: block;
                            width: 100%;
                            height: 2px;
                            background: $blue;
                            position: absolute;
                            top: 15px;
                            &:first-child {
                                -ms-transform: rotate(45deg);
                                -webkit-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                            &:last-child {
                                -ms-transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }
                ul {
                    paddin-left: 20px;
                }
                li,
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
                p {
                    margin-bottom: 5px;
                }
                .form-wrapper {
                    .form-radios {
                        label {
                            color: #fff;
                            font-size: 14px;
                            margin-bottom: 20px;
                            @include breakpoint($tab) {
                                display: inline-block;
                                margin-right: 17px;
                                color: $black;
                                font-size: 16px;
                                margin-bottom: 4px;
                            }
                            &:after {
                                display: none;
                            }
                        }
                        &>label {
                            font-size: 12px;
                            color: $blue;
                            margin: 0;
                            margin: 10px 0;
                            line-height: 18px;
                            @include breakpoint($tab) {
                                font-size: 14px;
                                line-height: 20px;
                                text-align: left;
                                display: block;
                                margin: 0 0 10px 0;
                            }
                        }
                        .form-item {
                            display: inline-block;
                            label {
                                color: $main;
                                border: 1px solid $main;
                                cursor: pointer;
                                padding: 10px 18px;
                                display: inline-block;
                                margin-bottom: 10px;
                                margin-right: 10px;
                                font-size: 14px;
                                line-height: 14px;
                                font-weight: bold;
                                &:hover {
                                    transition: all .2s;
                                    background: $main;
                                    color: #fff;
                                }
                                &:before {
                                    display: none;
                                }
                            }
                            input[type="radio"]:checked+label {
                                font-weight: bold;
                                color: #fff;
                                background: $main;
                            }
                        }
                    }
                }
                .form-submit {
                    float: none;
                    position: relative;
                }
                .form-type-textfield input.form-text {
                    width: 100%;
                }
            }
        }
    }
    .rocket_path_stock_notifications_fieldset {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 60%;
        left: 50%;
        background: #fff;
        border: 1px solid #E1E1E1;
        padding: 30px;
        width: 100%;
        max-width: 360px;
        .stock-pop-up {
            h2 {
                line-height: 27px;
                font-size: 18px;
                padding: 0 0 5px;
                border-bottom: 1px solid #212C4B;
                margin-bottom: 10px;
                font-weight: bold;
                color: #212C4B;
                display: block;
                position: relative;
                .close-pop-up {
                    display: block;
                    text-decoration: none;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 10;
                    .line {
                        display: block;
                        width: 100%;
                        height: 2px;
                        background: $blue;
                        position: absolute;
                        top: 15px;
                        &:first-child {
                            -ms-transform: rotate(45deg);
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                        &:last-child {
                            -ms-transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            ul {
                paddin-left: 20px;
            }
            li,
            p {
                font-size: 14px;
                line-height: 18px;
            }
            p {
                margin-bottom: 5px;
            }
            .form-wrapper {
                .form-radios {
                    label {
                        color: #fff;
                        font-size: 14px;
                        margin-bottom: 20px;
                        @include breakpoint($tab) {
                            display: inline-block;
                            margin-right: 17px;
                            color: $black;
                            font-size: 16px;
                            margin-bottom: 4px;
                        }
                        &:after {
                            display: none;
                        }
                    }
                    &>label {
                        font-size: 12px;
                        color: $blue;
                        margin: 0;
                        margin: 10px 0;
                        line-height: 18px;
                        @include breakpoint($tab) {
                            font-size: 14px;
                            line-height: 20px;
                            text-align: left;
                            display: block;
                            margin: 0 0 10px 0;
                        }
                    }
                    .form-item {
                        display: inline-block;
                        label {
                            color: $main;
                            border: 1px solid $main;
                            cursor: pointer;
                            padding: 10px 18px;
                            display: inline-block;
                            margin-bottom: 10px;
                            margin-right: 10px;
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: bold;
                            &:hover {
                                transition: all .2s;
                                background: $main;
                                color: #fff;
                            }
                            &:before {
                                display: none;
                            }
                        }
                        input[type="radio"]:checked+label {
                            font-weight: bold;
                            color: #fff;
                            background: $main;
                        }
                    }
                }
            }
            .form-submit {
                float: none;
                position: relative;
            }
            .form-type-textfield input.form-text {
                width: 100%;
            }
        }
    }
}