.page-checkout {
  @include pie-clearfix;

  .l-page {
    overflow: hidden;
  }

  .commerce-paypal-icon {
    top: 0;
  }

  .l-main .content-wrapper {
    @include breakpoint($desk) {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
    }
  }

    .view-checkout-cart-summary {
      width: 100%;
      margin-bottom: 40px;

      @include breakpoint($desk) {
        margin: 0;
        float: right;
        width: 50%;
      }
    }

    .commerce-checkout-form-checkout,
    .commerce-checkout-form-shipping,
    .commerce-checkout-form-review {
      width: 100%;

      .checkout-help {
        display: none;
      }

      @include breakpoint($desk) {
        float: left;
        width: 50%;
        padding-right: 50px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
      }
    }


  .l-footer {
    margin: 0;
  }

}

.page-checkout-complete {
  .l-main .content-wrapper {
    display: block;

    @include breakpoint($desk) {
      display: block;
    }
  }
}

.page-checkout .view-checkout-cart-summary {
  padding-top: 20px;
  background: $grey;
  position: relative;

  &:after {
    content: "";
    @include breakpoint($desk) {
      display: block;
      width: 300%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: $grey;
      z-index: -1;
      box-shadow: 0 -1px 0 #e1e1e1 inset;
    }
  }

  .view-content {
    padding-left: 40px;
  }

  table {
    margin-top: 0;
  }

  tr:nth-child(2n+1) {
    background: transparent;
  }

  .views-field-title {
    width: 100%;
    opacity: 0.6;

    h4 {
      font-size: 18px;
    }

    span {
      font-size: 13px;
    }
  }

  .price {
    font-size: 17px;
  }

  .views-field-field-product-image {
    padding-left: 0;

    & > div {
      position: relative;
    }

    .quantity {
      position: absolute;
      left: 55px;
      background: rgba(0, 0, 0, 0.45);
      color: #fff;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      top: -10px;
    }
  }

  .view-footer {
    border-top: 1px solid #ccc;
    margin-left: 40px;

    .component-title {
      font-size: 18px;
      opacity: 0.5;
    }

    .component-total {
      font-size: 24px;
      font-weight: 400;
    }
  }
}

.page-checkout.i18n-en fieldset#edit-terms-conditions .messages--error:before {content: "Please accept the Terms of Service to proceed";font-size: 15px;width:  100%;height:  20px;}

.page-checkout.i18n-en fieldset#edit-terms-conditions .messages--error {
  font-size: 0;
}

.commerce-checkout-form-checkout,
.commerce-checkout-form-shipping,
.commerce-checkout-form-review {
  @include pie-clearfix;

  .vertical-tabs {
    display: none;
  }

  legend {
    padding-bottom: 10px;
  }

  & > div {
    @include clearfix;
  }

  & > div > fieldset,
  & > div > div {
    margin-bottom: 20px;
    width: 100%;
  }

  input[type="radio"] {
    display: inline-block;
    margin: 0;
  }

  .form-radios label {
    display: inline-block;
    margin-bottom: 15px;

    &.error {
      color: #e72a2a;
      text-transform: none;
      font-weight: normal;
    }
  }

  table.checkout-review {
    margin: 0;

    .field--name-field-prefecture {
      .field__label {
        font-weight: 100;
      }
    }
  }

  .breadcrumb {
    background: transparent;
    padding-left: 0;
  }

  tr.pane-title {
    background: transparent;

    td {
      font-size: 18px;
      padding-left: 0;
      padding: 0;
    }
  }

  .form-type-select {
    position: relative;

    label {
      font-size: 13px;
      color: $black;
      margin-bottom: 5px;

      &.error {
        color: #e72a2a;
        text-transform: none;
        font-weight: normal;
      }
    }
  }

  .form-type-textfield {

    label {
      font-size: 13px;
      color: $black;
      margin-bottom: 5px;

      &.error {
        color: #e72a2a;
        text-transform: none;
        font-weight: normal;
      }
    }
  }

  .commerce_coupon {
    @include pie-clearfix;

    .form-item-commerce-coupon-coupon-code {
      float: left;
      width: 80%;
      padding-right: 20px;
    }

    .form-submit {
      float: left;
      width: 20%;
      margin-top: 20px;
      line-height: 37px;
    }
  }
}

.group-invoice {
  display: none;
}

.commerce-checkout-form-checkout .sticky-wrapper {
  float: right;
  width: 311px;
  z-index: 2;
}

.commerce-checkout-form-checkout > div .cart-contents-wrapper,
.commerce-checkout-form-shipping > div .cart-contents-wrapper,
.commerce-checkout-form-review > div .cart-contents-wrapper {
  z-index: 500;
  background: #fff;
  width: 100%;

  @include breakpoint($tab) {
    float: right;
    width: 311px;
  }

  .cart_contents {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.24);

    .view-commerce-cart-summary {
      .form-actions {
        .form-submit {
          display: none;
        }
      }
    }
  }

  legend {
    font-size: 18px;
    color: $indigo;
    text-align: center;
    line-height: 52px;
    border-bottom: 1px solid $indigo;
    background: #000000;
  }

  .line-item-quantity {
    position: absolute;
    top: -32px;
    right: 0;

    .line-item-quantity-label {
      display: none;
    }
    .line-item-quantity-raw {
      background: $indigo;
      font-size: 10.5px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 50%;
    }
  }

  .line-item-quantity {
    position: absolute;
    top: -32px;
    right: 0;

    .line-item-quantity-label {
      display: none;
    }
    .line-item-quantity-raw {
      background: $indigo;
      font-size: 10.5px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 50%;
    }
  }

  .links {
    text-align: center;
    margin: 20px 0 0;
  }

  .line-item-summary-view-cart {
    display: none;
  }

  .line-item-summary {
    margin: 0 0 20px;
    clear: both;
  }

  .line-item-summary-checkout {
    display: inline-block;
    float: none;
    margin: 0;

    a {
      background: $indigo;
      padding: 15px 20px;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      display: inline-block;
      border-radius: 20px;

      &:hover {
        text-decoration: none;
        color: $indigo;
        background: #000;
      }
    }
  }

  .commerce-price-formatted-components {
    background: transparent;
    width: 100%;
    margin-bottom: 20px;

    tr {
      background: transparent;
    }
  }

  .view-content {
    margin: 0;
    padding: 10px 20px 0;

    table.views-table {
      margin: 0 0 5px;
      max-height: 160px;
      overflow-y: scroll;
      display: block;
    }
  }

  .views-row {
    @include clearfix();
    margin: 0 0 15px;
    position: relative;

    &:before {
      width: 100%;
      height: 1px;
      bottom: 7px;
      background: #ddd;
      left: 0;
      clear: both;
      position: absolute;
      display: table;
      content: '';
    }

    .views-field-edit-delete {
      top: 0;
      right: 0;
      position: absolute;

      .field-content:before {
        @extend .font-icon;
        @extend .icon-close;
        font-size: 11px;
      }

      input[type="submit"] {
        padding: 0;
        font-size: 0;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .views-field-commerce-total,
    .views-field-field-product-image,
    .views-field-quantity,
    .views-field-line-item-title {
      display: inline-block;
      float: left;
      max-width: 220px;
    }

    .views-field-line-item-title {
      font-size: 14px;
      font-weight: 400;
      width: 190px;
      margin-bottom: 8px;
    }

    .views-field-quantity {
      font-size: 12px;
      font-weight: 300;
      color: $grey;
    }

    .views-field-field-product-image {
      padding-right: 25px;
      background: #fff;
      position: relative;
      z-index: 1;
    }

    .views-field-commerce-total {
      float: right;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.commerce-checkout-form-checkout > div {
  display: flex;
  flex-wrap: wrap;

  .breadcrumb {
    order: 1;
  }

  .account.form-wrapper {
    order: 2;
  }

  #customer-profile-shipping-ajax-wrapper {
    order: 3;

    input[name="pane-customer_profile_shipping-cancel"] {
      margin-bottom: 20px;
    }

    .field--name-field-prefecture {
      .field__label {
        font-weight: 100;
      }
    }
  }

  #customer-profile-billing-ajax-wrapper {
    order: 5;

    .field--name-field-prefecture {
      .field__label {
        font-weight: 100;
      }
    }
  }

  .commerce_fieldgroup_pane__group_invoice {
    order: 4;
  }

  .checkout-buttons.form-wrapper {
    order: 6;
  }
}

.checkout_completion_message {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  padding: 100px 0;
}

.checkout-buttons {
  text-align: center;
  margin-top: 30px;

  .checkout-continue {
    margin: 0 auto 5px;
    display: block;
  }
}

.commerce-checkout-form-review {
  margin-bottom: 100px;

  .checkout-help {
    margin: 50px 0;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
  }

  .checkout_review {
    padding: 0 20px;
    width: 100%;
    max-width: 680px;
    display: block;
    margin: 0 auto;

    .checkout-review {
      width: 100%;
      border: 1px solid #f8f8f8;

      .pane-title {
        font-size: 18px;
        text-align: center;
        line-height: 52px;
        background: #f8f8f8;
      }

      .pane-data {
        background: transparent;

        & > td {
          padding: 20px;
        }

        font-size: 16px;

        .form-item {
          width: 50%;
          display: inline-block;
        }
      }


      .view-commerce-cart-summary {
        .commerce-order-handler-area-order-total {
          .commerce-price-formatted-components {
            width: 50%;
          }
        }

        .line-item-quantity {
          position: absolute;
          top: -175px;
          right: -25px;

          .line-item-quantity-label {
            display: none;
          }
          .line-item-quantity-raw {
            background: $indigo;
            font-size: 10.5px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            width: 18px;
            height: 18px;
            display: inline-block;
            border-radius: 50%;
          }
        }

        .links {
          text-align: center;
          margin: 20px 0 0;
        }

        .line-item-summary-view-cart {
          display: none;
        }

        .line-item-summary {
          margin: 0 0 20px;
          clear: both;
          position: relative;
        }

        .line-item-summary-checkout {
          display: inline-block;
          float: none;
          margin: 0;

          a {
            background: $indigo;
            padding: 15px 20px;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            display: inline-block;
            border-radius: 20px;

            &:hover {
              text-decoration: none;
              color: $indigo;
              background: #000;
            }
          }
        }

        .views-row {
          @include clearfix();
          margin: 0 0 15px;
          position: relative;

          &:before {
            width: 100%;
            height: 1px;
            bottom: 7px;
            background: #ddd;
            z-index: -1;
            left: 0;
            clear: both;
            position: absolute;
            display: table;
            content: '';
          }

          .views-field-edit-delete {
            top: 0;
            right: 0;
            position: absolute;

            .field-content:before {
              @extend .font-icon;
              @extend .icon-close;
              font-size: 11px;
            }

            input[type="submit"] {
              padding: 0;
              font-size: 0;
              background: transparent;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          .views-field-commerce-total,
          .views-field-field-product-image,
          .views-field-quantity,
          .views-field-line-item-title {
            display: inline-block;
            float: left;
            max-width: 220px;
          }

          .views-field-line-item-title {
            font-size: 14px;
            font-weight: 400;
            width: calc(100% - 200px);
            max-width: initial;
            margin-bottom: 8px;
          }

          .views-field-quantity {
            width: calc(100% - 400px);
            max-width: initial;
            font-size: 12px;
            font-weight: 300;
            color: $grey;
          }

          .views-field-field-product-image {
            padding-right: 25px;
            background: #fff;
          }

          .views-field-commerce-total {
            float: right;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

table.checkout-review .pane-data-full {
  padding-left: 0;
  padding-top: 0;
}

td.views-field.views-field-edit-quantity.cart-quantity input {
  box-shadow: 0 0 0 1px #d9d9d9;
  border: 0;
  background: transparent;
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  width: 100%;
  resize: none;
  border-radius: 2px;
  max-width: 39px;
  text-align: center;
  margin: 0 auto;
  vertical-align: middle;
}

.commerce-line-item-views-form {

  .views-field-edit-delete {
    input {
      background: transparent;
      color: transparent;
      background-image: url(../images/icons/close.svg);
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      background-size: contain;
    }
  }

  .title-image-wrapper {

    .title-wrapper {
      display: inline-block;
    }

    .cart-image-wrapper {
      float: left;
      margin-right: 20px;
      display: inline-block;
      position: relative;

      @include breakpoint($tab) {
        float: none;
      }

      span.quantity {
        position: absolute;
        font-size: 12px;
        display: block;
        right: -10px;
        background: rgba(0, 0, 0, 0.45);
        color: #fff;
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        top: -10px;
      }
    }
  }

  .form-actions {
    input {
      background: #000;
      width: 100%;
      margin: 0 0 10px;

      @include breakpoint($tab) {
        margin: 0 0 0 20px;
        min-width: 200px;
        width: auto;
      }
    }

    #edit-checkout {
      background: $main;
    }
  }
}

.cart-empty-page {
  margin: 10px auto 100px;
  text-align: center;
  font-size: 20px;
}

.view-commerce-cart-block td.price,
.view-commerce-cart-form td.price,
.view-commerce-cart-summary td.price {
  white-space: nowrap;
  padding-right: 10px;
}


.form-item.form-type-textarea.form-item-commerce-fieldgroup-pane--group-comments-field-order-comments-und-0-value label {
 display: none;
}

.field-name-commerce-customer-billing {
  display: none;
}

.field--name-field-invoice {
  margin: 0 0 10px;
}
