
.espa-banner-popout {
    position: fixed;
    // top: 0;
    // right: 0;
    // bottom: unset;
    z-index: 499;
    display: block;
    // width: 100%;
    width: fit-content;
    background: rgba(255, 255, 255, 0.94);
    // padding: 15px 20px;
    color: #6e6e6e;
    text-align: center;
    box-shadow: 0 2px 10px rgba(54, 54, 54, 0.15);
  
    // @include breakpoint($tab) {
    top: unset;
    bottom: 10px;
    right: 5px;
    @include breakpoint($tab) {
      right: 20px;
    }
    padding: 20px;
    width: fit-content;
    // }
  
    img {
      width: auto;
      height: auto;
      padding-right: 20px;
      max-width: 160px;
    }
  
    a {
      width: auto;
      height: auto;
    }
    a:hover {
      display: unset;
    }
    .close-banner {
      position: absolute;
      font-size: 0;
      cursor: pointer;
      // top: 0;
      // left: 10px;
  
      // @include breakpoint($tab) {
      top: 20px;
      right: 35px;
      left: unset;
      // }
  
      &:before {
        content: "";
        background-image: url("/sites/all/themes/custom/mazeblock_theme/images/espa/close.svg");
        // width: 7px;
        // height: 7px;
        background-size: 7px;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        position: absolute;
        // top: 5px;
  
        //  @include breakpoint($tab) {
        width: 15px;
        height: 15px;
        background-size: 15px;
        //  }
      }
    }
  }

.espa-banner-popout.closed{
    display: none !important;
}
