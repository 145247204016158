.block--kessaris-kessaris-category-banner {
	.block__title {
		display: none;
	}
	.term-info-highlighted-wrapper {
		border-bottom: 1px solid #E1E1E1;

		.term-info-banner {
			width: 100%;
			// height: 150px;
   //    background-size: cover;
   //    background-repeat: no-repeat;
   //    background-position: center;

   //    @include breakpoint($tab) {
   //      height: 200px;

  	// 		@include breakpoint($desk) {
  	// 			width: auto;
   //        height: 300px;
  	// 		}
   //    }

			img {
				width: 100%;
				height: auto;
			}
		}
		.term-info-banner-wrapper {
			position: relative;

			h2 {
		    margin: 0;
		    font-size: 40px;
		    line-height: 80px;
			}

			&.has_logo {
				display: none;

				@include breakpoint($tab) {
					display: block;
				}
			}
		}

  }

  .term-description {
    padding: 15px 0;
    text-align: center;
    font-size: 16px;
    margin-top: 28px;
    color: #000000;
    display: block;
    font-weight: 400;
    border: 1px solid #d7d7d7;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    p {
      margin: 0;
    }

    a {
      opacity: 0.5;

      &.logo_link {
  			opacity: 1;
  		}
    }
  }

  .term-description-image {
  	&.has_logo {
	    img {
		    max-width: 190px;
		    margin-top: 15px;

		    @include breakpoint($tab) {
		    	max-width: unset;
		    }
		  }
		}
  }

  .term-description-text {
	  .term-description-text-inner {
	    max-height: 100px;
    	overflow: hidden;
    	position: relative;

    	@include breakpoint($tab) {
    		max-height: unset;
    		overflow: unset;
    	}

    	a {
				color: #8A733D; /* #8A733D;*/
    		opacity: 1;
    	}

    	&:before {
    	  content: '';
		    width: 100%;
		    height: 25px;
		    display: block;
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    background: rgba(255,255,255,0.7);

    		@include breakpoint($tab) {
	    		display: none;
	    	}
    	}
	  }

	  .read-wrapper {
	  	.show-details {
	  		display: block;
	  		margin-top: 10px;
        text-decoration: underline;

	  	}

	  	.hide-details {
	  		display: none;
	  	}
	  }

	  &.open {
	  	.term-description-text-inner {
	  		max-height: 100%;
	  		overflow: unset;

	  		&:before {
		    	display: none;
	    	}
	  	}

      .read-wrapper {
		  	.show-details {
		  		display: none;
		  	}

		  	.hide-details {
	    		display: block;
	    		text-decoration: underline;

	    	}
	    }
	  }
  }

	.page-taxonomy-term {
		h1 {
			display: none;
		}
	}

	.view-taxonomy-term {
		margin: 60px auto;
	  max-width: 1000px;
	}
}

#mazeblock-elastic-page-wrapper {
  margin: 0 auto 20px;

	.mazeblock-elastic-facets-inner {
		display: none;

		@include breakpoint($tab) {
			display: block;
			float: left;
			width: 100%;
			border-bottom: 1px solid #e6e6e6;
			margin-bottom: 35px;
		}

    .mazeblock-elastic-facets-label {
			display: none;

			@include breakpoint($tab) {
	    	@include pie-clearfix;
				display: inline-block;
				float: left;
		    font-size: 15px;
		    line-height: 22px;
		    margin: 28px 0;
	      color: $main;
	      font-weight: 400;
	    }
    }

		.facet-wrapper {
      width: 100%;
	    float: none;
	    margin: 0;
	    clear: both;
	    display: block;

	    @include breakpoint($tab) {
				@include pie-clearfix;
				display: inline-block;
				float: left;
		    font-size: 13px;
		    line-height: 20px;
		    margin: 20px;
		    margin-right: 0;
        width: auto;
        clear: none;
		  }

      .facet-inner {
				.facet-title {
					line-height: 19px;
			    padding: 10px 20px;
			    font-size: 15px;
			    /*font-family: Helvetica, Arial, Roboto, sans-serif;*/
			    width: 100%;
			    margin: 0;
			    border-bottom: 1px solid #F8F8F8;
			    background: #000;
			    color: #fff;
			    position: relative;

          @include breakpoint($tab) {
						font-weight: 100;
				    font-weight: 400;
				    padding: 10px 25px 4px 10px;
				    text-transform: lowercase;
				    display: inline-block;
				    cursor: pointer;
				    background: #fff;
				    color: #000;
				    border-bottom: 0;
          }

			    &:before {
		     	  @extend .icon-keyboard_arrow_down;
		     	  @extend .font-icon;
				    position: absolute;
     	      right: 5px;
				    top: 14px;
				    color: #fff;

				    @include breakpoint($tab) {
							color: $main;
				    }
					}
				}

				.facet-content {
					display: none;
					ul {
						display: block;
						list-style: none;
						padding: 0;
						margin-bottom: 0;

						li {
							a {
								font-size: 13px;

								&:hover {
									text-decoration: none;
								}

                &[data-count="0"] {
                  display: none;
                }

								.facet-count {
							    font-size: 11px;
									padding-left: 5px;

									@include breakpoint($tab) {
							    		opacity: 0.7;
									}
								}
							}
						}
					}
				}
			}

			&.category-active .facet-inner {
				.facet-title {

					@include breakpoint($tab) {
						color: #fff;
				    font-weight: 400;
				    background-color: #282828;
				    padding: 10px 25px 4px 10px;
				  }

					&:before {
						color: #fff;
						-webkit-transform: rotate(180deg);
				    -moz-transform: rotate(180deg);
				    -o-transform: rotate(180deg);
				    -ms-transform: rotate(180deg);
				    transform: rotate(180deg);
					}
				}
				.facet-content {
					display: block;
					overflow-y: scroll;
					max-height: 200px;

					@include breakpoint($tab) {
						position: absolute;
				    padding: 18px;
				    background: #282828;
				    z-index: 20;
				    overflow: hidden;
				    max-height: 100%;
					}

			    .checked {
			    	color: #000;

			      @include breakpoint($tab) {
			        font-weight: bold;
			      	color: #fff;
			      }
			    }

			    a {
			    	color: #000;
			    	padding: 0 20px;
				    font-size: 14px;
				    line-height: 39px;
				    border-bottom: 1px solid #000;
				    display: block;
				    width: 100%;
            text-transform: capitalize;

            &[data-count="0"] {
              display: none;
            }

			    	@include breakpoint($tab) {
							color: #fff;
							border-bottom: 0;
							width: auto;
							padding: 0;
							line-height: 20px;
			    	}
			    }
				}

				.facet-content::-webkit-scrollbar {
					-webkit-appearance: none;
					width: 7px;
				}
				.facet-content::-webkit-scrollbar-thumb {
					border-radius: 4px;
					background-color: rgba(0,0,0,.5);
					box-shadow: 0 0 1px rgba(255,255,255,.5);
				}
			}
	  }

	  .mazeblock-elastic-total {
  		font-weight: 400;
	    padding: 10px 0;
	    text-transform: lowercase;
	    text-align: center;
	    color: $main;
	    line-height: 19px;
	    font-size: 15px;
      width: 100%;
	  }

	  .left-header {
	  	display: inline-block;
	    float: left;
      width: 100%;

      @include breakpoint($tab) {
		    width: 80%;
		  }
	  }

	  .right-header {
	  	display: inline-block;
	    float: left;
	    width: 100%;

	    @include breakpoint($tab) {
		    width: 20%;
		  }
	  }


	  .rocket-path-elastic-sort {
      position: relative;
      margin: 20px;

	    @include breakpoint($tab) {
	    	display: inline-block;
	    	float: right;
	    }

	    label {
	    	font-weight: 400;
		    line-height: 19px;
		    font-size: 15px;
		    text-align: center;
		    color: #000;
		    display: block;
		    position: relative;
		    padding: 10px 25px 4px 10px;
		    position: relative;
		    margin: 0;
		    cursor: pointer;

		     &:before {
	     	  @extend .icon-keyboard_arrow_down;
	     	  @extend .font-icon;
			    position: absolute;
   	      right: 5px;
			    top: 14px;
			    color: #fff;

			    @include breakpoint($tab) {
						color: $main;
			    }
				}
	    }

	    ul {
	    	background: #282828;
		    min-width: 181px;
		    z-index: 10;
		    margin: 0;
		    position: absolute;
		    display: none;
		    padding: 15px 20px;
		    right: 0;

		    li {
		      display: inline-block;
		      width: 100%;

			    a {
	    	    color: #fff;
				    border-bottom: 0;
				    width: auto;
				    padding: 0;
				    line-height: 20px;
		        font-size: 14px;

		        &:hover {
		        	text-decoration: none;
		        }
			    }

			    &.active a {
			    	font-weight: 700;
			    }
			  }
	    }

	    &.open-sort {

	    	label {
    	    color: #fff;
			    font-weight: 400;
			    background-color: #282828;
			    padding: 10px 25px 4px 10px;

			     &:before {
     	      color: #fff;
            transform: rotate(180deg);
					}
		    }

				ul {
					display: block;
				}
			}
	  }

	  .mazeblock-elastic-active_filters {
			padding: 0;
			clear: both;
			float: left;
			width: 100%;
			padding: 0 20px;
			background: #F5F5F5;
			line-height: 36px;

			@include breakpoint($tab) {
			  display: block;
			  padding: 0 0 20px;
			  width: auto;
			  background: #fff;
        line-height: 20px;
			}

			ul {
				width: 100%;
				list-style: none;
		    margin: 0;
		    float: left;
		    padding: 0;

		    @include breakpoint($tab) {
				  width: auto;
				}

				li {
			    display: inline-block;
			    float: left;
			    padding-right: 15px;
			    width: 100%;
			    line-height: 36px;
			    font-size: 12px;
			    border-top: 1px solid #E1E1E1;
			    position: relative;

          @include breakpoint($tab) {
				    padding-right: 15px;
            padding-left: 0;
				    border-top: 0;
				    width: auto;
				    line-height: 17px;
				  }

					[data-key] {
						padding-right: 18px;
						display: inline-block;

						@include breakpoint($tab) {
							position: relative;
						}
					}

					.remove-facet {
						display: block;
				    float: right;
				    text-decoration: none;
				    width: 11px;
				    height: 11px;
				    position: absolute;
				    cursor: pointer;
				    top: 12px;
            right: 10px;

						@include breakpoint($tab) {
	            top: 3px;
					    right: 0;
					  }

            .line {
				      display: block;
				      width: 100%;
				      height: 2px;
				      background: $base;
				      position: absolute;
				      top: 5px;

				      &:first-child {
				        -ms-transform: rotate(45deg);
				        -webkit-transform: rotate(45deg);
				        transform: rotate(45deg);
				      }

				      &:last-child {
				        -ms-transform: rotate(-45deg);
				        -webkit-transform: rotate(-45deg);
				        transform: rotate(-45deg);
				      }
				    }
					}
				}
			}
	  }
	}

	.mobile-facets .mazeblock-elastic-facets-inner {
		position: fixed;
		display: block;
		height: 100%;
		z-index: 115;
		width: 100%;
		top: 0;
		left: 0;
		background: #fff;
		overflow: scroll;
		padding-bottom: 30px;
	}

  //Mobile filters

	.mazeblock-elastic-mob-header {
		padding-top: 20px;
    margin-bottom: 20px;

    .mazeblock-elastic-total {
	    padding-bottom: 20px;
    	text-align: center;
    	color: $main;
    }

    .mazeblock-elastic-facets-label-wrapper {
    	width: 50%;
    	display: inline-block;
    	padding-right: 2px;

			.mazeblock-elastic-facets-label {
			  width: 100%;
		    text-align: center;
		    line-height: 30px;
		    font-size: 12px;
		    border-bottom: 5px solid #000;
		    position: relative;

		    &:before {
		      @extend .icon-keyboard_arrow_down;
		      @extend .font-icon;
		      position: absolute;
		      right: 0;
		      top: 0;
		      color: #000;
		      font-size: 28px;
		    }
			}
		}

	  .rocket-path-elastic-sort-mobile {
			float: right;
			width: 50%;
			text-align: right;
	    line-height: 30px;
	    padding-left: 2px;

			label {
		    text-align: center;
		    line-height: 30px;
		    font-size: 12px;
		    border-bottom: 5px solid #000;
		    display: inline-block;
		    position: relative;
		    width: 100%;
		    font-weight: 100;

		    &:before {
    	    @extend .icon-keyboard_arrow_down;
	        @extend .font-icon;
	        position: absolute;
	        right: 0;
	        top: 0;
	        color: #000;
	        font-size: 28px;
		    }
			}


			.rocket-path-elastic-sort-overlay {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				display: none;
				z-index: 140;
				background: #fff;
				margin: 0;

        .mazeblock-elastic-sort-title {
					@extend .minion;
					width: 100%;
					text-align: center;
					position: relative;
					font-size: 16px;
					color: #000;
					line-height: 57px;
					border-bottom: 1px solid #E1E1E1;

					a.close-filters {
				    display: block;
				    float: left;
				    text-decoration: none;
				    width: 15px;
				    height: 15px;
				    position: absolute;
				    top: 20px;
				    left: 20px;
				    z-index: 10;
				    text-indent: -99999px;

				    .line {
				      display: block;
				      width: 100%;
				      height: 2px;
				      background: $base;
				      position: absolute;
				      top: 7px;

				      &:first-child {
				        -ms-transform: rotate(45deg);
				        -webkit-transform: rotate(45deg);
				        transform: rotate(45deg);
				      }

				      &:last-child {
				        -ms-transform: rotate(-45deg);
				        -webkit-transform: rotate(-45deg);
				        transform: rotate(-45deg);
				      }
				    }
				  }
				}

				ul {
					margin: 0;
					padding: 0;
				}

				li {
					list-style: none;
					display: block;
					width: 100%;
					text-align: left;

					&.active {

						a {
							text-decoration: underline;
						}
					}

					a {
						font-size: 14px;
					    line-height: 39px;
					    display: block;
					    width: 100%;
					    position: relative;
					    color: #000;
					    font-weight: 100;
					    padding: 0 20px;
					}
				}

				&.open-sort {
					display: block;
				}
			}
		}
	}

  .mazeblock-elastic-facets-term-title {
		line-height: 40px;
		font-size: 16px;
		padding: 0 20px;
		border-bottom: 1px solid #e6e6e6;
	}

  .mazeblock-elastic-facets-mob-header {
		.mazeblock-elastic-facets-active,
		.mazeblock-elastic-facets-filter-by {
			line-height: 36px;
			font-size: 12px;
			padding: 0 20px;
			border-top: 1px solid #E1E1E1;
		}

		.mazeblock-elastic-facets-active {
			color: #E1E1E1;
		}

		.mazeblock-elastic-facets-title {
			@extend .minion;
			width: 100%;
			text-align: center;
			position: relative;
			font-size: 16px;
			color: #000;
			line-height: 57px;

			 a.close-filters {
		    display: block;
		    float: left;
		    text-decoration: none;
		    width: 15px;
		    height: 15px;
		    position: absolute;
		    top: 20px;
		    left: 20px;
		    z-index: 10;
		    text-indent: -99999px;

		    .line {
		      display: block;
		      width: 100%;
		      height: 2px;
		      background: $base;
		      position: absolute;
		      top: 7px;

		      &:first-child {
		        -ms-transform: rotate(45deg);
		        -webkit-transform: rotate(45deg);
		        transform: rotate(45deg);
		      }

		      &:last-child {
		        -ms-transform: rotate(-45deg);
		        -webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
		      }
		    }
		  }
		}
	}

	.mazeblock-elastic-facets-apply {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		line-height: 40px;
		padding: 0 20px;
		text-align: center;
		background: $black;
		color: #fff;
		font-size: 14px;
	}

	.mazeblock-elastic-footer {
		text-align: center;
    margin-top: 40px;

		.mazeblock-elastic-pager {
		  label {
		  	display: none;
		  }

			.facet-pager {
				padding: 0;

			 li {
			    padding: 0;
			    display: inline-block;
			  }

			  .pager-link a {
			    background-color: #fff;
			    border-radius: 0;
			    padding: 0 15px;
			    display: inline-block;
			    min-width: 30px;
			    line-height: 32px;
			    text-align: center;
			    color: #000;

			    &:hover,
			    &:focus {
			      background-color: #000;
			      color: #fff;
				    text-decoration: none;
			    }
			  }

			  .active-page a {
			    background-color: #000;
			    border-radius: 0;
			    padding: 0 15px;
			    display: inline-block;
			    min-width: 30px;
			    line-height: 32px;
			    text-align: center;
			    color: #fff;
			  }

			  .previous-page,
			  .next-page {
			    background-color: #fff;
			    color: #000;
			    line-height: 32px;

			    &:hover,
			    &:focus {
			    	a {
				      color: #fff;
				      background-color: #000;

				      &:hover {
				      	text-decoration: none;
				      }
				    }
			    }
			  }
			}
		}
	}
}

//Search page
body.with-overlay {
  // overflow: hidden;
}

.search-overlay {
  display: none;
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  // height: 100%;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 1000;

  &.show {
    display: block;
  }

  .mazeblock-elastic-instant-search-wrapper {
    padding: 27px 20px 0;

		.mazeblock-elastic-pager {
			display: none;
		}

    .mazeblock-elastic-instant-search-container {
			position: relative;
    }

    .mazeblock-elastic-instant-logo {
      width: 200px;
      display: inline-block;
      float: left;
      margin-top: 4px;

      @include breakpoint($tab) {
        width: 30%;

      	@include breakpoint($desk) {
	        width: 306px;
	        margin-right: 55px;
	        margin-top: 0;
	      }
	    }

      img {
        width: 100%;
        height: auto;
        max-width: 306px;
      }
    }

    .search-input-wrapper {
      width: 100%;
      display: block;
      border-bottom: 1px solid $black;
      margin: 0 auto;
      clear: both;
	    position: relative;

      @include breakpoint($tab) {
      	width: 50%;
      	display: inline;
	      float: left;
	      clear: none;
	      margin: 15px 50px 0;

	      @include breakpoint($desk) {
	        margin: 0;
	    		margin-top: 35px;
	    	}
      }

      @include breakpoint($wide) {
        width: 600px;
      }

      input[type="text"] {
        box-shadow: none;

        &:hover {
        	box-shadow: none;
        }
      }

      .form-text {
        background: #fff;
        padding: 7px 0 15px;
        line-height: 16px;
        font-size: 14px;
      }

      &:before {
        @extend .font-icon;
        @extend .icon-search;
        color: $black;
        font-size: 20px;
        top: 15px;
        right: 0;
        position: absolute;
        line-height: 0;
      }
    }

    a.mazeblock-elastic-instant-search-close {
      display: block;
	    float: right;
	    text-decoration: none;
	    width: 15px;
	    height: 15px;
	    position: absolute;
	    z-index: 10;
	    top: 30px;
      right: 0;

	    @include breakpoint($tab) {
	    	width: 25px;
	      height: 25px;
	    	top: 25px;
	    	@include breakpoint($desk) {
	    	  top: 35px;
	    	}
	    }

      .line {
	      display: block;
	      width: 100%;
	      height: 2px;
	      background: $base;
	      position: absolute;
	      top: 7px;

	      &:first-child {
	        -ms-transform: rotate(45deg);
	        -webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	      }

	      &:last-child {
	        -ms-transform: rotate(-45deg);
	        -webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	      }
	    }
    }


    .container, .view-marketing-posts {
    	max-width: 100%;
    }
  }
}

.mazeblock-elastic-instant-search-results,
 .mazeblock-elastic-results {

	.products-grid{
      overflow: hidden;
				clear: both;
      	margin: 0 auto;
        // @include pie-clearfix;
			// mobile
				display: grid;
				grid-gap: 10px;
				grid-template-columns: repeat(2, 1fr);

			// tablet
				@media (min-width:600px) {
						grid-template-columns: repeat(3, 1fr);
				}
				.grid-item{
					position: relative;
				}
				.grid-1-3 {
					 float: unset!important;
					 display: unset!important;
					 width: unset!important;
					 padding: 5px;
			}
	}

}

.mazeblock-elastic-results {
	.mazeblock-elastic-active_filters {
	  display: block;
    width: auto;
    background: #fff;
    line-height: 20px;

    @include breakpoint($tab) {
    	display: none;
    }

		ul {
			list-style: none;
	    margin: 0;
	    float: left;
	    padding: 0;
	    padding: 10px 20px;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;

			li {
		    display: inline-block;
		    padding: 0 15px 10px 0;
		    border-top: 0;
		    width: auto;
		    line-height: 17px;
		    font-size: 12px;
		    position: relative;

				[data-key] {
					padding-right: 18px;
					display: inline-block;
					position: relative;
				}

				.remove-facet {
					display: block;
			    float: right;
			    text-decoration: none;
			    width: 11px;
			    height: 11px;
			    position: absolute;
			    cursor: pointer;
          top: 3px;
			    right: 0;

          .line {
			      display: block;
			      width: 100%;
			      height: 2px;
			      background: $base;
			      position: absolute;
			      top: 5px;

			      &:first-child {
			        -ms-transform: rotate(45deg);
			        -webkit-transform: rotate(45deg);
			        transform: rotate(45deg);
			      }

			      &:last-child {
			        -ms-transform: rotate(-45deg);
			        -webkit-transform: rotate(-45deg);
			        transform: rotate(-45deg);
			      }
			    }
				}
			}
		}
  }
}

.mazeblock-elastic-results.rolex-result {
	text-align: center;

	.rolex-title {
		width: 100%;
	    font-size: 26px;
	    text-align: center;
	    font-weight: 400;
	    margin: 30px auto;
	    display: block;
	    max-width: 500px;
	    text-transform: capitalize;
	}

	.rolex-button {
		background: #212121;
	    font-family: Helvetica;
	    padding: 15px 25px;
	    border-radius: 2px;
	    display: inline-block;
	    width: auto;
	    text-decoration: none;
	    font-size: 12px;
	    color: #fff;
	    margin-top: 15px;
	}
}

.page-taxonomy-term-26,
.page-taxonomy-term-504,
.page-taxonomy-term-31 {
	#mazeblock-elastic-page-wrapper .mazeblock-elastic-facets-inner .facet-wrapper.facet-gender {
		display: none;
	}
}
