body.mobile-menu-active,
body.cart-block-active {
    height: 100%;
    overflow: hidden;
}

body.mobile-menu-active {
    @include breakpoint($desk) {
        -webkit-transform: translateX(220px);
        -moz-transform: translateX(220px);
        transform: translateX(220px);
    }
}

body.cart-block-active {
    @include breakpoint($desk) {
        -webkit-transform: translateX(-400px);
        -moz-transform: translateX(-400px);
        transform: translateX(-400px);
    }
}

.l-mobile-overlay {
    @include opacity(0);
    position: absolute;
    top: 0;
    left: 0;
    width: 0px;
    height: 0px;
    z-index: 501;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    body.mobile-menu-active &,
    body.cart-block-active & {
        @include breakpoint($desk) {
            @include opacity(1);
            width: 100%;
            height: 100%;
            -webkit-transition: opacity 0.3s;
            -moz-transition: opacity 0.3s;
            transition: opacity 0.3s;
        }
    }
    // @include breakpoint($desk) {
    //   display: none;
    // }
}

ul.menu {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
        list-style-type: none;
        display: inline-block;
        a {
            padding: 5px 10px;
            &:hover {
                text-decoration: none;
                color: $main;
            }
        }
        &:first-child {
            a {
                padding-left: 0;
            }
        }
        ul {
            li {
                display: block;
                text-align: center;
                &:first-child {
                    a {
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

.pager {
    margin-top: 20px;
    li>a {
        border: 0;
    }
    .pager__item {
        padding: 0;
    }
    .pager__item a {
        color: #000;
        border-radius: 0;
        padding: 0 15px;
        display: inline-block;
        min-width: 30px;
        line-height: 32px;
        text-align: center;
        background-color: #fff;
        &:hover,
        &:focus {
            background-color: #000;
            color: #fff;
        }
    }
    .pager__item--current {
        background-color: #000;
        border-radius: 0;
        padding: 0 15px;
        display: inline-block;
        min-width: 30px;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .pager__item--last a,
    .pager__item--first a,
    .pager__item--previous a,
    .pager__item--next a {
        background-color: #fff;
        color: #000;
        &:hover,
        &:focus {
            color: #fff;
            background-color: #000;
        }
    }
}

// Simple dropdown menu
.drop-menu {
    display: none;
    @include breakpoint($desk) {
        display: block;
    }
    &>ul.menu {
        display: table;
        width: auto;
        margin: 0 auto;
        @include pie-clearfix;
        &>li {
            padding: 20px 30px;
            position: relative;
            a {
                color: #fff;
                padding: 0;
                font-size: 14px;
                &:hover {
                    color: $main;
                }
            }
            &>ul.menu {
                display: none;
                position: absolute;
                top: 60px;
                min-width: 110px;
                left: 0;
                background: #fff;
                box-shadow: 0 4px 15px -5px #666 !important;
                z-index: 1;
                li {
                    margin: 0;
                    float: none;
                    display: block;
                    border: 0;
                    a {
                        color: $black;
                        line-height: 18px;
                        font-size: 14px;
                        font-weight: 400;
                        display: block;
                        padding: 10px;
                        height: auto;
                    }
                }
            }
            &.expanded {
                &:hover {
                    &>ul {
                        display: table;
                        border-top: 4px solid $main;
                    }
                }
            }
        }
    }
}

.l-mobile-menu {
    display: block;
    position: fixed;
    z-index: 502;
    top: 0;
    //left: -260px;
    // width: 100%;
    width: fit-content;
    height: 100%;
    box-shadow: none;
    display: none;
    text-align: center;
    background: #fff;
    overflow: auto;
    @include breakpoint($desk) {
        left: -220px;
        width: 100%;
        z-index: 500;
        background: transparent;
    }
    body.mobile-menu-active & {
        display: block;
        box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.5);
    }
    @include breakpoint($desk) {
        display: none;
    }
    a.mobile-menu-close {
        display: block;
        float: left;
        text-decoration: none;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 10px;
        left: 15px;
        z-index: 10;
        text-indent: -99999px;
        @include breakpoint($desk) {
            width: 22px;
            height: 22px;
            top: 25px;
        }
        .line {
            display: block;
            width: 100%;
            height: 2px;
            background: $base;
            position: absolute;
            top: 7px;
            &:first-child {
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &:last-child {
                -ms-transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }
    .mobile-drop-menu {
        padding: 55px 0 0;
        clear: both;
        display: block;
        // overflow-y: scroll;
        // margin-bottom: 30px;
        background: #fff;
        @include breakpoint($desk) {
            width: 220px;
            height: 100%;
            margin-bottom: 0;
        }
        li.gold {
            >span,
            >a {
                color: #8A733D;
                /* #;*/
            }
        }
        li.red {
            >span,
            >a {
                color: red;
            }
        }
        &>ul.menu {
            &>li {
                margin: 0;
                width: 100%;
                position: relative;
                margin-bottom: 20px;
                text-align: center;
                a,
                span.nolink {
                    @extend .minion-bold;
                    letter-spacing: 3px;
                    display: block;
                    font-size: 18px;
                    padding: 15px 20px;
                    color: $base;
                    border-bottom: 1px solid rgba(245, 245, 245, 0.2);
                    text-align: center;
                    color: #212121;
                    display: inline-block;
                }
                &>ul.menu>li .bean-menu-marketing-post .field__items>.field__item {
                    padding: 5px 20px;
                    display: inline-block;
                }
                &>ul.menu>li a {
                    @extend .helvetica;
                    font-size: 13px;
                    // color: #818181;
                    color: #737373;
                    /*#818181*/
                    letter-spacing: inherit;
                    padding: 10px 20px;
                    cursor: pointer;
                    @include breakpoint($desk) {
                        padding: 5px 0;
                    }
                }
                //here
                &>ul.menu>li.gold {
                    >span,
                    >a {
                        color: #585754;
                    }
                }
                &>ul.menu>li.red {
                    >span,
                    >a {
                        color: #BE0100;
                    }
                }
                &.expanded>ul.menu>.expanded {
                    position: relative;
                    &.open {
                        background-color: #f7f7f7;
                        -webkit-transform: translateZ(0);
                        -webkit-overflow-scrolling: touch;
                    }
                    >a {
                        position: relative;
                        &:before {
                            display: none;
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            color: #000;
                            font-size: 15px;
                            transform: translatey(-50%);
                            @extend .icon-keyboard_arrow_down;
                            @extend .font-icon;
                        }
                    }
                    .submenu-title {
                        font-weight: 700;
                        font-size: 11px;
                        padding: 20px 0 10px;
                        display: block;
                        text-align: center;
                        color: #8A733D;
                        /* #8A733D;*/
                    }
                    &>ul.menu {
                        display: none;
                        @include breakpoint($desk) {
                            position: absolute;
                            top: -33px;
                            left: 100%;
                            z-index: 1;
                            background: #fff;
                            flex-wrap: wrap;
                            // justify-content: space-between;
                            min-width: 1100px;
                            padding: 30px 20px;
                        }
                        >li {
                            @include breakpoint($desk) {
                                width: 20%;
                                padding-bottom: 30px;
                            }
                            span.nolink {
                                font-size: 13px;
                                padding: 5px 20px;
                                // text-decoration: underline;
                                // color: #8A733D;
                                @include breakpoint($desk) {
                                    font-size: 18px;
                                }
                            }
                            &.hide-text {
                                margin: -20px;
                                @include breakpoint($desk) {
                                    margin: 0;
                                }
                                span.nolink {
                                    display: none;
                                    @include breakpoint($desk) {
                                        display: block;
                                        opacity: 0;
                                    }
                                }
                            }
                            ul.menu {
                                margin-bottom: 20px;
                                @include breakpoint($desk) {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &.watches {
                        &>ul.menu {
                            &>li {
                                ul.menu {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &.col-1 {
                        &>ul.menu {
                            @include breakpoint($desk) {
                                min-width: 300px;
                            }
                            >li {
                                width: 100%;
                            }
                        }
                    }
                    &.col-2 {
                        &>ul.menu {
                            @include breakpoint($desk) {
                                min-width: 500px;
                            }
                            >li {
                                @include breakpoint($desk) {
                                    width: 50%;
                                }
                            }
                        }
                    }
                    &.col-3 {
                        &>ul.menu {
                            @include breakpoint($desk) {
                                min-width: 700px;
                            }
                            >li {
                                @include breakpoint($desk) {
                                    width: 33.333%;
                                }
                            }
                        }
                    }
                    &.col-4 {
                        &>ul.menu {
                            @include breakpoint($desk) {
                                min-width: 900px;
                            }
                            >li {
                                @include breakpoint($desk) {
                                    width: 25%;
                                }
                            }
                        }
                    }
                    &.open {
                        >a {
                            color: #8A733D;
                            /* #8A733D;*/
                            &:before {
                                transform: translatey(-50%) rotate(180deg);
                                @include breakpoint($desk) {
                                    transform: translatey(-50%) rotate(270deg);
                                }
                            }
                        }
                        &>ul.menu {
                            display: block;
                            @include breakpoint($desk) {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
    #block-locale-language {
        width: 100%;
        @include breakpoint($desk) {
            width: 220px;
        }
        ul {
            list-style: none;
            text-align: center;
            padding: 0;
            margin-bottom: 30px;
            li {
                display: inline-block;
                width: 50%;
                a {
                    // color: #818181;
                    color: #737373;
                    /*#818181*/
                    &:hover {
                        text-decoration: none;
                    }
                }
                img {
                    display: none;
                }
            }
            .el {
                text-align: left;
                padding-left: 10px;
            }
            .en {
                text-align: right;
                padding-right: 10px;
            }
            .active {
                font-weight: 700;
                color: #000;
            }
        }
    }
    .social-wrapper {
        // display: inline-block;
        // margin: 0 20px;
        width: 100%;
        @include breakpoint($desk) {
            width: 220px;
        }
        a {
            display: inline-block;
            margin: 0;
            text-align: center;
            position: relative;
            overflow: hidden;
            color: transparent;
            font-size: 21px;
            width: 21px;
            height: 21px;
            margin: 0 18px;
            &.facebook:before {
                position: absolute;
                @extend .font-icon;
                @extend .icon-social-facebook;
                color: $base;
                top: -5px;
                left: 0;
                width: 30px;
                height: 30px;
                font-size: 30px;
            }
            &.instagram:before {
                position: absolute;
                @extend .font-icon;
                @extend .icon-instagram;
                color: $base;
                top: 0;
                left: 0;
            }
            &:hover,
            &:focus {
                &:after {
                    background: #000;
                    color: $indigo;
                    text-decoration: none;
                }
            }
        }
    }
    .user-block {
        a {
            letter-spacing: 3px;
            display: block;
            font-size: 18px;
            padding: 15px 20px;
            border-bottom: 1px solid rgba(245, 245, 245, 0.2);
            text-align: center;
            color: #212121;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .header-rolex-clock {
        margin: 20px 0;
        .contextual-links-wrapper {
            display: none !important;
        }
        a {
            display: block;
            width: 100%;
            height: 100%;
            color: transparent;
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}
