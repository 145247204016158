#admin-menu,
body.admin-menu.adminimal-menu:before {
  display: none;

  @include breakpoint($desk) {
    display: block;
  }
}

html {
  height: auto;
}

body {
  position: relative;
    @extend .helvetica;
  font-weight: 300;
  font-size: 14px;
  font-display: swap;

  &.cke_editable {
    padding: 20px;
  }

  h2, h3, h4, h5 {
    @extend .helvetica;
  }
}

ul.menu,
ul.language-menu {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

.flexslider {
  border: none;
}

h1 {
  text-align: center;
}

.element-invisible {
  display: none;
}


.fieldset-wrapper {
  .messages {
    border-radius: 0;
    position: relative;
    padding: 20px 45px 20px 30px;
    box-shadow: 0 3px 16px 0px rgba(0, 0, 0, 0.3);
    opacity: 1;
    background: #fff;
    border: 0;
    max-width: 600px;
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 100;

    .close {
      position: absolute;
      top: 17px;
      right: 15px;
      display: table;
      width: auto;
    }
  }

  .messages--status {
    // border-top: 5px solid rgba(12, 184, 99, 1);
    border: 1px solid #444;
  }

  .messages--warning {
    border-top: 5px solid rgba(255, 186, 0, 1);
    color: #e72a2a;
  }

  .messages--error, {
    color: #e72a2a;
    border: 1px solid #e72a2a;
  }
}

.l-messages {
  z-index: 100;

  .messages {
    border-radius: 0;
    position: relative;
    padding: 20px 45px 20px 30px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    opacity: 1;
    background: #fff;
    border-radius: 4px;
    border: 0;
    margin: 40px auto;

    .close {
      position: absolute;
      top: 17px;
      right: 15px;
      display: table;
      width: auto;
    }
  }

  .messages--status {
    // border: 1px solid rgba(12, 184, 99, 1);
    border: 1px solid #444;
  }

  .messages--warning {
    border: 1px solid rgba(255, 186, 0, 1);
  }

  .messages--error {
    color: #e72a2a;
    border: 1px solid #e72a2a;
  }
}

.block__title {
  @extend .minion;
  font-size: 26px;
  text-align: center;
  font-weight: 400;
  margin: 0 0 30px;
  //letter-spacing: 3px;
}

.text-center {
  text-align: center;
}

.clear-both {
  @include clearfix;
  @include pie-clearfix;
}

.view-col-3 {
  @include clearfix();

  .view-content > * {
    display: inline-block;
    width: 100%;
    float: left;

    @include breakpoint($tab) {
      width: 50%;

      @include breakpoint($desk) {
        width: 33.33333333%;
      }
    }
  }
}

.view-col-4 {
  @include clearfix();

  .view-content > * {
    display: inline-block;
    width: 50%;
    float: left;

    @include breakpoint($tab) {
      width: 25%;
    }
  }
}


.grid-6,
.grid-5,
.grid-4,
.grid-3,
.grid-2 {
  @include pie-clearfix;
  @include clearfix;
}

.grid-1-2 {
  float: left;
  display: inline-block;
  width: 100%;
  padding: 5px;

  @include breakpoint($tab) {
    width: 50%;
  }
}

.grid-1-3 {
  float: left;
  display: inline-block;
  width: 50%;
  padding: 5px;

  @include breakpoint($tab) {
    &:nth-child(3n+1) {
      clear: both;
    }
    width: 33.33%;
  }
}

.grid-1-4 {
  float: left;
  display: inline-block;
  width: 50%;
  padding: 5px;

  &:nth-child(2n+1) {
    clear: both;
  }

  @include breakpoint($tab) {
    width: 25%;

    &:nth-child(2n+1) {
        clear: none;
    }

    &:nth-child(4n+1) {
      clear: both;
    }
  }
}


.grid-1-6 {
  float: left;
  display: inline-block;
  width: 50%;
  padding: 5px;

  &:nth-child(2n+1) {
    clear: both;
  }

  @include breakpoint($tab) {
    width: 25%;

    &:nth-child(2n+1) {
      clear: none;
    }

    @include breakpoint($desk) {
      width: 16%;
    }
  }
}

.grid-1-5 {
  float: left;
  display: inline-block;
  width: 50%;
  padding: 5px;

  @include breakpoint($tab) {
    width: 25%;

    @include breakpoint($desk) {
      width: 20%;
    }
  }
}

.till-mobile {
  @include breakpoint($tab) {
    display: none;
  }
}

.tab-and-above {
  display: none;

  @include breakpoint($tab) {
    display: block;
  }
}

.mob-and-tab {
  display: block;

  @include breakpoint($desk) {
    display: none;
  }
}

.only-desk {
  display: none;

  @include breakpoint($desk) {
    display: block;
  }
}
