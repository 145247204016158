.margeting-post-teaser {
  &.video {
    background-size: cover;
  }
  &.banner,
  &.reverse-banner {
    h3 {
      font-size: 18px;
    }
    h2 {
      font-size: 32px;
      margin: 0;
      margin-bottom: 20px;
    }
  }
  &.banner {
    .mob_tab-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      top: 0;
    }
    .image-wrapper {
      height: auto;
      background-size: cover;
      position: relative;
      background-position: center; // background-attachment: fixed;
      @include breakpoint($tab) {
        // height: 450px;
        background-position: unset;
        background-attachment: unset;
      }
    }
    .slide-content {
      color: #fff;
      text-align: center;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      padding: 20px 10px;
      margin: 0 auto;
      max-width: 690px;
      top: 50%;
      transform: translatey(-50%);
      @include breakpoint($tab) {
        // top: unset;
        // transform: unset;
        // padding: 85px;
        @include breakpoint(1125px) {
          float: right;
          width: 35%;
          text-align: left;
          padding: 70px 10px 0 0;
          position: relative;
        }
      }
      a {
        padding: 15px 25px;
        border-radius: 2px;
        display: inline-block;
        width: auto;
        text-decoration: none;
        font-size: 15px;
        letter-spacing: 3.2px;
            border-radius: 50px;
        color: #fff;
        margin-top: 15px;
        background: #bc944f;
        /* Old browsers */
        background: -moz-linear-gradient(top, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bc944f', endColorstr='#bc944f', GradientType=0);
        /* IE6-9 */
        background: $main;
        &:hover {
          background: #fff;
          color: #212121;
          text-decoration: none;
        }
      }
    }
  }
  &.reverse-banner {
    // margin: 20px 0 0;
    &.tab-and-above {
      display: none;
      @include breakpoint($desk) {
        display: block;
      }
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 13px;
    }
    .image-wrapper {
      background-size: cover;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      height: auto;
      @include breakpoint($tab) {
        // height: 450px;
        background-position: unset;
        background-attachment: unset;
      }
    }
    .simple-content {
      opacity: 0;
      position: absolute;
      top: 0px;
      color: #fff;
      background: rgba(0, 0, 0, 0.7);
      -webkit-transition-duration: 0.5s;
      /* Safari */
      transition-duration: 0.5s;
      margin: 10px;
      padding: 5px;
      @include breakpoint($desk) {
        padding: 30px;
        margin: 50px 25px 0;
        top: 15px;
      }
    }
    &:hover {
      .simple-content {
        opacity: 1;
      }
    }
  }
  &.simple {
    padding: 0 10px;
    text-align: center;
    @include breakpoint($desk) {
      text-align: left;
    }
    img {
      width: 100%;
      height: auto;
      max-width: 441px;
    }
    &.desk {
      display: none;
    }
    &.mob_tab {
      display: block;
      a:hover {
        text-decoration: none;
        cursor: pointer;
      }
      .field--name-field-image {
        .field__items {
          text-align: center;
          @include breakpoint($tab) {
            text-align: left;
          }
          img {
            height: auto;
          }
        }
      }
      h2 {
        font-size: 20px;
        margin-top: 20px;

        @include breakpoint($tab) {
          margin-top: 65px;
        }
      }
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 13px;
      }
    }
    @include breakpoint($desk) {
      &.desk {
        display: block;
        h2 {
          font-size: 20px;
        }
        h3 {
          font-size: 16px;
        }
        p {
          font-size: 13px;
        }
        .simple-content {
          opacity: 0;
          position: absolute;
          top: 40px;
          color: #fff;
          margin: 50px 25px 0;
          background: rgba(0, 0, 0, 0.7);
          padding: 30px;
          -webkit-transition-duration: 0.5s;
          /* Safari */
          transition-duration: 0.5s;
          margin: 0 10px !important;
        }
        &:hover {
          .simple-content {
            opacity: 1;
          }
        }
      }
      &.mob_tab {
        display: none;
      }
    }
  }
  &.list {
    @include pie-clearfix;
    padding: 0 78px;
    .field--name-field-image,
    .simple-content {
      width: 50%;
    }
    .simple-content {
      width: 50%;
      padding: 50px;
    }
  }
  &.tile {
    &.tab-and-above {
      display: none;
    }
    &.mob_tab {
      display: block;
      position: relative;
      a:hover {
        text-decoration: none;
        cursor: pointer;
      }
      .field--name-field-image {
        .field__items {
          text-align: center;
          img {
            height: auto;
            width: 100%;
          }
        }
      }
      h2 {
        font-size: 20px;
      }
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 13px;
      }
    }
    @include breakpoint($tab) {
      &.tab-and-above {
        .field--name-field-image {
          .field__items {
            text-align: center;
            img {
              height: auto;
              width: 100%;
            }
          }
        }
        h2 {
          font-size: 20px;
        }
        h3 {
          font-size: 8px;
          @include breakpoint($desk) {
            font-size: 16px;
          }
        }
        p {
          font-size: 13px;
        }
        .simple-content {
          opacity: 0;
          position: absolute;
          top: 0px;
          color: #fff;
          background: rgba(0, 0, 0, 0.7);
          -webkit-transition-duration: 0.5s;
          /* Safari */
          transition-duration: 0.5s;
          margin: 10px;
          padding: 5px;
          @include breakpoint($desk) {
            padding: 30px;
            margin: 50px 25px 0;
            top: 15px;
          }
        }
        &:hover {
          .simple-content {
            opacity: 1;
          }
        }
      }
      &.tab-and-above.video .simple-content {
        background: none;
      }
    }
    @include breakpoint($desk) {
      &.mob_tab {
        display: none;
      }
      &.tab-and-above {
        display: inline-block;
        position: relative;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  h2 {
    margin-top: 0;
  }

  .simple-content {
    margin-top: 20px;
    display: block;
  }
}

.view-marketing-posts {
  @include pie-clearfix;
  @extend .container;

  &.full-width {
    width: 100%;
    padding: 0;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
  }
  .views-row-odd .margeting-post-teaser.list .field--name-field-image {
    float: left;
  }
  .views-row-odd .margeting-post-teaser.list .simple-content {
    float: right;
  }
  .views-row-even .margeting-post-teaser.list .field--name-field-image {
    float: right;
  }
  .views-row-even .margeting-post-teaser.list .simple-content {
    float: left;
  }
  .views-row-even .margeting-post-teaser.tile.tab-and-above .simple-content {
    top: 60px;
    @include breakpoint($desk) {
      top: 100px;
    }
  }
  .simple-content-wrapper {
    position: absolute;
    bottom: 20px;
    padding: 0 20px;
    left: 0;
  }
  .views-row {
    @include pie-clearfix;
    text-align: center;
    padding: .66%;

    @include breakpoint($tab) {
      text-align: left;
      display: inline-block;
      float: left;
      &.views-row-odd {
        width: 33.33%;
      }
      &.views-row-even {
        width: 66.66%
      }
    }
  }
  &.banner,
  &.banner-for-articles {
    .views-row {
      width: 100%;
      float: none;
      padding: 0;
    }
  }
  &.reverse {
    .views-row {
      @include breakpoint($tab) {
        &.views-row-odd {
          width: 66.66%
        }
        &.views-row-even {
          width: 33.33%;
        }
      }
    }
  }
  .views-slideshow-cycle-main-frame-row-item.views-row {
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }
}


.view-marketing-posts.owl-simple-wrapper {
  padding: 0;
}

.owl-simple-wrapper .item {
  display: inline-block;
  width: 100%;
  position: relative;
  float: left;
  @include breakpoint($tab) {
    width: 33.333%;
  }
}

.owl-simple-wrapper .owl-item .item {
  width: 100%;
}

.owl-simple {
  .owl-controls {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translate(-50%);
    @include breakpoint($tab) {
      //bottom: 115px;
    }
    .owl-pagination .owl-page {
      span {
        background: #737373; /*#818181*/
        width: 10px;
        height: 10px;
        margin: 5px;
        opacity: 1;
      }
      &.active {
        border: 1px solid #737373; /*#818181*/
        background: transparent;
        border-radius: 50%;
        position: relative;
      }
    }
    .owl-buttons {
      display: none;
    }
  }
}

.view-marketing-placement > .view-content > .views-row {
  margin-bottom: 60px;

  @include breakpoint($tab) {
    margin-bottom: 80px;
  }
}

.rolex-wrapper {
  .margeting-post-teaser.banner {
    position: relative;
    .image-wrapper-rolex img {
      width: 100%;
      height: auto;
    }
    h3 {
      display: none;
      font-size: 16px;
      margin: 0 auto;
      color: #000 !important;

      @include breakpoint($tab) {
        // color: currentColor !important;
        // color: unset;
        font-size: 22px;

        @include breakpoint(990px) {
          font-size: 20px;
          margin: 0 auto;
        }
      }
    }
    h2 {
      font-size: 20px;
      margin: 20px auto 0;
      // color: #000 !important;
      color: #000;

      @include breakpoint($tab) {
        // color: currentColor !important;
        // color: unset;
        font-size: 22px;

        @include breakpoint(990px) {
          font-size: 30px;
          margin: 0 auto 20px;
        }
      }
    }
    p {
      font-size: 13px;
      @include breakpoint($tab) {
        font-size: 14px;
      }
    }
    a {
      background: transparent;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .mob_tab-overlay {
      top: 0;
      opacity: 0;
      opacity: 0;
      -webkit-transition: opacity .5s ease-in-out;
      -moz-transition: opacity .5s ease-in-out;
      -ms-transition: opacity .5s ease-in-out;
      -o-transition: opacity .5s ease-in-out;
      transition: opacity .5s ease-in-out;
      a {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .slide-content {
      opacity: 1;
      position: relative;
      color: #000;
      text-align: center;
      padding: 0;
      max-width: 100%;
      text-align: center;
      top: unset;
      transform: unset;
      @include breakpoint($tab) {
        // opacity: 0;
        position: absolute;
        color: #000;
        padding: 25px;
        max-width: 370px;
        -webkit-transition: opacity .5s ease-in-out;
        -moz-transition: opacity .5s ease-in-out;
        -ms-transition: opacity .5s ease-in-out;
        -o-transition: opacity .5s ease-in-out;
        transition: opacity .5s ease-in-out;
        top: 50%;
        left: auto;
        right: 0;
        transform: translatey(-50%);
        text-align: left;

        @include breakpoint(990px) {
          padding: 5px;
          max-width: 450px;

          @include breakpoint(1100px) {
            padding: 35px;
            max-width: 500px;
          }
        }
      }

      &.center {
        @include breakpoint($tab) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &.left {
        @include breakpoint($tab) {
          left: 0;
          right: unset;
        }
      }
      a {
        background: transparent;
        text-decoration: none;
        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
      .button-url {
        margin-top: 15px;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 3.2px;
        color: #fff;
        background: #127749;
        padding: 20px;
        text-align: center;
        display: inline-block;
        border-radius: 50px;
        // max-width: 350px;
        margin: 15px auto 0;



        @include breakpoint($tab) {
          margin: 15px 0 0;
          padding: 15px 20px;

          @include breakpoint($desk) {
            padding: 20px;
          }
        }
      }
    } // &:hover {
    //   .mob_tab-overlay {
    //     @include breakpoint($desk) {
    //       opacity: 1;
    //       -webkit-transition: opacity .5s ease-in-out;
    //       -moz-transition: opacity .5s ease-in-out;
    //       -ms-transition: opacity .5s ease-in-out;
    //       -o-transition: opacity .5s ease-in-out;
    //       transition: opacity .5s ease-in-out;
    //     }
    //   }
    //   .slide-content {
    //     @include breakpoint($desk) {
    //       opacity: 1;
    //       -webkit-transition: opacity .5s ease-in-out;
    //       -moz-transition: opacity .5s ease-in-out;
    //       -ms-transition: opacity .5s ease-in-out;
    //       -o-transition: opacity .5s ease-in-out;
    //       transition: opacity .5s ease-in-out;
    //     }
    //   }
    // }
  }
}

#block-views-marketing-placement-block .slide-content-wrapper-for-articles.container > div{
  margin-top:20px!important;
  @include breakpoint($tab) {
  right: -150px!important;
   margin-top: unset!important;
  }

  @include breakpoint($desk) {
      right: -165px!important;
      margin-top: unset!important;
    }
}

//case rolex-banner-2
// #block-views-marketing-placement-block div.views-row.views-row-1 div:nth-child(2) .slide-content-wrapper.container > div{
//   //right: unset;
//   margin-top:20px;
//   //border: 1px solid green;
//   @include breakpoint($tab) {
//    right: -150px ;
//    //- 200px

//    margin-top: unset;
//   }

//   @include breakpoint($desk) {
//       right: -165px;
//       margin-top: unset;
//     }
// }
