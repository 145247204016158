.node--product--full {
    @include pie-clearfix;
    margin: 50px 0;
    .right-col,
    .left-col {
        padding: 0 20px;
        @include breakpoint($desk) {
            display: inline-block;
            width: 40%;
            float: left;
        }
    }
    .left-col {
        @include breakpoint($desk) {
            width: 60%;
        }
    }
    .left-col {
        position: relative;
        height: 100%!important;
        padding: 0;
        @include breakpoint($desk) {
            padding: 0 20px;
        }
        .back-category {
            font-weight: 400;
            position: relative;
            padding-left: 19px;
            margin-bottom: 10px;
            &:before {
                @extend .font-icon;
                @extend .icon-keyboard_arrow_left;
                font-size: 18px;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .mob_images_wrapper {
            position: relative;
            height: 100% !important;
            .mz-zoom-window.mz-inner {
                >div,
                >span {
                    opacity: 0;
                }
            }
            ul.field__items {
                padding: 0;
                li {
                    list-style: none;
                }
            }
            .flex-viewport {
                max-height: 315px;
                margin: 0 auto;
                // max-width: 400px;
                max-height: 2000px!important;
                ul {
                    padding: 0;
                }
            }
            img {
                width: 100%;
                height: auto;
                // padding: 0 10px;
            }
            .flex-control-paging li a {
                background: #fff;
                &.flex-active {
                    background: $main;
                }
            }
            .flex-control-nav {
                position: relative;
                bottom: -35px;
                margin-bottom: 50px;
            }
            .flex-direction-nav {
                display: none;
                li {
                    position: absolute;
                    top: 50%;
                }
            }
            .flex-nav-next {
                right: -8px;
                @include breakpoint($desk) {
                    right: -5px;
                }
            }
            .flex-direction-nav .flex-prev {
                left: -29px;
            }
            .flex-direction-nav a {
                opacity: 1;
                font-size: 0;
            }
            .flex-direction-nav li {
                width: 45px;
                height: 45px;
            }
            .flex-direction-nav .flex-prev {
                //@extend .icon-keyboard_arrow_left;
                @extend .font-icon;
                content: url('../svg/keyboard_arrow_left.svg');
                width: 45px;
                height: 45px;
            }
            .flex-direction-nav .flex-next {
                right: -17px;
                //@extend .icon-keyboard_arrow_left;
                content: url('../svg/keyboard_arrow_right.svg');
                @extend .font-icon;
            }
        }
        .commerce-product-field-field-product-image {
            display: none;
            @include breakpoint($desk) {
                display: block;
            }
        }
        .shop-single {
            text-align: center;
            @include breakpoint($desk) {
                width: 79%;
                float: right;
                padding: 0 40px;
            }
            &.no-gallery {
                float: none;
                margin: 0 auto;
            }
            img {
                width: 100%;
                height: auto;
                // @include breakpoint($desk) {
                //   width: 240px;
                //   @include breakpoint($desk) {
                //     width: 300px;
                //   }
                // }
            }
            .flex-direction-nav {
                display: none;
            }
        }
        #gal1 {
            text-align: center;
            list-style: none;
            padding: 0;
            @include pie-clearfix;
            margin: 30px auto;
            display: inline-block;
            float: left;
            text-align: right;
            width: 20%;
            .gal1 {
                position: relative;
            }
            ul {
                padding: 30px 0;
                text-align: center;
                overflow: hidden;
            }
            li {
                display: inline-block;
                position: relative;
                a.active {
                    display: block;
                    position: relative;
                    cursor: unset;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.05);
                    }
                }
            }
            .control-nav {
                a {
                    font-size: 0;
                    a:before {
                        @extend .font-icon;
                        font-size: 40px;
                        display: inline-block;
                        @extend .icon-keyboard_arrow_down;
                        color: rgba(0, 0, 0, 0.8);
                        text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
                    }
                }
                a.disable {
                    cursor: unset;
                    &:before {
                        opacity: 0.5;
                    }
                }
                .next {
                    position: absolute;
                    bottom: -30px;
                    left: 50%;
                    transform: translatex(-50%);
                }
                a.flex-next:before {
                    @extend .font-icon;
                    @extend .icon-keyboard_arrow_down;
                    font-size: 30px;
                }
                .prev {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translatex(-50%);
                    width: 100%;
                    text-align: center;
                    background: #fff;
                }
                a.flex-prev:before {
                    @extend .font-icon;
                    @extend .icon-keyboard_arrow_down;
                    font-size: 30px;
                    transform: rotate(180deg);
                }
            }
        }
    }
    .right-col {
        margin-top: 30px;
        clear: both;
        @include breakpoint($desk) {
            margin-top: 0;
            clear: none;
        }
        h1 {
            @extend .helvetica;
            padding-top: 20px;
            text-align: left;
            font-size: 28px;
            line-height: 38px;
            margin: 0;
            clear: both;
        }
        .brand {
            font-size: 16px;
            font-weight: 400;
            @include breakpoint($desk) {
                float: left;
            }
            .field__label {
                display: none;
            }
        }
        .sku {
            font-size: 14px;
            opacity: 0.5;
            @include breakpoint($desk) {
                float: right;
            }
            .commerce-product-sku-label {
                font-weight: 400;
            }
            span {
                font-weight: 400;
            }
        }
        .prices-wrapper {
            margin: 25px 0 0;
            float: left;
            .final-price,
            .initial-price {
                display: inline-block;
                font-weight: 400;
                .field__label {
                    display: none;
                }
            }
            .final-price {
                font-size: 23px;
                margin-right: 20px;
                .field__label {
                    display: none;
                }
            }
            .initial-price {
                text-decoration: line-through;
                font-size: 18px;
                color: $grey;
            }
        }
        .certification {
            clear: both;
            .field__label {
                display: none;
            }
        }
        .description-summary {
            padding: 30px 0;
            clear: both;
            line-height: 19px;
            .continue-review {
                font-size: 13px;
                font-weight: 400;
                border-bottom: 1px solid $indigo;
                &:hover {
                    color: $indigo;
                    text-decoration: none;
                }
            }
        }
        .features {
            @include pie-clearfix;
            margin-top: 40px;
            .field--name-field-gems {
                @include pie-clearfix;
            }
        }
        .feature-wrapper {
            @include pie-clearfix;
            margin-bottom: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #E1E1E1;
            .field__label {
                font-weight: 300;
                float: left;
                width: 35%;
                display: inline-block;
            }
            .field__item {
                float: left;
                width: 65%;
            }
            .field__items {
                float: right;
                width: 65%;
            }
            p {
                margin: 0;
            }
        }
        .features.alternative .feature-wrapper {
            padding-left: 16px;
            border: 0;
            margin: 0;
            .field__label {
                display: none;
            }
            .field__item {
                display: list-item;
            }
            .field__items {
                float: left;
            }
        }
        .availability {
            float: right;
            .field__item {
                font-size: 13px;
                font-weight: 300;
                margin-bottom: 5px;
                position: relative;
                &:before {
                    @extend .font-icon;
                    // @extend .icon-tick;
                    color: $indigo;
                    font-size: 13px;
                    padding-right: 5px;
                }
            }
        }
        .product-variations {
            display: block;
            clear: left;
            padding-top: 10px;
        }
        ul.variations {
            list-style-type: none;
            list-style: none;
            padding: 0;
        }
        ul.variations li {
            list-style-type: none;
            list-style: none;
            display: inline-block;
            margin-right: 10px;
        }

        ul.variations li.status-disabled {
            background-color:lightcoral;
            border: 1px solid lightcoral;
        }

        ul.variations li a {
            background-color: transparent;
            color: inherit;
            display: inline-block;
            text-decoration: none;
        }
        ul.variations li a.btn {
            display: inline-block;
            color: #fff;
            background-color: #78756D;
            padding: 10px;
            margin: 1px;
        }
        ul.variations li img {
            max-width: 70px;
        }
        .product-wrapper {
            @include pie-clearfix;
            display: flex;
            align-items: flex-end;
            position: relative;
            .form-item-quantity {
                margin: 0;
                label {
                    margin: 0 5px 5px 0;
                    display: inline-block;
                }
                input {
                    display: block;
                    width: 85px;
                    border-width: 2px;
                }
            }
            .form-submit {
                margin-top: 20px;
                background: $main;
                @extend .helvetica-regular;
                border-radius: 2px;
                width: 210px;
                @include breakpoint($desk) {
                    width: 240px;
                }
                &:hover {
                    color: #fff;
                    text-decoration: none;
                    background: #bc944f;
                    /* Old browsers */
                    background: -moz-linear-gradient(top, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
                    /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
                    /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
                    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#bc944f', endColorstr='#bc944f', GradientType=0);
                    /* IE6-9 */
                    transition: background-color 0.3s ease;
                    background: $main;
                }
            }
            .not_sale {
                width: auto;
                margin-right: 20px;
                display: inline-block;
                float: left;
                @include breakpoint($desk) {
                    margin-right: 0;
                    width: 50%;
                }
                .inquire-button,
                .inquire-button-all {
                    display: block;
                    text-align: center;
                    max-width: 210px;
                    background: $base;
                    color: #fff;
                    padding: 10px;
                    font-size: 14px;
                    padding: 10px 20px;
                    border: 0;
                    outline: none;
                    // box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
                    margin: 20px 0;
                    @extend .helvetica-regular;
                    @include breakpoint(1220px) {
                        max-width: 240px;
                    }
                    &:hover,
                    &:focus {
                        color: #fff;
                        text-decoration: none;
                        background: #bc944f;
                        /* Old browsers */
                        background: -moz-linear-gradient(top, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
                        /* FF3.6-15 */
                        background: -webkit-linear-gradient(top, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
                        /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to bottom, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
                        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#bc944f', endColorstr='#bc944f', GradientType=0);
                        /* IE6-9 */
                        transition: background-color 0.3s ease;
                        background: $main;
                    }
                }
            }
        }
        .not_sale_wishlist {
            float: left;
            @include breakpoint($desk) {
                float: none;
            }

            .commerce-product-extra-field-flag-wishlist {
                display: inline-block;
                // float: right;
                // width: 50%;

                background-color:transparent;
                position: absolute;
                bottom: 0;
                left: unset;
                // padding-left:10px;
                top: 0px;
                @include breakpoint($desk) {
                    // left: 250px;
                    // width: 50%;
                }
                a {
                    display: inline-block;
                    margin-top: 20px;
                    color: #000;
                    font-size: 0;
                    &:before {
                        @extend .font-icon;
                        @extend .icon-heart-outlined;
                        font-size: 33px;
                    }
                    &:hover,
                    &:focus,
                    &.flagged {
                        &:before {
                            @extend .font-icon;
                            @extend .icon-heart;
                            font-size: 33px;
                            color: $main;
                        }
                    }
                }
            }
        }
        .for_sale {
            float: left;
        }
        .for_sale_wishlist {
            height: auto;
            // float: left;
            // margin: 63px 0 0 13px;
            margin-left:13px;
            .flag-throbber{
                font-size:0px;
            }
            .commerce-product-extra-field-flag-wishlist {
                display: inline-block;
                // float: right;
                // width: 50%;

                background-color:transparent;
                position: absolute;
                bottom: 0;

                z-index:100;
                // left: 240px;
                // padding-left:10px;
                left: 230px;
                  @include breakpoint($desk) {
                    left: 260px;
                }


                // @include breakpoint($desk) {
                //     top: 0px;
                // }
                a {
                    display: inline-block;
                    margin-top: 20px;
                    color: #000;
                    font-size: 0;
                    &:before {
                        @extend .font-icon;
                        @extend .icon-heart-outlined;
                        font-size: 33px;
                    }
                    &:hover,
                    &:focus,
                    &.flagged {
                        &:before {
                            @extend .font-icon;
                            @extend .icon-heart;
                            font-size: 33px;
                            color: $main;
                        }
                    }
                }
            }
        }
        .tags-wrapper {
            margin: 30px 0 0;
            position: relative;
            font-size: 12px;
            padding-top: 10px;
            padding-left: 10px;
            font-weight: 700;
            border-top: 1px solid #dadada;
            .tags-inner {
                @include pie-clearfix;
                &>div {
                    float: left;
                }
            }
            .tags-inner:before {
                content: '#';
                display: block;
                position: absolute;
                top: 10px;
                left: 0;
            }
            .field__item {
                display: inline-block;
                position: relative;
                margin-right: 6px;
                border-bottom: 1px solid $indigo;
                a:hover {
                    color: $indigo;
                    text-decoration: none;
                }
                &:after {
                    content: ',';
                    position: absolute;
                    top: 0;
                    right: -3px;
                    display: block;
                }
                &:last-child:after {
                    display: none;
                }
            }
        }
        .share-product {
            margin-top: 60px;
            h4 {
                margin: 0 8px 0 0;
                font-size: 13px;
                line-height: 25px;
                float: left;
                font-weight: 700;
                display: inline-block;
            }
            .share-product-wrapper {
                display: inline-block;
                a {
                    display: inline-block;
                    margin: 0;
                    text-align: center;
                    position: relative;
                    overflow: hidden;
                    color: transparent;
                    font-size: 26px;
                    width: 32px;
                    &.facebook-share:before {
                        position: absolute;
                        @extend .font-icon;
                        @extend .icon-facebook;
                        color: $base;
                    }
                    &:hover,
                    &:focus {
                        &:after {
                            background: #000;
                            color: $indigo;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }


    .product-bundle-wrapper {
        .field--type-paragraphs {
            margin-top: 40px;
            .entity-paragraphs-item .content {
                @include pie-clearfix;
                .field--name-field-image {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .field--name-field-subtitle-product-bundle {
                    @extend .minion;
                    font-style: italic;
                    font-size: 20px;
                    color: $main;
                    padding: 0 20px;
                    @include breakpoint($desk) {
                        padding: 0 50px;
                    }
                }
                .field--name-field-title {
                    @extend .minion;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                    font-size: 20px;
                    padding: 50px 20px 10px;
                    @include breakpoint($desk) {
                        padding: 50px 50px 10px;
                    }
                }
                .field--name-field-body-product-bundle {
                    padding: 20px;
                    opacity: 0.8;
                    @include breakpoint($desk) {
                        padding: 20px 50px;
                    }
                }
            }
            .even {
                .field--name-field-image-product-bundle {
                    float: left;
                    width: 100%;
                    @include breakpoint($desk) {
                        width: 50%;
                    }
                }
                .field--name-field-title,
                .field--name-field-subtitle-product-bundle,
                .field--name-field-body-product-bundle {
                    float: right;
                    width: 100%;
                    @include breakpoint($desk) {
                        width: 50%;
                    }
                }
            }
            .odd {
                .field--name-field-image-product-bundle {
                    float: right;
                    width: 100%;
                    @include breakpoint($desk) {
                        width: 50%;
                    }
                }
                .field--name-field-title,
                .field--name-field-subtitle-product-bundle,
                .field--name-field-body-product-bundle {
                    float: left;
                    width: 100%;
                    @include breakpoint($desk) {
                        width: 50%;
                    }
                }
            }
        }
    }

    .size-guide-wrapper {
        a {
            text-decoration: underline;
            font-weight: bold;
        }
    }

    .production_times{
        color:#8A733D;
        font-size: 15px;
        font-weight: normal;
        line-height: 22px;
        // padding-bottom:20px;
    }
}

// Teaser Mode
.product-teaser {
    text-align: center;
    margin-bottom: 30px;
    min-height: 200px;
    position: relative;
    overflow: hidden;
    @include breakpoint($tab) {
        padding: 0 25px;
    }

    .custom_label_wrapper{
        display: none;
        // width: fit-content;
        // overflow: hidden;
        // position: absolute;
        // top: 1px;
        // left: 13px;
        // z-index: 1;
        width:100%;
        overflow: hidden;
        // position: absolute;
        z-index: 3;
        // top: -20px;
    }

    .custom_label{
        display: inline-block;
        // margin-left: 10px;
        // border-bottom: 2px solid #ffc62d;
        // border-top: 2px solid #ffc62d;
        line-height: 1;
        padding-top: 3px;
        padding-bottom: 3px;
        text-transform: uppercase;
        text-align: center;
        // width: fit-content;
        width: 100%;
        position: relative;
        // padding: 7px 0;
        font-size: 12px;
        font-weight: 500;
        // color:#8A733D;
        color:#8A733D;
        mix-blend-mode: difference;
    }
    @media only screen and (max-width: 768px) {
        .custom_label_wrapper{

        }
        .custom_label{
            display:inline-block;
            width: fit-content;
            margin-right: 10px;
        }
    }
    .is_new{

    }

    .is_exclusive{

    }

    .image-wrapper {
        top:20px;
        position: relative;
        overflow: hidden;
        .image-default {
            opacity: 1;
            -webkit-transition: opacity .4s ease-in-out;
            -moz-transition: opacity .4s ease-in-out;
            -ms-transition: opacity .4s ease-in-out;
            -o-transition: opacity .4s ease-in-out;
            transition: opacity .4s ease-in-out;
        }
        .image-hover {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            -webkit-transition: opacity .4s ease-in-out;
            -moz-transition: opacity .4s ease-in-out;
            -ms-transition: opacity .4s ease-in-out;
            -o-transition: opacity .4s ease-in-out;
            transition: opacity .4s ease-in-out;
        }
        .image-default img,
        .image-default-once img,
        .image-hover img {
            width: 100%;
            height: auto;
            // max-width: 175px;
        }
        a {
            width: 100%;
            height: auto;
        }
        a {
            display: inline-block;
        }
        &:hover {
            @include breakpoint($tab) {
                .image-default {
                    opacity: 0;
                }
                .image-hover {
                    opacity: 1;
                }
            }
        }
    }
    &:hover {
        .image-wrapper {
            .teaser-sizes-wrapper {
                display: block;
            }
        }
    }
    .description-summary {
        position: absolute;
        opacity: 0;
        bottom: 0;
        height: auto;
        background: rgba(255, 255, 255, 0.9);
        cursor: pointer;
        transition-duration: 0.5s;
        font-weight: 400;
        box-shadow: 0 -3px 6px rgba(50, 50, 93, 0.11);
        padding: 10px;
        text-align: left;
        font-size: 14px;
        width: 100%;
    }
    a {
        text-decoration: none;
    }
    &:hover {
        .description-summary {
            // padding-top: 40px;
            opacity: 1;
            transition-duration: 0.5s;
        }
    }
    .field--name-field-brand,
    .field-name-field-brand {
        // margin: 21px 0 0;
        margin: 0px 0px 0px;
        opacity: 0.7;
        color: $main;
        font-size: 14px;
    }
    .product-summary {
        padding: 0 20px;
    }
    h2 {
        font-size: 14px;
        height: 36px;
        overflow: hidden;
        line-height: 18px;
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        a {
            text-decoration: none;
        }
    }
    .teaser_title{
        margin: 10px 0 10px;
        height: 36px;
        vertical-align: middle;
        display: table;
        width: 100%;

    }
    .final-price {
        font-weight: 400;
        padding: 5px 0;
        height: 36px;
        // color: #999;
        color: #757575;
        a {
            // color: #999;
            color: #757575;
            &:hover {
                color: $main;
            }
        }
    }
    .prices-wrapper {
        font-size: 20px;
        @extend .minion-bold;
        color: #444;
    }


    .product-teaser-other-variants{
        // background-color:red;
        // display:none;
        ul.variations{
            list-style-type: none;
            list-style: none;
            // text-align: center;
            // overflow: hidden;
            // margin: 0 auto;

            // border: 1px solid red;
            position: relative;

            // display: grid;
            // grid-template-columns: repeat(6, 1fr);
            // align-items: center;
            // justify-content: center;
            // place-items: center;
            // justify-items: center;
            // column-gap:1px;

            margin: 0 auto;
            width: 100%;
            padding-left: 0px;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: space-around;
            align-content: center;

        }

        ul.variations li.status-disabled {
            background-color:lightcoral;
        }

        ul.variations li a{
            color:black;
            font-size:10px;
            font-weight: bold;
            background-color: transparent;
            color: inherit;
            // display: inline-block;
            text-decoration: none;
            border:0px solid transparent;
        }


        ul.variations li.plus_more a{
            // margin-right: 10px;
            // display: inline-block;
            padding-left:10px;
            color:black;
            font-size:10px;
            font-weight: normal;
        }
         ul.variations li img {
            border:0px solid transparent;
            // width: 25%;
        }
        ul.variations li a.btn {
            display: inline-block;
            color: #fff;
            background-color: #78756D;
            padding: 10px;
            // margin: 1px;
        }

    }

        /* LOGIC */
        .product-teaser-other-variants{
            // min-height:40px;
            min-height:58px;
            // height: 40px;
         }
        ul.variations li img{
            height:40px;
        }
        // ul.variations li.item_3,
        ul.variations li.item_4
        {
            //todo
            display:none;
        }
        // tablet
        @media (min-width:600px) {
            .product-teaser-other-variants{
                min-height:50px;
                // height: 50px;
            }
            // ul.variations li.item_3,
            ul.variations li.item_4
            {
                //todo
                display:block;
            }
            ul.variations li img{
                height:50px;
            }
        }
                /* END LOGIC */

}

.inquire-block {
    display: none;
}

.inquire-block.inquire-block-active {
    display: block;
    margin-top: 50px;
    .webform-client-form {
        .webform-component-checkboxes,
        .webform-component-radios {
            width: 100%;
            .form-item {
                width: 100%;
                input {
                    display: none;
                }
                label {
                    position: relative;
                    padding-left: 20px;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 50%;
                        border: 1px solid $black;
                        display: block;
                        background: transparent !important;
                        width: 14px;
                        height: 14px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        border-radius: 50%;
                        display: block;
                        background: $black;
                        width: 8px;
                        height: 8px;
                        display: none;
                    }
                }
                input:checked+label {
                    &:after {
                        display: block;
                    }
                }
                .form-required {
                    color: #b60303;
                }
            }
        }
    }
}

.zoomLens {
    background-color: #fff;
}

.zoomContainer {
    // overflow: hidden;
}
/*  -------------------------------------------  */

.ribbon-wrapper-gold {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 1;
}

.ribbon-gold {
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform:    rotate(45deg);
  -ms-transform:     rotate(45deg);
  -o-transform:      rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
  background-color: rgb(140, 136, 128);
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
  box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
}

.ribbon-gold:before,
.ribbon-gold:after {
  content: "";
  border-top:   3px solid rgb(140, 136, 128);
  border-left:  3px solid transparent;
  border-right: 3px solid transparent;
  position:absolute;
  bottom: -3px;
}

.ribbon-gold:before {
  left: 0;
}
.ribbon-gold:after {
  right: 0;
}

/*  -------------------------------------------  */

.ribbon-wrapper-1 {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    mix-blend-mode: multiply;

    // width: 85px;
    // height: 88px;
    // top: 20px;
    // right: -3px;

    // top: 19px;
    // right: 35px;


    width: 74px;
    height: 71px;
    top: 20px;
    right: 0px;
}
.ribbon-is_exclusive.ribbon-wrapper-1{
    width: 76px!important;;
    height: 99px!important;;
}

.ribbon-wrapper-is_exclusive.ribbon-1{
    left:-14px!important;
    width:125px!important;
}

.ribbon-1 {
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    left: -5px;
    top: 15px;
    width: 120px;
    // background-color: rgb(140, 136, 128);
    // background-color: #78756D;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.ribbon-1:before,
.ribbon-1:after {
    content: "";
    border-top: 3px solid rgb(140, 136, 128);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
}

.ribbon-1:before {
    left: 0;
}

.ribbon-1:after {
    right: 0;
}

/*  -------------------------------------------  */
.ribbon-wrapper-2 {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    mix-blend-mode: multiply;

    width: 183px;
    height: 113px;
    top: 20px;
    right: -33px;

}
.ribbon-isnew {

}

.ribbon-2 {
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

    width: 244px;
    // left: -11px;
    // top: 33px;

    left: -18px;
    top: 27px;
}


.ribbon-2:before,
.ribbon-2:after {
    content: "";
    border-top: 3px solid rgb(140, 136, 128);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
}

.ribbon-2:before {
    left: 0;
}

.ribbon-2:after {
    right: 0;
}

/*  -------------------------------------------  */
.ribbon-wrapper-3 {
    width: 212px;
    height: 142px;
    overflow: hidden;
    position: absolute;
    top: 20px;
    right: -5px;
    z-index: 1;
    mix-blend-mode: multiply;
}

.ribbon-3 {
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    left: -8px;
    top: 47px;
    width: 301px;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
     -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.ribbon-3:before,
.ribbon-3:after {
    content: "";
    border-top: 3px solid rgb(140, 136, 128);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
}

.ribbon-3:before {
    left: 0;
}

.ribbon-3:after {
    right: 0;
}


/*  -------------------------------------------  */

.ribbon-right-wrapper-1 {
    width: 183px;
    height: 113px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    mix-blend-mode: multiply;
    top: 20px;
    left: 0px;
    line-height: 10px;
    // position: absolute;
    // left: 15px;
    // top: 15px;
    // z-index: 1;
    // overflow: hidden;
    // // width: 75px;
    // // height: 75px;
    // text-align: right;
}

.ribbon-right-1 {
    font-size: 10px;
    font-weight: 400;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    position: absolute;
    // top: 19px;
    // left: -21px;

    // top: 12px;
    // left: -33px;
    // line-height: 17px;
    top: 18px;
    left: -24px;
    line-height: 17px;

}


.ribbon-wrapper-isnew.ribbon-right-1{
    top: 8px!important;
    left: -34px!important;
    line-height: 17px!important;
}
.ribbon-wrapper-is_exclusive.ribbon-right-2{
    top: 21px!important;
    left: -45px!important;
    width: 146px!important;
    line-height: 3px!important;
}
.ribbon-wrapper-soldout.ribbon-1{
    left: -11px !important;
    top: 12px !important;
    width: 120px !important;
    line-height: 10px;
}

.ribbon-wrapper-isnew.ribbon-1{
      left: -11px !important;
    top: 12px !important;
    width: 120px !important;
    line-height: 10px;
}

.ribbon-right-1:before,
.ribbon-right-1:after {
    content: "";
    position: absolute;
    bottom: -3px;
}

.ribbon-right-1:before {
    left: 0;
}

.ribbon-right-1:after {
    right: 0;
}

/*  -------------------------------------------  */
.ribbon-right-wrapper-2 {
    position: absolute;
    width: 183px;
    height: 113px;
    overflow: hidden;
    top: 20px;
    left: 0px;
    z-index: 1;
    mix-blend-mode: multiply;
    line-height: 10px;
}

.ribbon-right-2 {
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    // top: 31px;
    // left: -33px;
    // width: 159px;
    top: 22px;
    left: -41px;
    width: 147px;
    line-height: 10px;
}

/*
// NEW<br />+<br />EXCLUSIVE
.ribbon-right-2--together {
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 2px;
    left: -39px;
    width: 183px;
}
*/
.ribbon-right-2:before,
.ribbon-right-2:after {
    content: "";
    // border-top: 3px solid rgb(140, 136, 128);
    // border-left: 3px solid transparent;
    // border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
}

.ribbon-right-2:before {
    left: 0;
}

.ribbon-right-2:after {
    right: 0;
}

/*  -------------------------------------------  */
.ribbon-right-wrapper-3 {
  position: absolute;
    width: 183px;
    height: 113px;
    overflow: hidden;
    top: 20px;
    left: 0px;
    z-index: 1;
    mix-blend-mode: multiply;
}

.ribbon-right-3 {
  font-size: 10px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 33px;
    left: -9px;
    width: 162px;
}

.ribbon-right-3:before,
.ribbon-right-3:after {
    content: "";
    // border-top: 3px solid rgb(140, 136, 128);
    // border-left: 3px solid transparent;
    // border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
}

.ribbon-right-3:before {
    left: 0;
}

.ribbon-right-3:after {
    right: 0;
}
/* ----------------------------------------------- */
.ribbon-wrapper-bg-grey{
    background-color: #78756D;
}

.ribbon-wrapper-bg-golden{
    background-color: #8A733D;
}

/*  -------------------------------------------  */
.swiss-made {
    padding-left: 23px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 15px;
        height: 15px;
        background-image: url('../images/swiss.png');
        background-repeat: no-repeat;
        background-size: 15px;
    }
}

.combine-with-wrapper,
.recommended-products-wrapper {
    margin-top: 80px;
    @include pie-clearfix;
    h2.block-title {
        font-size: 26px;
        text-align: center;
        font-weight: 400;
        margin: 0 0 30px;
        font-family: "minion-pro", serif;
    }
    .field--name-field-combine-with,
    .field--name-field-recommended-products-speci {
        &>.field__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            &>.field__item {
                float: left;
                display: inline-block;
                width: 50%;
                padding: 5px;
                &:nth-child(2n+1) {
                    clear: both;
                }
                @include breakpoint($tab) {
                    width: 25%;
                    &:nth-child(2n+1) {
                        clear: none;
                    }
                    &:nth-child(4n+1) {
                        clear: both;
                    }
                }
            }
        }
    }
}
