input[type="text"],
input[type="password"],
input[type="email"],
button,
textarea {
  // box-shadow: 0 0 0 1px #d9d9d9;
  box-shadow: none;
  border: 0;
  background: transparent;
  font-size: 14px;
  padding: 10px 40px 10px 15px;
  width: 100%;
  resize: none;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  -webkit-appearance: none;

  &:hover,
  &:focus {
    outline: none;
    // box-shadow: 0 0 0 1px $main;

    &.error {
      border: 1px solid #e72a2a;
      color: #e72a2a;
      box-shadow: none;
      // box-shadow: 0 0 0 1px $red;
    }

    // &.valid {
    //   border-color: #5fad86;
    // }
  }

  &.error {
    border: 1px solid #e72a2a;
    color: #e72a2a;
    box-shadow: none;
  }

  &.valid {
    border: 0;
  }
}

.selector {
  // box-shadow: 0 0 0 1px #d9d9d9;
  box-shadow: none;
  -webkit-appearance: none;
  border: 0;
  border: 1px solid #d9d9d9;
  background: transparent;
  font-size: 14px;
  padding: 10px 40px 10px 15px;
  width: 100% !important;
  resize: none;
  border-radius: 2px;
  position: relative;

  &:before {
    @extend .font-icon;
    @extend .icon-keyboard_arrow_down;
    font-size: 16px;
    color: $base;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  select {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

input[type="submit"] {
  background: $main;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
  padding: 0 20px;
  line-height: 40px;
  border: 0;
  outline: none;
  // box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    @include breakpoint($tab) {
      background: $base;
      color: #fff;
    }
  }
}

.form-type-checkbox label {
  padding-left: 5px;
  position: relative;
  top: 5px;
  margin-bottom: 5px;
}
input[type="checkbox"] {
    margin: 4px 15px 0;
}


.form-item .description {
  margin-top: 5px;
  font-size: 12px;
}

.form-item-commerce-shipping-shipping-service.form-item .description {
  margin-top: 5px;
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: -10px;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + div,
input[type="radio"] + label {
  position: relative;

  &:before {
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    display: block;
    content: "";
    background-color: #ffffff;
    border: 4px solid $base;
    position: absolute;
    top: -2px;
    left: 0;
  }
}

input[type="radio"] + div {
  &:before {
    width: 10px;
    height: 10px;
    font-size: 8px;
    line-height: 10px;
    top: 6px;
  }
}

input[type="radio"]:checked + div,
input[type="radio"]:checked + label {
  &:before {
    background: $base;
  }
}

.form-type-radio label {
  padding-left: 20px;
}
.form-item {
  margin-bottom: 20px;

  label {
    font-size: 12px;
    color: $base;
    text-transform: uppercase;
    margin: 0 0 4px;

    &.error {
      color: #e72a2a;
      margin-top: 5px;
    }
  }
}

.form-actions {
  clear: both;
}

// Contact Form

.node--webform--full {
  .field--name-body {
    text-align: center;
    margin-bottom: 60px;
  }
}

.page-node-5 .webform-client-form .webform-component-checkboxes,
.page-node-5 .webform-client-form .webform-component-radios,
.inquire-block.inquire-block-active .webform-client-form .webform-component-checkboxes,
.inquire-block.inquire-block-active .webform-client-form .webform-component-radios {
  &.webform-component--i-agree-to-the-terms-of-use--the-privacy-policy {
    padding-right: 0;
    width: auto;
    position: relative;
    display: flex;

    @include breakpoint($tab) {
      display: inline-block;
    }

    .description {
      margin-left: 3px;
      position: relative;

      @include breakpoint($tab) {
        margin-left: 0;
        position: absolute;
        right: 0;
        top: 1px;
      }
    }
  }
}

.page-node-5 .webform-client-form,
.webform-client-form {
  @include pie-clearfix;
  max-width: 840px;
  margin: 0 auto;

  .form-item {
    width: 100%;

    @include breakpoint($tab) {
      width: 33.333%;
      float: left;
      display: inline-block;
      padding: 0 10px;
    }

    &.webform-component-textarea {
      @include breakpoint($tab) {
        width: 100%;
      }
    }
  }

  .form-submit {
    display: block;
    margin: 10px auto 0;
    font-weight: 700;
  }
}

.search-form {
  @include pie-clearfix;
  text-align: center;

  .form-type-textfield {
    max-width: 640px;
    display: inline-block;
  }

  input[type="text"] {
    border-radius: 0;
  }

  input[type="submit"] {
    line-height: 39px;
  }
}

.block--search-form {
  display: none;

  .search-form {
    @include pie-clearfix;

    .form-type-textfield {
      max-width: 640px;
      display: inline-block;
    }

    input[type="text"] {
      border-radius: 0;
    }

    input[type="submit"] {
      line-height: 39px;
    }
  }

  &.open-search {
    display: block;

    .search-block-form {
      width: 100%;
      background: #fff;
      z-index: 1;
      position: fixed;
      top: 62px;
      padding: 13px 0;
      left: 0;
      margin: 0;

      @include breakpoint($tab) {
        top: 84px;
      }

      input[type="text"] {
        width: 100%;
        max-width: 270px;
        border: 0;
        border-bottom: 1px solid #000;
        border-radius: 0;
        line-height: 35px;
        color: #000;
        box-shadow: none;
        font-size: 16px;
        padding: 0 20px;
        background: transparent;

        @include breakpoint($tab) {
          max-width: 500px;
        }

        // &:hover {
        //   border-bottom: 1px solid $main;
        // }

      }

      input[type="text"]::-webkit-input-placeholder { /* Edge */
        color: #000;
      }

      input[type="text"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #000;
      }

      input[type="text"]::placeholder {
        color: #000;
      }

      .form-item-search-block-form {
        position: relative;
        cursor: pointer;

        // &:hover {
        //   &:before {
        //     color: $main;
        //   }
        // }

        &:before {
          @extend .font-icon;
          @extend .icon-search;
          color: #000;
          font-size: 16px;
          top: 6px;
          position: absolute;
          line-height: 0;
          right: 0;

           @include breakpoint($tab) {
             right: 15px;
           }
        }
      }

      input[type="submit"] {
        background: transparent;
        color: transparent;
        font-size: 14px;
        font-weight: 400;
        border-radius: 2px;
        padding: 0;
        line-height: 30px;
        border: 0;
        outline: none;
        position: absolute;
        width: 17px;
        margin-left: -16px;

        @include breakpoint($tab) {
          margin-left: -31px;
        }
      }
    }
  }
}

.search-button {
  font-size: 0;
  margin: 0 0 0 22px;
  cursor: pointer;
  display: none;

  @include breakpoint($tab) {
    display: block;
  }

  &:before {
    @extend .font-icon;
    @extend .icon-search;
    font-size: 17px;
    color: #000;
    display: block;
    text-decoration: none;
    width: 17px;
    height: 17px;
    z-index: 10;
  }

  &:hover {
    &:before {
      @include breakpoint($tab) {
        color: #8A733D; /* #8A733D;*/
      }
    }
  }
}

.header-left .search-button {
  display: block;
  width: 18px;
  height: 18px;
  position: relative;
  float: left;
  display: block;
  margin: 0;
  margin-top: 10px;
  margin-right: 7px;
}

.scroll-menu {
  .header-left .search-button {
    margin-top: 2px;
  }

  .block--search-form.open-search .search-block-form {
    top: 77px;
  }
}

.search-block-active.front {
  .l-header {
    background: #fff;
  }
}

.search-block-active .search-button:before {
  @extend .icon-close;
  font-size: 24px;
  top: -3px;
  position: relative;
  left: -4px;
}

.captcha {
  clear: both;
}


