#edit-picture,
.user-profile {
  .user-picture {
    width: 100px;
    height: 100px;
    border: 1px solid #8A733D; /* #8A733D;*/
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.marker,
.form-required {
  color: #b60303;
}

.ms-products-user-purchase-options-form {
  margin: 40px 0;
}

.user-profile {
  position: relative;

  .user-links {
    margin-top: 30px;
  }

  .account-settings-links {
    display: inline-block;
    width: 165px;
    text-align: center;
    padding: 0 20px;
    line-height: 40px;
    color: #fff;
    background: #000;
    border-radius: 3px;
    margin-bottom: 10px;

    &.active-link,
    &:hover,
    &:hover {
      background: $main;
      text-decoration: none;
    }
  }

  .field {
    display: inline-block;
    margin-right: 40px;
    font-size: 18px;

    .field__label {
      font-size: 13px;
      text-transform: uppercase;
    }
  }

  .user-profile-category-history {
    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.user-profile-form {
  max-width: 540px;
  margin: 0 auto;

  fieldset.date-combo .container-inline-date .date-padding {
    padding: 0;
  }

  .field-type-datetime {
    legend {
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

.user-profile-tabs {
  h2 {
    font-weight: bold;
    display: none;
  }
}

#user-list.user-profile-tabs {
  margin-top: 35px;
}

#addressbook-list {
  @include pie-clearfix;

  .shipping-addressbook,
  .billing-addressbook {
    @include breakpoint($tab) {
      width: 50%;
      float: left;
      padding: 0 10px;
    }

    a {
      font-weight: 400;
      font-size: 14px;
    }

    table tbody tr:nth-child(2n+1) {
      background: none;
    }

    table tbody tr {
      padding: 0 10px;
    }

    table tbody tr td {
      padding: 10px;

      .views-field-rendered-entity {
        background: #f0f0f0;
        padding: 20px;

        a {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}

.user-profile-tabs {
  display: none;

  &.active {
    display: block;
  }
}

.shipping-addressbook {
  .view-commerce-addressbook-defaults {
    @include pie-clearfix;
    .views-row {
      @include breakpoint($tab) {
        width: 50%;
        float: left;
      }

      .views-field-rendered-entity {
        background: #f0f0f0;
        padding: 20px;
      }
    }
  }
}

.form-item-mail {
  position: relative;
}

.forgot-password {
  margin-bottom: 20px;
  text-align: right;
}

.info-tooltip {
  position: absolute;
  z-index: 100;
  bottom: 11px;
  right: 15px;
  display: table;
  .info-icon {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-align: center;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    color: #fff;
  }
  .info-content {
    position: absolute;
    display: none;
    top: 22px;
    right: 0;
    width: 360px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    background: #f6f9fc;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
  }

  &:hover .info-content {
    display: block;
  }
}

.sign-in-link,
.sign-up-link {
  margin-top: 50px;
  border-radius: 3px;
  background: #eff0ef;
  padding: 30px;

  &.sign-in-link-dark {
    background: #242527;
    color: #fff;
    margin-top: 10px;

    a {
      color: #fff;
    }
  }
  a{
    font-size: 18px;
    line-height: 20px;
    margin-left: 30px;
  }
}
