.node-type-blog {
    .l-content.container {
        max-width: 1680px;
        width: 100%;
        padding: 0;
        margin: 0 auto;
    }
}

.blogs-breadcrumbs {
    padding: 30px 10px;
    @include breakpoint($tab) {
        padding: 30px 20px;
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            padding-right: 15px;
            position: relative;
            font-size: 13px;
            @include breakpoint($tab) {
                font-size: 14px;
            }
            &.category-breadcrumb-link a {
                text-transform: capitalize;
            }
            &.active {
                color: #8A733D;
                /* #8A733D;*/
                padding: 0;
                &:before {
                    display: none;
                }
            }
            &:before {
                content: '/';
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
            }
        }
    }
}

.node--blog--full,
.node--event--full {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    h1 {
        display: none;
    }
}

.blog-teaser {
    margin-bottom: 30px;
    padding: 0 10px;
    .image-wrapper {
        img {
            width: 100%;
            height: auto;
        }
    }
    h2 {
        margin-top: 0;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .blog-category-wrapper {
        margin: 10px 0 0;
        opacity: .7;
        color: #8A733D;
        /* #8A733D;*/
        font-size: 14px;
        display: inline-block;
        text-transform: uppercase;
        @include breakpoint($tab) {
            font-size: 16px;
        }
    }
}

.paragraph-banner-wrapper {
    .paragraph-image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .video-wrapper {
        position: relative;
    }
    .content-wrapper {
        text-align: center;
        background: rgb(248, 248, 248);
        padding: 30px;
        width: 100%;
    }
    .paragraph-subtitle {
        font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
        text-transform: uppercase;
        line-height: 1.1em;
        display: block;
        color: rgb(190, 1, 0);
        margin-bottom: 5px;
        font-weight: bold;
    }
    .paragraph-title {
        font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
        text-transform: uppercase;
        line-height: 1.1em;
        color: rgb(0, 0, 0);
        margin-bottom: 30px;
        font-weight: bold;
    }
    .paragraph-body {
        font-size: 15px;
        // margin-bottom: 30px;
        margin-bottom: 0px;
        line-height: 1.6em;
        font-weight: 200;
        letter-spacing: 0.05em;
        color: rgb(0, 0, 0);
        blockquote {
            display: inline-block;
            margin: 0 0 0px;
            @media (min-width: 768px) {}
        }
    }
    @media (max-width: 768px) {
        .paragraph-title {
            font-size: 20px;
        }
        .paragraph-subtitle {
            font-size: 17px;
        }
        .paragraph-body {
            blockquote {
                font-size: 15px;
            }
        }
    }
    .paragraph-reference-products-wrapper {
        margin-bottom: 50px;
        padding: 0 25px;
        @include pie-clearfix;
        .paragraph-reference-products-title {
            font-size: 26px;
            text-align: center;
            font-weight: 400;
            margin: 0 0 30px;
            font-family: "minion-pro", serif;
        }
        .field--name-field-reference-products {
            &>.field__items {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                &>.field__item {
                    float: left;
                    display: inline-block;
                    width: 50%;
                    padding: 5px;
                    &:nth-child(2n+1) {
                        clear: both;
                    }
                    @include breakpoint($tab) {
                        width: 25%;
                        &:nth-child(2n+1) {
                            clear: none;
                        }
                        &:nth-child(4n+1) {
                            clear: both;
                        }
                    }
                }
            }
        }
    }
}

.paragraph-banner-in-small-container-wrapper {
    max-width: 1000px;
    text-align: center;
    margin: 3px auto;
    @include breakpoint($tab) {
        margin: 32px auto;
    }
    .paragraph-image {
        margin-bottom: 30px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .paragraph-subtitle {
        font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
        text-transform: uppercase;
        line-height: 1.1em;
        display: block;
        color: rgb(190, 1, 0);
        margin-bottom: 5px;
        font-weight: bold;
        padding: 0 30px;
    }
    .paragraph-body {
        font-size: 15px;
        margin: 30px 0;
        line-height: 1.6em;
        font-weight: 200;
        letter-spacing: 0.05em;
        color: rgb(0, 0, 0);
        padding: 0 30px;
    }
    .paragraph-title {
        font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
        text-transform: uppercase;
        line-height: 1.1em;
        color: rgb(0, 0, 0);
        margin-bottom: 30px;
        font-weight: bold;
        padding: 0 30px;
    }
}

// .paragraph-banner-in-small-container-wrapper .paragraph-id-201
.paragraph-banner-in-small-container-wrapper.paragraph-id-201 .content-wrapper .paragraph-body{
	padding: unset!important;
}


.paragraph-half-video-half-text-wrapper {
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    @include breakpoint($tab) {
        margin: 100px auto;
    }

    .paragraph-image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .right-section,
    .left-section {
        width: 100%;
        @include breakpoint($tab) {
            width: 50%;
        }
    }
    .right-section {
        position: relative;
    }
    .content-wrapper {
        padding: 30px;
        width: 100%;
        height: 100%;
        .paragraph-subtitle {
            font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
            text-transform: uppercase;
            line-height: 1.1em;
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
            color: #fff;
        }
        .paragraph-title {
            font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
            text-transform: uppercase;
            line-height: 1.1em;
            margin-bottom: 10px;
            font-weight: bold;
            color: #fff;
            @include breakpoint($tab) {
                margin-bottom: 30px;
            }
        }
        .paragraph-body {
            font-size: 15px;
            margin-bottom: 30px;
            line-height: 1.6em;
            font-weight: 200;
            letter-spacing: 0.05em;
            color: #fff;
            margin-top: 10px;
            @include breakpoint($tab) {
                margin-top: 30px;
            }
        }
    }
    video {
        display: block;
    }
    .paragraph-video {
        width: 100%;
        height: 100%;
        min-height: 500px;
        .field--name-field-youtube-video,
        .field__items,
        .field__item,
        .embedded-video,
        .player,
        iframe {
            height: 100%;
            width: 100%;
        }
    }
}


.paragraph-half-video-half-text-wrapper.paragraph-hvht-style-normal{

}

.paragraph-half-video-half-text-wrapper.paragraph-hvht-style-centered_verticaly_horizontaly{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px auto;
    .content-wrapper{
        position: relative;
        top: 50%;
    }
    .right-section,
    .left-section {
        width: 100%;
        @include breakpoint($tab) {
            width: 50%;
        }
    }
}


.paragraph-half-image-half-text-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    @include breakpoint($tab) {
        margin-bottom: 0;
    }
    .paragraph-image {
        margin-bottom: 20px;
        @include breakpoint($tab) {
            margin-bottom: 0;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .right-section,
    .left-section {
        width: 100%;
        padding: 0 30px;
        @include breakpoint($tab) {
            width: 50%;
        }
    }
    .image-col {
        padding: 0;
    }
    .paragraph-subtitle {
        font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
        text-transform: uppercase;
        line-height: 1.1em;
        display: block;
        color: rgb(190, 1, 0);
        margin-bottom: 5px;
        font-weight: bold;
    }
    .paragraph-title {
        font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
        text-transform: uppercase;
        line-height: 1.1em;
        color: rgb(0, 0, 0);
        margin-bottom: 10px;
        font-weight: bold;
        @include breakpoint($tab) {
            margin-bottom: 30px;
        }
    }
    .paragraph-body {
        font-size: 15px;
        line-height: 1.6em;
        font-weight: 200;
        letter-spacing: 0.05em;
        color: rgb(0, 0, 0);
        margin-top: 10px;
        @include breakpoint($tab) {
            margin-top: 30px;
        }
        p {
            margin: 0;
        }
    }
    &.revert {
        .right-section {
            order: 1;
            @include breakpoint($tab) {
                order: 2;
            }
        }
        .left-section {
            order: 2;
            @include breakpoint($tab) {
                order: 1;
            }
        }
    }
}

.paragraph-banner-with-absolute-text-wrapper {
    position: relative;
    margin-top: 30px;
    @include breakpoint($tab) {
        margin-top: 100px;
    }
    .paragraph-image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .content-wrapper {
        width: 100%;
        text-align: center;
        padding: 30px;
        @include breakpoint($tab) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .paragraph-title {
            font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
            text-transform: uppercase;
            line-height: 1.1em;
            margin: 0;
            font-weight: bold;
            color: #000;
            @include breakpoint($tab) {
                color: #fff;
                margin: 10px 0;
            }
        }
        .paragraph-subtitle {
            font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
            text-transform: uppercase;
            line-height: 1.1em;
            display: block;
            color: rgb(190, 1, 0);
            margin-bottom: 5px;
            font-weight: bold;
            padding: 0 30px;
            color: #000;
            margin-top: 20px;
            @include breakpoint($tab) {
                color: #fff;
                margin: 0;
            }
        }
        .paragraph-body {
            font-size: 15px;
            margin: 0;
            line-height: 1.6em;
            font-weight: 200;
            letter-spacing: 0.05em;
            color: rgb(0, 0, 0);
            padding: 0;
            color: #000;
            margin-top: 10px;
            margin-bottom: 30px;
            @include breakpoint($tab) {
                color: #fff;
                margin: 0;
            }
        }
    }
}

.page-blogs {
    &.scroll-menu {
        h1 {
            padding-top: 100px;
        }
        .blogs-menu {
            top: 47px;
            position: fixed;
        }
    }
}

.blogs-menu {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 20px;
    background: #fff;
    display: flex;
    justify-content: center;
    z-index: 1;
    ul {
        li {
            a {
                &.active {
                    font-weight: bold;
                    color: #8A733D;
                    /* #8A733D;*/
                }
            }
        }
    }
}

.paragraph-video-text-wrapper {
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    @include breakpoint($tab) {
        margin: 100px auto;
    }
    .paragraph-image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .right-section,
    .left-section {
        width: 100%;
        @include breakpoint($tab) {
            width: 50%;
        }
    }
    .right-section {
        position: relative;
    }
    .content-wrapper {
        padding: 30px;
        width: 100%;
        height: 100%;
        .paragraph-subtitle {
            font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
            text-transform: uppercase;
            line-height: 1.1em;
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
            color: #fff;
        }
        .paragraph-title {
            font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
            text-transform: uppercase;
            line-height: 1.1em;
            margin-bottom: 10px;
            font-weight: bold;
            color: #fff;
            @include breakpoint($tab) {
                margin-bottom: 30px;
            }
        }
        .paragraph-body {
            font-size: 15px;
            margin-bottom: 30px;
            line-height: 1.6em;
            font-weight: 200;
            letter-spacing: 0.05em;
            color: #fff;
            margin-top: 10px;
            @include breakpoint($tab) {
                margin-top: 30px;
            }
        }
    }
    video {
        display: block;
        // min-height:360px;
    }
    .paragraph-video {
        width: 100%;
        height: 100%;
        // min-height:360px;
        .field--name-field-youtube-video,
        .field__items,
        .field__item,
        .embedded-video,
        .player,
        iframe {
            height: 100%;
            width: 100%;
            // min-height:360px;
        }
    }
}

.paragraph-video-text-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    @include breakpoint($tab) {
        margin-bottom: 0;
    }
    .paragraph-image {
        margin-bottom: 20px;
        @include breakpoint($tab) {
            margin-bottom: 0;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .right-section,
    .left-section {
        width: 100%;
        padding: 0 30px;
        @include breakpoint($tab) {
            width: 50%;
        }
    }
    .image-col {
        padding: 0;
    }
    .paragraph-subtitle {
        font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
        text-transform: uppercase;
        line-height: 1.1em;
        display: block;
        color: rgb(190, 1, 0);
        margin-bottom: 5px;
        font-weight: bold;
    }
    .paragraph-title {
        font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
        text-transform: uppercase;
        line-height: 1.1em;
        color: rgb(0, 0, 0);
        margin-bottom: 10px;
        font-weight: bold;
        @include breakpoint($tab) {
            margin-bottom: 30px;
        }
    }
    .paragraph-body {
        font-size: 15px;
        line-height: 1.6em;
        font-weight: 200;
        letter-spacing: 0.05em;
        color: rgb(0, 0, 0);
        margin-top: 10px;
        @include breakpoint($tab) {
            margin-top: 30px;
        }
        p {
            margin: 0;
        }
    }
    &.revert {
        .right-section {
            order: 1;
            @include breakpoint($tab) {
                order: 2;
            }
        }
        .left-section {
            order: 2;
            @include breakpoint($tab) {
                order: 1;
            }
        }
    }
}

/////////////////////////////
.paragraph-video-full-wrapper {
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    @include breakpoint($tab) {
        margin: 100px auto;
    }
    .paragraph-image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .top-section,
    .bottom-section {
        width: 100%;
        @include breakpoint($tab) {
            width: 100%;
        }
    }
    .top-section {
        position: relative;
    }
    .content-wrapper {
        padding: 30px;
        width: 100%;
        height: 100%;
        .paragraph-subtitle {
            font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
            text-transform: uppercase;
            line-height: 1.1em;
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
            color: #fff;
        }
        .paragraph-title {
            font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
            text-transform: uppercase;
            line-height: 1.1em;
            margin-bottom: 10px;
            font-weight: bold;
            color: #fff;
            @include breakpoint($tab) {
                margin-bottom: 30px;
            }
        }
        .paragraph-body {
            font-size: 15px;
            margin-bottom: 30px;
            line-height: 1.6em;
            font-weight: 200;
            letter-spacing: 0.05em;
            color: #fff;
            margin-top: 10px;
            @include breakpoint($tab) {
                margin-top: 30px;
            }
        }
    }
    video {
        display: block;
        // min-height:360px;
    }
    .paragraph-video {
        width: 100%;
        height: 100%;
        // min-height:360px;
        .field--name-field-youtube-video,
        .field__items,
        .field__item,
        .embedded-video,
        .player,
        iframe {
            height: 100%;
            width: 100%;
            // min-height:360px;
        }
    }
}

.paragraph-video-full-wrapper {
    max-width: 1000px;
    // margin: 0 auto;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    @include breakpoint($tab) {
        margin-bottom: 0;
    }
    .paragraph-image {
        margin-bottom: 20px;
        @include breakpoint($tab) {
            margin-bottom: 0;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .top-section,
    .bottom-section {
        width: 100%;
        padding: 0 30px;
        @include breakpoint($tab) {
            width: 100%;
        }
    }
    .image-col {
        padding: 0;
    }
    .paragraph-subtitle {
        font-size: calc(1.125rem + (1vw - 3.2px) * 0.7813);
        text-transform: uppercase;
        line-height: 1.1em;
        display: block;
        color: rgb(190, 1, 0);
        margin-bottom: 5px;
        font-weight: bold;
    }
    .paragraph-title {
        font-size: calc(1.5rem + (1vw - 3.2px) * 0.9375);
        text-transform: uppercase;
        line-height: 1.1em;
        color: rgb(0, 0, 0);
        margin-bottom: 10px;
        font-weight: bold;
        @include breakpoint($tab) {
            margin-bottom: 30px;
        }
    }
    .paragraph-body {
        font-size: 15px;
        line-height: 1.6em;
        font-weight: 200;
        letter-spacing: 0.05em;
        color: rgb(0, 0, 0);
        margin-top: 10px;
        @include breakpoint($tab) {
            margin-top: 30px;
        }
        p {
            margin: 0;
        }
    }
    &.revert {
        .right-section {
            order: 1;
            @include breakpoint($tab) {
                order: 2;
            }
        }
        .left-section {
            order: 2;
            @include breakpoint($tab) {
                order: 1;
            }
        }
    }
}



.paragraph-half-video-half-text-wrapper video{
    width:100%!important;
    height:100%!important;
}   
