@font-face {
  font-family: "myfont";
  src: url('../fonticon/fonts/myfont.eot');
  src: url('../fonticon/fonts/myfont.eot?#iefix') format('eot'),
    url('../fonticon/fonts/myfont.woff2') format('woff2'),
    url('../fonticon/fonts/myfont.woff') format('woff'),
    url('../fonticon/fonts/myfont.ttf') format('truetype'),
    url('../fonticon/fonts/myfont.svg#myfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-icon:before {
  display: inline-block;
  font-family: "myfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.font-icon-2x { font-size: 2em; }
.font-icon-3x { font-size: 3em; }
.font-icon-4x { font-size: 4em; }
.font-icon-5x { font-size: 5em; }
.font-icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.icon-bell:before { content: "\EA01" }
.icon-close:before { content: "\EA02" }
.icon-eye:before { content: "\EA03" }
.icon-facebook:before { content: "\EA04" }
.icon-heart-outlined:before { content: "\EA05" }
.icon-heart:before { content: "\EA06" }
.icon-instagram:before { content: "\EA07" }
.icon-keyboard_arrow_down:before { content: "\EA08" }
.icon-keyboard_arrow_left:before { content: "\EA09" }
.icon-keyboard_arrow_right:before { content: "\EA0A" }
.icon-pause:before { content: "\EA0B" }
.icon-phone:before { content: "\EA0C" }
.icon-play:before { content: "\EA0D" }
.icon-search:before { content: "\EA0E" }
.icon-shopping-bag:before { content: "\EA0F" }
.icon-social-facebook:before { content: "\EA10" }
.icon-volume-low:before { content: "\EA11" }
.icon-volume-mute:before { content: "\EA12" }
