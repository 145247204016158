@import "compass";
@import "breakpoint";
// Import default variables and icon font.
@import "variables/default.scss";
@import "variables/icons.scss";
// Import boostrap and font-awesome.
@import "bootstrap";
@import "font-awesome";
// Import variables
@import "base/default.scss";
// Import custom mixins.
@import "abstractions/mixins.scss";
// Import base styling.
@import "base/navigation.scss";
@import "base/form.scss";
@import "base/table.scss";
// Import components.
@import "components/tabs.scss";
@import "components/user.scss";
@import "components/newsletter.scss";
@import "components/blog.scss";
@import "components/product.scss";
@import "components/checkout.scss";
@import "components/marketingpost.scss";
@import "components/category.scss";
@import "components/stocknotification.scss";
@import "components/rolex.scss";
@import "components/espa.scss";
::selection {
    color: #fff;
    background: $main;
}

// Layout.
.container {
    @include pie-clearfix;
    margin: 0 auto;
    padding: 0 10px;
    @include breakpoint($tab) {
        max-width: 720px;
        @include breakpoint($desk) {
            max-width: 960px;
            @include breakpoint($wide) {
                max-width: 1220px;
            }
        }
    }
    &.no-padding {
        padding: 0;
    }
}

// .header-top {
//   background: #000; /* Old browsers */
//   // background: -moz-linear-gradient(top, #ffffff 0%, #f9f9f9 45%, #eeedeb 91%, #e4e3e1 100%); /* FF3.6-15 */
//   // background: -webkit-linear-gradient(top, #ffffff 0%,#f9f9f9 45%,#eeedeb 91%,#e4e3e1 100%);  Chrome10-25,Safari5.1-6
//   // background: linear-gradient(to bottom, #ffffff 0%,#f9f9f9 45%,#eeedeb 91%,#e4e3e1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//   // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=‘#ffffff’, endColorstr=‘#e4e3e1’,GradientType=0 );
//   text-align: center;
//   padding-top: 3px;
//   @include breakpoint($desk) {
//     display: none;
//   }
// }
.scroll-menu {
    .l-header {
        top: 0;
    }
    .l-header .header-right .header-rolex-clock,
    .l-header .header-right .panerai-clock {
        top: -17px;
    }
}

.scroll-menu.panerai .l-header .header-right .panerai-clock,
.scroll-menu.panerai .l-mobile-menu .panerai-clock {
    display: none;
}

.l-page {
    border-top: 2px solid $main;
    position: relative;
    @include breakpoint($tab) {
        padding-top: 0;
    }
    h1 {
        font-size: 26px;
        text-align: center;
        font-weight: 300;
        margin: 30px 0;
        @include breakpoint($tab) {
            font-size: 30px;
        }
    }
    &.blur {
        -webkit-filter: blur(5px);
        filter: blur(5px);
    }
}

.l-header {
    @include pie-clearfix;
    width: 100%;
    text-align: center;
    padding: 15px 0;
    left: 0;
    z-index: 111;
    background: #fff;
    border-width: 0 0 3px 0;
    // top: 70px;
    top: 2px;
    border-bottom: 1px solid #eeeff0;
    // @include breakpoint($desk) {
    //   top: 0;
    // }
    .front & {
        // position: absolute;
        background: transparent;
        @include breakpoint($desk) {
            // position: fixed;
        }
    }
    .scroll-menu & {
        position: fixed;
        padding: 20px 0;
        background: #fff;
        .l-branding {
            .site-logo {
                display: none;
            }
            .site-small-scroll-logo {
                display: block;
                padding: 0;
            }
        }
        .header-right {
            margin-top: 0;
            padding-top: 6px;
        }
        .header-left {
            margin-top: 6px;
            #mobile-search {
                margin-top: 0;
            }
            #mobile-menu {
                .line {
                    height: 1px;
                    margin-bottom: 3px;
                }
                span:last-child {
                    font-size: 10px;
                    float: left;
                }
            }
        }
    }
    .l-branding {
        float: left;
        width: 30%;
        .site-logo {
            display: none;
            img {
                width: 100%;
                height: auto;
                max-width: 200px;
                filter: brightness(0);
            }
            @include breakpoint($tab) {
                display: block;
            }
        }
        .site-small-scroll-logo {
            display: block;
            margin-top: 6px;
            @include breakpoint($tab) {
                display: none;
            }
            img {
                filter: brightness(0);
            }
        }
        .site-slogan {
            display: none;
        }
    }
    .header-left {
        float: left;
        width: 35%;
        margin-top: 0;
        @include breakpoint($tab) {
            margin-top: 10px;
        }
        .kessaris-phone {
            float: left;
            position: relative;
            margin-top: 8px;
            @include breakpoint($desk) {
                margin-top: 14px;
            }
            .scroll-menu & {
                margin: 0;
            }
            .phone-icon {
                @extend .font-icon;
                @extend .icon-phone;
                color: #000;
                font-size: 18px;
                margin-left: 12px;
                &:hover {
                    color: $main;
                }
            }
            a {
                position: absolute;
                color: #000;
                font-weight: 400;
                top: 30px;
                left: 0;
                display: none;
                @include breakpoint($tab) {
                    width: 120px;
                    top: 1px;
                    left: 35px;
                }
                &.show {
                    display: block;
                }
            }
        }
        #mobile-menu {
            font-size: 11px;
            padding: 2.5px 0;
            float: left;
            text-decoration: none;
            width: 25px;
            height: 30px;
            display: block;
            margin-right: 15px;
            color: #000;
            .line {
                display: block;
                width: 100%;
                height: 2px;
                margin-bottom: 5px;
                background: #000;
            }
            .line.small {
                width: 50%;
            }
            &:hover {
                color: $main;
                .line {
                    background: $main;
                }
            }
            span {
                color: #000;
                // font-size: 10px;
            }
        }
        #mobile-search {
            width: 18px;
            height: 30px;
            position: relative;
            float: left;
            display: block;
            margin-top: 7px;
            margin-right: 7px;
            @include breakpoint($tab) {
                display: none;
            }
            &:before {
                @extend .font-icon;
                @extend .icon-search;
                color: #000;
                font-size: 16px;
                top: 11px;
                position: absolute;
                line-height: 0;
                right: 0;
            }
        }
    }
    .header-right {
        float: left;
        width: 35%;
        padding-top: 6px;
        @include breakpoint($tab) {
            padding-top: 17px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include breakpoint($desk) {
                padding-top: 22px;
            }
        }
        .header-right-wrapper {
            float: right;
        }
        .l-region--header-right {
            float: right;
        }
        #block-search-form,
        #block-mazeblock-commerce-cart-menu,
        .kessaris-phone,
        .block--mazeblock-wishlist {
            float: right;
            margin-left: 15px;
            @include breakpoint($tab) {
                margin: 0;
            }
        }
        #block-mazeblock-commerce-cart-menu {
            margin: 0 8px 0 22px;
        }
        .block--mazeblock-wishlist {
            .block__title {
                display: none;
            }
            .block__content {
                position: relative;
            }
            .wishlist-count {
                width: 35px;
                position: absolute;
                float: left;
                color: #fff;
                top: -3px;
                left: -35px;
                &:hover {
                    text-decoration: none;
                    color: $main;
                }
                .heart-icon {
                    position: relative;
                    font-size: 25px;
                    margin-top: 2px;
                    display: inline-block;
                    color: #000;
                    &:before {
                        @extend .icon-heart;
                        @extend .font-icon;
                    }
                }
                .count {
                    font-size: 11px;
                    position: absolute;
                    width: 15px;
                    top: 0px;
                    background: #8A733D;
                    border-radius: 10px;
                    color: #fff;
                    font-weight: bold;
                    right: -3px;
                    height: 15px;
                    display: none;
                    text-align: center;
                    &.not-empty {
                        display: block;
                    }
                }
            }
        }
        .cart-wrapper {
            width: 35px;
            float: left;
            position: relative;
            float: left;
            color: #fff;
            font-size: 11px;
            margin-left: 0;
            cursor: pointer;
            &:hover {
                color: $main;
            }
            @include breakpoint($tab) {
                margin-left: 0;
            }
            .cart-button {
                @extend .font-icon;
                @extend .icon-shopping-bag;
                font-size: 0;
                color: #000;
                &:before {
                    font-size: 20px;
                }
            }
            #cart-wrapper-items .cart-count {
                font-size: 11px;
                position: absolute;
                width: 15px;
                top: -3px;
                background: #8A733D;
                border-radius: 10px;
                color: #fff;
                font-weight: bold;
                right: -3px;
                height: 15px;
                display: block;
                text-align: center;
            }
        }
        .header-rolex-clock,
        .panerai-clock {
            display: none;
            @include breakpoint($desk) {
                display: inline-block;
                top: -3px;
                margin-right: 180px;
                position: absolute;
                right: 0;
            }
            a {
                display: block;
                width: 100%;
                height: 100%;
                color: transparent;
            }
            br,
            p {
                display: none;
            }
        }
    }
    .l-header-inner {
        position: relative;
    }
}

// logic : never display panerai on mobile
.l-header .header-right .panerai-clock,
.l-mobile-menu .panerai-clock {
    display: none;
}

// logic :  display rolex on mobile except Branded Pages
// ON DESKTOP : do not show
// ON Mobile : show!
.page-taxonomy-term-44 .header-right #block-block-11,
.page-taxonomy-term-124 .header-right #block-block-11,
.page-taxonomy-term-652 .header-right #block-block-11,
.page-taxonomy-term-539 .header-right #block-block-11,
.page-taxonomy-term-542 .header-right #block-block-11,
.page-taxonomy-term-205 .header-right #block-block-11,
.page-taxonomy-term-162 .header-right #block-block-11,
.page-taxonomy-term-201 .header-right #block-block-11,
.page-taxonomy-term-105 .header-right #block-block-11,
.page-taxonomy-term-553 .header-right #block-block-11,
.page-taxonomy-term-250 .header-right #block-block-11,
.page-taxonomy-term-526 .header-right #block-block-11,
.page-taxonomy-term-646 .header-right #block-block-11,
.page-taxonomy-term-502 .header-right #block-block-11,
.page-taxonomy-term-525 .header-right #block-block-11,
.page-taxonomy-term-213 .header-right #block-block-11,
.page-taxonomy-term-110 .header-right #block-block-11,
.page-taxonomy-term-57 .header-right #block-block-11,
.page-taxonomy-term-9 .header-right #block-block-11,
.page-taxonomy-term-524 .header-right #block-block-11,
.page-taxonomy-term-143 .header-right #block-block-11,
.page-taxonomy-term-635 .header-right #block-block-11,
.page-taxonomy-term-133 .header-right #block-block-11,
.page-taxonomy-term-99 .header-right #block-block-11,
.page-taxonomy-term-152 .header-right #block-block-11,
.page-taxonomy-term-80 .header-right #block-block-11,
.page-taxonomy-term-522 .header-right #block-block-11,
.page-taxonomy-term-208 .header-right #block-block-11,
.page-taxonomy-term-562 .header-right #block-block-11,
.page-taxonomy-term-414 .header-right #block-block-11,
.page-taxonomy-term-68 .header-right #block-block-11,
.page-taxonomy-term-285 .header-right #block-block-11,
.page-taxonomy-term-538 .header-right #block-block-11,
.page-taxonomy-term-261 .header-right #block-block-11,
.page-taxonomy-term-45 .header-right #block-block-11,
.page-taxonomy-term-168 .header-right #block-block-11,
.page-taxonomy-term-182 .header-right #block-block-11,
.page-taxonomy-term-159 .header-right #block-block-11,
.page-taxonomy-term-518 .header-right #block-block-11,
.page-taxonomy-term-116 .header-right #block-block-11,
.page-taxonomy-term-944 .header-right #block-block-11,
.page-taxonomy-term-709 .header-right #block-block-11,
.page-taxonomy-term-710 .header-right #block-block-11
{
    display:none;
}



.page-taxonomy-term-80.mobile-menu-active .block--block-17{
display: none;
}

.panerai .l-header .header-right .header-rolex-clock,
.panerai .l-mobile-menu .header-rolex-clock {
    display: none;
}

.panerai .l-header .header-right .panerai-clock {
    display: none;
    @include breakpoint($tab) {
        display: block;
    }
}

.panerai .l-mobile-menu .panerai-clock {
    display: block;
    @include breakpoint($tab) {
        display: none;
    }
    .clock_widget {
        margin: 20px auto;
    }
}

.page-taxonomy-term-80 {
    .l-header .header-right .panerai-clock {
        display: none;
        @include breakpoint($tab) {
            display: block;
        }
    }
    .l-mobile-menu .panerai-clock {
        display: block;
        @include breakpoint($tab) {
            display: none;
        }
        .clock_widget {
            margin: 20px auto;
        }
    }

    .l-header .header-right .header-rolex-clock
    // , .l-mobile-menu .header-rolex-clock
    {
        display: none;
    }
}

.l-breadcrumb {
    // background-color: $main;
    padding: 15px 0;
    .breadcrumb {
        padding: 0 20px;
        margin: 0;
        background-color: transparent;
        li:before {
            color: #000;
            padding-right: 2px;
        }
    }
}

.l-main {
    @include pie-clearfix;
    @include clearfix;
    .content-wrapper {
        display: inline-block;
        &.with-sidebar {
            margin-top: 50px;
            margin-bottom: 100px;
            padding: 0 20px;
            @include breakpoint($tab) {
                float: left;
                width: 66.666%;
            }
        }
        &.no-sidebar {
            width: 100%;
            position: relative;
            .page-user & {
                padding: 0 20px 100px;
            }
        }
        .page-taxonomy-term & {
            width: 100%;
        }
    }
    .front & {
        .node--page--full {
            padding: 0;
            .field--name-body {
                max-width: none;
                .layout-wrapper {
                    text-align: left;
                    .step-wrapper {
                        margin: 50px 0;
                        position: relative;
                        h3 {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-weight: 700;
                            font-size: 32px;
                            margin: 0;
                        }
                        &:nth-child(2n) {
                            text-align: right;
                            h3 {
                                right: auto;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.main-navigation {
    width: 100%;
    background: $base;
}

.l-prefooter {
    text-align: center;
    .form-item {
        float: none;
    }
}

.newsletter-promo {
    background: $neutral;
    padding: 10px 26px;
    text-align: center;
    @include breakpoint($tab) {
        padding: 20px 0;
    }
    p {
        margin: 0;
    }
    .block__title {
        font-size: 14px;
        margin-bottom: 10px;
        @extend .helvetica;
    }
    .webform-client-form {
        .form-actions {
            display: none;
        }
        label {
            display: none;
        }
        .form-item {
            margin: 0;
        }
        input[type="email"] {
            border-radius: 2px;
            padding: 5px 10px;
            border: 1px solid #000;
            box-shadow: none;
        }
    }
}

.eshop-info {
    border-top: 1px solid $base;
    margin-top: 40px;
    padding: 20px 0;
    @include breakpoint($tab) {
        margin-top: 80px;
        padding: 50px 0;
    }
    .block__content {
        @extend .container;
    }
    h2 {
        font-size: 15px;
        letter-spacing: 1px;
        position: relative;
        @extend .helvetica-regular;
        padding-bottom: 12px;
        @include breakpoint($tab) {
            padding-bottom: 20px;
        }
        &:after {
            content: "";
            position: absolute;
            width: 25px;
            height: 1px;
            display: block;
            background: $base;
            text-align: center;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
        }
    }
}

// Footer region.
.l-footer {
    font-size: 15px;
    font-weight: 400;
    padding-top: 40px;
    background: $base;
    .payment-methods {
        text-align: center;
        background: #fff;
        img {
            max-width: 100%;
            width: auto;
            height: auto;
        }
        @include breakpoint($tab) {
            img {
                width: auto;
                height: auto;
            }
        }
    }
    p {
        margin: 0;
    }
    .footer-bottom {
        border-top: 1px solid $main;
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        padding: 10px;
        .footer-bottom-inner {
            padding: 0 20px;
        }
        a {
            color: $main;
        }
        .copyrights {
            text-align: center;
        }
    }
}

.l-region--footer {
    @include pie-clearfix;
    padding-bottom: 20px;
    h4 {
        color: #fff;
    }
    &>div,
    &>nav {
        width: 100%;
        padding-left: 20px;
        @include breakpoint($tab) {
            float: left;
            width: 100%;
            @include breakpoint($desk) {
                padding-left: 0;
                padding-bottom: 0;
                width: 33%;
                padding: 0 20px;
            }
        }
    }
    .block--block-15 {
        margin-top: 20px;
        width: 100%;
        padding: 0;
        @include breakpoint($desk) {
            float: left;
            margin-top: 0;
            width: 33%;
            padding: 0 20px;
        }
    }
    &>#block-webform-client-block-6 {
        padding-right: 0;
    }
    h2 {
        padding-bottom: 10px;
        margin: 0 0 10px;
        position: relative;
        border-bottom: 1px solid #ccc;
        display: inline-block;
        min-width: 100px;
        color: #fff;
        display: none;
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            display: block;
            text-align: center;
            padding-bottom: 10px;
            width: 50%;
            display: inline-block;
            @include breakpoint($tab) {
                width: 100%;
                padding-bottom: 5px;
                @include breakpoint($desk) {
                    text-align: left;
                }
            }
            a {
                color: #fff;
                font-size: 12px;
                padding: 0;
            }
        }
    }
    #block-menu-menu-footer-menu ul {
        @include breakpoint($tab) {
            column-count: 2;
            -moz-column-count: 2;
            -webkit-column-count: 2;
            -moz-column-gap: 20px;
            -webkit-column-gap: 20px;
            column-gap: 20px;
        }
    }
    .find-us {
        @include pie-clearfix;
        margin-top: 10px;
        text-align: center;
        a.social {
            width: 25px;
            height: 25px;
            color: #fff;
            margin: 0px 15px;
            display: inline-block;
            font-size: 0;
            position: relative;
            &:before {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 25px;
            }
            &:hover {
                color: $main;
            }
        }
        a.facebook:before {
            @extend .font-icon;
            @extend .icon-social-facebook;
            font-size: 35px;
            width: 30px;
            height: 30px;
            top: -5px;
        }
        .instagram:before {
            @extend .font-icon;
            @extend .icon-instagram;
        }
    }
    #block-webform-client-block-6 {
        margin-top: 25px;
        .form-item label {
            display: none;
        }
        .webform-client-form .form-item {
            width: 100%;
        }
        input[type="email"] {
            background: #fff;
            box-shadow: none;
            padding: 5px 40px 5px 15px;
            border-radius: 2px;
        }
        .form-actions {
            display: none;
        }
    }
}

.front {
    h1 {
        display: none;
    }
    .content-wrapper>div {
        margin: 0;
        margin-bottom: 20px;
        @include breakpoint($tab) {
            margin-bottom: 80px;
        }
    }
}

.not-logged-in.page-user {
    .content-wrapper {
        margin: 0 auto 100px;
        display: block;
        width: 100%;
        max-width: 480px;
        input[type="submit"] {
            margin: 0 auto;
            display: block;
        }
    }
}

.node--page--full {
    padding: 50px 0;
    max-width: 960px;
    margin: 0 auto;
    h1 {
        font-size: 26px;
        text-align: center;
        font-weight: 300;
        margin: 0 0 30px;
        @include breakpoint($tab) {
            font-size: 30px;
        }
    }
    .field--name-body {
        line-height: 19px;
        width: 100%;
        max-width: 720px;
        margin: 0 auto;
        .node-type-webform & {
            text-align: center;
            margin-bottom: 60px;
            h4 {
                font-weight: 4px;
                font-size: 15px;
            }
            .phone,
            .email {
                display: inline-block;
                margin: 0 10px;
                font-size: 20px;
            }
        }
    }
    .webform-client-form {
        width: 100%;
        max-width: 840px;
        margin: 0 auto;
        display: block;
    }
    .field--type-paragraphs {
        margin-top: 40px;
        .gallery-thumbs {
            display: none;
        }
        .entity-paragraphs-item .content {
            @include pie-clearfix;
            .field--name-field-image {
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .field--name-field-year {
                @extend .minion;
                font-style: italic;
                font-size: 20px;
                color: $main;
                padding: 50px 20px 10px;
                @include breakpoint($tab) {
                    padding: 50px 50px 10px;
                }
            }
            .field--name-title-field,
            .field--name-field-title,
            .field--name-field-tile-title {
                @extend .minion;
                padding: 30px 20px 0;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 20px;
                @include breakpoint($tab) {
                    padding: 50px 50px 25px;
                    font-size: 15px;
                    @include breakpoint($desk) {
                        font-size: 20px;
                    }
                }
            }
            .field--name-field-body {
                padding: 20px;
                opacity: 0.8;
                text-align: justify;
                @include breakpoint($tab) {
                    padding: 12px 50px;
                    @include breakpoint($desk) {
                        padding: 0 50px;
                    }
                }
                p {
                    @include breakpoint($tab) {
                        margin: 0 0 7px;
                        font-size: 12px;
                        @include breakpoint($desk) {
                            margin: 0 0 10px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .even {
            .field--name-field-image,
            .field--name-field-top-image {
                float: left;
                width: 100%;
                @include breakpoint($tab) {
                    width: 50%;
                }
            }
            .field--name-field-tile-title,
            .field--name-field-title,
            .field--name-title-field,
            .field--name-field-body,
            .field--name-field-year {
                float: right;
                width: 100%;
                @include breakpoint($tab) {
                    width: 50%;
                }
            }
        }
        .odd {
            .field--name-field-image,
            .field--name-field-top-image {
                float: right;
                width: 100%;
                @include breakpoint($tab) {
                    width: 50%;
                }
            }
            .field--name-field-tile-title,
            .field--name-field-title,
            .field--name-title-field,
            .field--name-field-body,
            .field--name-field-year {
                float: left;
                width: 100%;
                @include breakpoint($tab) {
                    width: 50%;
                }
            }
        }
    }
    .owl-controls {
        margin: 0;
    }
    .owl-controls .owl-buttons {
        .owl-prev,
        .owl-next {
            font-size: 0;
            z-index: 2;
            background: #fff;
            color: black;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            margin: auto;
            padding: 0;
            border: 0;
            opacity: 1;
            // box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.26);
            box-shadow: 0px 0px 7px 0px rgb(0, 0, 0);
        }
        .owl-prev {
            left: -20px;
            top: 80px;
            @include breakpoint($desk) {
                top: 140px;
            }
            .image-wrapper & {
                top: 49%;
            }
            &:before {
                @extend .font-icon;
                @extend .icon-keyboard_arrow_left;
                color: $main;
                font-size: 30px;
                position: absolute;
                display: block;
                top: 5px;
                right: 5px;
            }
        }
        .owl-next {
            right: -20px;
            top: 80px;
            @include breakpoint($desk) {
                top: 140px;
            }
            .image-wrapper & {
                top: 49%;
            }
            &:before {
                @extend .font-icon;
                @extend .icon-keyboard_arrow_right;
                color: $main;
                font-size: 30px;
                position: absolute;
                display: block;
                top: 5px;
                right: 5px;
            }
        }
    }
}

.i18n-el .node--page--full .field--type-paragraphs .entity-paragraphs-item .content .field--name-field-tile-title,
.i18n-el .node--page--full .field--type-paragraphs .entity-paragraphs-item .content .field--name-field-tile-title {
    @include breakpoint($tab) {
        padding: 30px 50px 15px;
    }
}

.cookie-consent {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 499;
    display: block;
    width: 200px;
    background: $main;
    padding: 30px;
    color: $grey;
    a {
        color: $black;
    }
    button {
        background: $black;
        color: #fff;
        margin-top: 20px;
        padding: 5px 15px;
        border: 1px solid $black;
        display: block;
    }
}

#block-commerce-cart-cart {
    display: none;
    position: fixed;
    z-index: 499;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: none;
    display: none;
    text-align: center;
    background: $grey;
    padding-top: 30px;
    @include breakpoint($desk) {
        right: -400px;
        width: 400px;
    }
    .block__title {
        @extend .helvetica;
    }
    body.cart-block-active & {
        display: block;
        box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.5);
        .cart-block-close {
            display: block;
            text-decoration: none;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 20px;
            right: 15px;
            z-index: 10;
            @include breakpoint($tab) {
                width: 25px;
                height: 25px;
                top: 25px;
            }
            .line {
                display: block;
                width: 100%;
                height: 2px;
                background: $base;
                position: absolute;
                top: 7px;
                &:first-child {
                    -ms-transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                &:last-child {
                    -ms-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }
    }
    .views-field-edit-delete {
        input {
            background: transparent;
            color: transparent;
            background-image: url(../images/icons/close.svg);
        }
    }
    .views-field.views-field-quantity {
        display: none;
    }
    .title-image-wrapper {
        text-align: left;
        .cart-image-wrapper {
            float: left;
            max-width: 50px;
            margin-right: 20px;
            display: inline-block;
            position: relative;
            img {
                width: 100%;
                height: auto;
            }
            span.quantity {
                position: absolute;
                font-size: 12px;
                display: block;
                right: -10px;
                background: rgba(0, 0, 0, 0.45);
                color: #fff;
                width: 22px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                top: -10px;
            }
        }
        a {
            font-size: 12px;
            text-align: left;
            @include breakpoint($tab) {
                font-size: 14px;
            }
        }
    }
    .line-item-summary {
        padding: 30px 10px;
    }
    ul.links {
        text-align: center;
        margin-top: 20px;
        li {
            float: none;
            display: inline-block;
            margin: 0 5px;
            &.line-item-summary-view-cart a {
                background: #000;
            }
        }
        a {
            background: $main;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            border-radius: 2px;
            padding: 0 20px;
            line-height: 47px;
            border: 0;
            outline: none;
            box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        }
    }
}

.hero-banner {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    height: auto;
    @include breakpoint($tab) {
        margin-bottom: 0;
        // height: 350px;
        @include breakpoint($desk) {
            // height: 600px;
        }
    }
    .view-content,
    .views-row,
    .slide-wrapper,
    .view-hero-banner,
    .view-hero-banner .view-content *,
    .block__content {
        @include pie-clearfix;
        height: 100%;
        margin: 0 auto;
        width: 100%;
        padding: 0;
        overflow: hidden;
    }
    a.slide-wrapper {
        display: block;
    }
    video {
        @include breakpoint($tab) {
            // width: auto !important;
            // height: auto !important;
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // min-width: 50%;
            // min-height: 50%;
            // transform: translateX(-50%) translateY(-50%);
            // display: none;
            display: block;
        }
    }
    .view-hero-banner .view-content .mob-video {
        position: relative;
        @include breakpoint($tab) {
            position: unset;
        }
        video {
            display: block;
        }
        .video-controls {
            display: block;
        }
    }
    .view-hero-banner .view-content .video-controls {
        position: absolute;
        width: auto;
        height: auto;
        padding: 3px 10px 0;
        bottom: 20px;
        right: 20px;
        z-index: 1;
        display: none;
        span {
            color: #fff;
            &:hover {
                color: $main;
            }
        }
        .play-pause {
            width: 18px;
            height: 18px;
            display: inline-block;
            cursor: pointer;
            margin: 0 5px;
            @include breakpoint($tab) {
                width: 26px;
                height: 26px;
            }
            &:before {
                @extend .font-icon;
                @extend .icon-pause;
                font-size: 18px;
                @include breakpoint($tab) {
                    font-size: 26px;
                }
            }
            &.play {
                &:before {
                    @extend .font-icon;
                    @extend .icon-play;
                    font-size: 18px;
                    @include breakpoint($tab) {
                        font-size: 26px;
                    }
                }
            }
        }
        .mute {
            width: 18px;
            height: 18px;
            display: inline-block;
            cursor: pointer;
            margin: 0 5px;
            @include breakpoint($tab) {
                width: 26px;
                height: 26px;
            }
            &:before {
                @extend .font-icon;
                @extend .icon-volume-mute;
                font-size: 18px;
                @include breakpoint($tab) {
                    font-size: 26px;
                }
            }
            &.off {
                &:before {
                    @extend .font-icon;
                    @extend .icon-volume-low;
                    font-size: 18px;
                    @include breakpoint($tab) {
                        font-size: 26px;
                    }
                }
            }
        }
        @include breakpoint($desk) {
            display: block;
        }
    }
    .view-hero-banner .view-content .slide-wrapper {
        position: relative;
        text-align: center;
        background-position: center;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-repeat: no-repeat;
        //background-color: rgba(0,0,0, 0.4);
        background-blend-mode: multiply;
        // &:before {
        //   content: '';
        //   display: none;
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   bottom: 0;
        //   left: 0;
        //   // background: -moz-linear-gradient(top, transparent 29%, rgba(0,0,0,0.5) 71%); /* FF3.6-15 */
        //   // background: -webkit-linear-gradient(top, transparent 29%, rgba(0,0,0,0.5) 71%); /* Chrome10-25,Safari5.1-6 */
        //   // background: linear-gradient(to bottom, transparent 29%,  rgba(0,0,0,0.5) 71%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        //   // opacity: 0.5;
        //   @include breakpoint($tab) {
        //     display: block;
        //   }
        // }
        &.with-filter {
            background-color: rgba(0, 0, 0, 0.4);
            background-blend-mode: multiply;
        }
        .slide-content {
            position: relative;
            width: 100%;
            width: 100%;
            max-width: 100%;
            padding: 10px;
            margin: 0 auto;
            @include breakpoint($tab) {
                max-width: 900px;
                padding: 24px 40px;
            }
            .title {
                @extend .minion;
                height: auto;
                margin: 20px 0 10px;
                font-size: 26px;
                font-weight: 100;
                color: #000;
                @include breakpoint($tab) {
                    margin: 0 0 10px;
                    @include breakpoint($desk) {
                        font-size: 28px;
                        @include breakpoint(1500px) {
                            font-size: 34px;
                        }
                    }
                }
            }
            .body {
                @extend .minion;
                font-weight: 300;
                font-size: 13px;
                height: auto;
                margin: 0 auto;
                color: #000;
                @include breakpoint($tab) {
                    // max-width: 420px;
                    margin: 0 auto;
                    font-size: 15px;
                    margin-bottom: 20px;
                    @include breakpoint($desk) {
                        margin: 0 0 20px;
                        @include breakpoint(1500px) {
                            font-size: 17px;
                        }
                    }
                }
            }
            .cta-button {
                margin-top: 15px;
                display: block;
                // max-width: 270px;
                margin: 15px auto 0;
                a {
                    @extend .minion;
                    font-size: 15px;
                    font-weight: 400;
                    letter-spacing: 3.2px;
                    color: #fff;
                    background: #8A733D;
                    /* #8A733D rgba(159,133,70,0.7)*/
                    ;
                    padding: 20px;
                    text-align: center;
                    border-radius: 50px;
                    display: inline-block;
                    border: 1px solid #8A733D;
                    /*rgba(159,133,70,0.7);*/
                    width: auto;
                    @include breakpoint($tab) {
                        // border-radius: 2px;
                        // display: inline-block;
                        // width: auto;
                        // text-decoration: none;
                        // font-size: 12px;
                        // color: #fff;
                        // background: #bc944f;
                        // background: linear-gradient(to bottom,#bc944f 1%,#d3b945 23%,#e2cb6f 52%,#d3b945 77%,#bc944f 100%);
                        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bc944f',endColorstr='#bc944f',GradientType=0);
                        // background: #8A733D;
                        // padding: 15px 25px;
                        padding: 20px;
                        font-size: 15px;
                    }
                    &:hover {
                        background: #8A733D;
                        border: 1px solid #8A733D;
                        ;
                        color: #fff;
                        text-decoration: none;
                    }
                }
                @include breakpoint($tab) {
                    margin: 15px 0 0;
                }
            }
        }
    }
    .views-slideshow-pager-fields.widget_pager {
        display: none !important;
        position: absolute;
        width: auto;
        height: 38px;
        display: table;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%);
        bottom: 0;
        z-index: 100;
        .views_slideshow_pager_field_item {
            cursor: pointer;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            content: "";
            display: block;
            height: 10px;
            margin: 5px;
            width: 10px;
            background: #818181;
            position: relative;
            top: 8px;
            &.active {
                border: 1px solid #818181;
                background: transparent;
                border-radius: 50%;
                height: 20px;
                margin: 10px;
                width: 20px;
                top: 0;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    left: -1px;
                    top: -1px;
                    height: 10px;
                    margin: 5px;
                    width: 10px;
                    border-radius: 50%;
                    background: #818181;
                }
            }
        }
    }
    .views_slideshow_controls_text.views-slideshow-controls-text {
        position: absolute;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
        .views_slideshow_controls_text_previous {
            float: left;
            transform: rotate(-180deg);
        }
        .views_slideshow_controls_text_next {
            float: right;
        }
        .views_slideshow_controls_text_previous,
        .views_slideshow_controls_text_next {
            width: 22px;
            height: 40px;
            position: relative;
            margin: 0 40px;
            a {
                color: transparent;
                width: 40px;
                height: 40px;
                overflow: hidden;
                display: inline-block;
                &:before {
                    @extend .font-icon;
                    @extend .icon-keyboard_arrow_right;
                    color: $main;
                    font-size: 40px;
                    position: absolute;
                    z-index: 1;
                    pointer-events: none;
                    top: 0;
                    left: -5px;
                }
                &:hover {
                    &:before {
                        color: $main;
                    }
                }
            }
        }
        .views_slideshow_controls_text_pause {
            display: none;
        }
    }
}

.owl-pagination {
    .owl-page {
        &.active {
            position: relative;
            &:after {
                content: "";
            }
        }
    }
}

.section-about-us {
    .field--name-title-field {
        margin-top: 20px;
        padding: 0;
        @include breakpoint($tab) {
            margin-top: 40px;
            padding: 0 30px;
            @include breakpoint($desk) {
                padding: 0 50px;
                margin-top: 70px;
            }
        }
    }
    .field--name-field-body {
        padding: 20px 0;
        @include breakpoint($tab) {
            padding: 20px 30px;
            @include breakpoint($desk) {
                padding: 20px 50px;
            }
        }
        h2 {
            font-size: 20px;
            margin: 5px;
            color: #c5c5c5;
            text-align: center;
            font-weight: 700;
        }
    }
}

.section-stores {
    .l-main {
        padding-bottom: 100px;
    }
    .field--name-title-field {
        text-align: center;
        margin-top: 20px;
        @extend .minion;
    }
    .field--name-field-body {
        text-align: center;
        a {
            color: $main;
            &:hover {
                text-decoration: none;
            }
        }
    }
    #map {
        height: 400px;
        width: 100%;
    }
}

.section-brands .field--name-field-logos {
    .field__label {
        display: none;
    }
    .field__item {
        width: 33.3333%;
        padding: 50px;
        float: left;
        text-align: center;
        display: inline-block;
        &:nth-child(3n+1) {
            clear: both;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}

#block-views-recommended-products-block {
    margin-top: 80px;
}

.field.field--name-field-top-image.field--type-image.field--label-hidden .field__item {
    float: left;
    padding: 10px 5px;
}

.owl-nav {
    display: none;
}

div#orders-list {
    h2 {
        display: none;
    }
    table td a {
        font-size: 16px;
        color: #8A733D;
    }
}

@media (max-width:600px) {
    .user-profile .user-links {
        display: none;
    }
    .user-links-toggle {
        text-align: center;
        border: 1px solid;
        padding: 10px;
        border-radius: 2px;
    }
    .user-profile .account-settings-links {
        width: 100%;
    }
}

@media (min-width:600px) {
    .user-profile .user-links {
        display: block;
    }
    .user-links-toggle {
        display: none;
    }
}

.view-commerce-backoffice-user-orders td.active {
    background-color: transparent;
}

.view.view-wishlist {
    .view-empty p {
        text-align: center;
        margin: 40px 0;
        font-size: 24px;
    }
    a.flag.unflag-action {
        background: transparent;
        color: transparent;
        background-image: url(../images/icons/close.svg);
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
        background-size: contain;
        display: inline-block;
        margin: 10px;
        vertical-align: middle;
    }
    table tbody tr {
        background: #fff!important;
        border-bottom: 1px solid #f0f0f0;
    }
    .for-sale-1 a.wishlist-enquiry.inquire-button {
        display: none;
    }
    @media (max-width:600px) {
        td.views-field.views-field-field-product-image {
            display: none;
        }
        td.views-field.views-field-commerce-price {
            display: none;
        }
    }
    @media (min-width:600px) {
        .wishlist-item__image-mobile {
            display: none;
        }
        .product-price {
            display: none;
        }
    }
    .wishlist-item__actions.for-sale-0 {
        .product-price {
            display: none;
        }
        .product-addtocart {
            display: none;
        }
    }
    td.views-field.views-field-nothing {
        position: relative;
    }
    .wishlist-item__actions {
        text-align: center;
        vertical-align: bottom;
    }
    .remove-action {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .product-price {
        margin: 10px 0;
    }
    a.wishlist-enquiry {
        margin: 20px 0;
        display: block;
    }
}

@include breakpoint($desk) {
    .simple-content {
        opacity: 0;
        position: absolute;
        top: 0!important;
        color: #fff;
        margin: 0!important;
        background: rgba(0, 0, 0, 0.4);
        padding: 30px;
        transition-duration: 0.5s;
        bottom: 0!important;
        left: 0!important;
        right: 0!important;
    }
    .gradient-block {
        background: #000;
    }
    .margeting-post-teaser.banner .slide-content {
        float: none;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include breakpoint($tab) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
            bottom: unset;
            right: unset;
        }
    }
    .product-teaser .image-wrapper img {
        width: 100%;
        height: auto;
        max-width: 100%;
    }
}

.video-controls {
    position: absolute;
    width: auto;
    height: auto;
    padding: 3px 10px 0;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    span {
        color: #fff;
        &:hover {
            color: $main;
        }
    }
    .play-pause {
        width: 18px;
        height: 18px;
        display: inline-block;
        cursor: pointer;
        margin: 0 5px;
        @include breakpoint($tab) {
            width: 26px;
            height: 26px;
        }
        &:before {
            @extend .font-icon;
            @extend .icon-pause;
            font-size: 18px;
            @include breakpoint($tab) {
                font-size: 26px;
            }
        }
        &.play {
            &:before {
                @extend .font-icon;
                @extend .icon-play;
                font-size: 18px;
                @include breakpoint($tab) {
                    font-size: 26px;
                }
            }
        }
    }
    .mute {
        width: 18px;
        height: 18px;
        display: inline-block;
        cursor: pointer;
        margin: 0 5px;
        @include breakpoint($tab) {
            width: 26px;
            height: 26px;
        }
        &:before {
            @extend .font-icon;
            @extend .icon-volume-mute;
            font-size: 18px;
            @include breakpoint($tab) {
                font-size: 26px;
            }
        }
        &.off {
            &:before {
                @extend .font-icon;
                @extend .icon-volume-low;
                font-size: 18px;
                @include breakpoint($tab) {
                    font-size: 26px;
                }
            }
        }
    }
}

.page-node-1136 .node--page--full {
    max-width: none;
    .field--type-paragraphs .entity-paragraphs-item .content {
        text-align: center;
        .field--name-title-field,
        .field--name-field-title,
        .field--name-field-tile-title {
            padding: 25px 0 10px;
        }
        .field--name-field-body {
            padding-top: 0;
            text-align: center;
            p {
                margin: 0;
            }
            a {
                color: $main;
                font-weight: 400;
            }
        }
    }
    .field--type-paragraphs>.field__items>.field__item.even {
        .field--name-field-image {
            .field__items .field__item:nth-child(1) {
                width: 74.85%;
                float: left;
                border-right: 4px solid #fff;
            }
            .field__items .field__item:nth-child(2) {
                width: 25.1%;
                float: left;
                border-left: 2px solid #fff;
            }
        }
    }
    .field--type-paragraphs>.field__items>.field__item.odd {
        .field--name-field-image {
            .field__items .field__item:nth-child(1) {
                width: 25.1%;
                float: left;
                border-right: 2px solid #fff;
            }

            .field__items .field__item:nth-child(2) {
                width: 74.85%;
                float: left;
                border-left: 4px solid #fff;
            }
        }
    }
}

.not_sale {
    width: 100%;
    display: inline-block;
    .inquire-button,
    .inquire-button-all {
        display: block;
        text-align: center;
        max-width: 210px;
        margin-top: 20px;
        background: $base;
        color: #fff;
        padding: 10px;
        font-size: 14px;
        padding: 10px 20px;
        border: 0;
        outline: none;
        // box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        margin: 20px auto 30px;
        @extend .helvetica-regular;
        @include breakpoint(1220px) {
            max-width: 240px;
        }
        &:hover,
        &:focus {
            color: #fff;
            text-decoration: none;
            background: #bc944f;
            /* Old browsers */
            background: -moz-linear-gradient(top, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #bc944f 1%, #d3b945 23%, #e2cb6f 52%, #d3b945 77%, #bc944f 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            // filter:  progid: DXImageTransform.Microsoft.gradient( startColorstr='#bc944f', endColorstr='#bc944f', GradientType=0);
            /* IE6-9 */
            transition: background-color 0.3s ease;
            background: $main;
        }
    }
    .inquire-button-all {
        max-width: 230px;
    }
}

.commerce-order-commerce-order {
    .field--name-commerce-order-total {
        min-width: 300px;
        display: table;
        margin: 0 0 50px auto;
        .component-title {
            @extend .minion;
            font-weight: normal;
            font-size: 18px;
        }
        .component-total {
            @extend .minion-bold;
            font-weight: bold;
            font-size: 24px;
            padding-right: 15px;
        }
    }
    .field--name-field-phone {
        padding-top: 0;
        .field__label {
            display: none;
        }
    }
    .field--name-commerce-customer-shipping,
    .field--name-commerce-customer-billing {
        @include breakpoint($tab) {
            width: 50%;
            display: inline-block;
            float: left;
        }
    }
    .field--name-field-company,
    .field--name-field-job,
    .field--name-field-vat,
    .field--name-field-doy {
        display: block;
        width: 50%;
        float: right;
        margin-left: 1px;
    }
    .field--name-field-invoice {
        padding-top: 15px;
        display: inline-block;
        width: 50%;
    }
    .field--name-field-order-comments {
        clear: both;
        width: 50%;
        display: inline-block;
        float: left;
    }
    .field--name-field-phone {
        padding-top: 15px;
        display: inline-block;
    }
    .field--name-field-invoice,
    .field--name-commerce-customer-shipping,
    .field--name-commerce-customer-billing,
    .field--name-field-company,
    .field--name-field-job,
    .field--name-field-vat,
    .field--name-field-doy,
    .field--name-field-order-comments {
        .field__label {
            @extend .minion;
            font-size: 24px;
            margin-bottom: 5px;
            line-height: 24px;
            font-weight: normal;
        }
        font-size: 17px;
    }
    .views-field-commerce-total {
        padding-right: 10px;
    }
    .views-field-line-item-title {
        font-size: 1px;
        color: transparent;
        .title-image-wrapper {
            font-size: 16px;
            color: #000;
            .cart-image-wrapper {
                display: inline-block;
                margin-right: 15px;
            }
            .title-wrapper {
                display: inline-block;
            }
        }
    }
}

.webform-confirmation {
    text-align: center;
    font-size: 20px;
}

.page-node-done {
    h1 {
        display: none;
    }
    .links {
        text-align: center;
        border: 1px solid;
        max-width: 200px;
        padding: 10px;
        margin: 10px auto 20px;
    }
}

.messages.error {
    color: #e72a2a;
    border: 1px solid #e72a2a;
}

.fieldset-wrapper .messages {
    border-radius: 0;
    box-shadow: none;
    opacity: 1;
    background: #fff;
    max-width: 600px;
    position: relative;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 5px;
    border: none!important;
    .close {
        display: none;
    }
}

fieldset#edit-locale,
fieldset#edit-picture {
    display: none;
}

a.wishlist-enquiry {
    display: none!important;
}

.i18n-el .for-sale-0 a.wishlist-enquiry--el {
    display: block!important;
}

.i18n-en .for-sale-0 a.wishlist-enquiry--en {
    display: block!important;
}

// --------------- Breadcrumb ----------------
.l-breadcrumb {
    @extend .container;
    //line-height: 42px;
    padding: 0;
    padding-top: 30px;
    text-align: left;
    a {
        color: $black;
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
        @include breakpoint($tab) {
            font-size: 13px;
        }
    }
    li {
        display: inline-block;
        margin-right: 5px;
        position: relative;
        padding-right: 12px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 300;
        @include breakpoint($tab) {
            font-size: 13px;
        }
        &:after {
            content: '>';
            position: absolute;
            top: 0;
            right: 0;
        }
        &:before {
            display: none;
        }
        &:last-child:after {
            display: none;
        }
        &:last-child {
            font-weight: 700;
        }
    }
}

//Rolex Page
.page-node-1456,
.page-node-1546 {
    .node--page--full {
        .node__title {
            display: none;
        }
        .field--name-body {
            max-width: 100%;
        }
    }
}

//Rolex Bottom Block
.rolex-native-info,
.block--block-12 {
    font-family: Helvetica,Arial,sans-serif;
    text-align: center;
    margin: 0 auto 100px;
    max-width: 800px;
    .subtitle {
        font-family: Helvetica,Arial,sans-serif;
        font-size: 13px;
        margin: 0;
        color: #8A733D;
        /*#a37e2c;*/
        margin-top: 35px;
        @include breakpoint($tab) {
            font-size: 18px;
        }
    }
    .title {
        font-family: Helvetica,Arial,sans-serif;
        position: relative;
        margin-bottom: 35px;
        font-family: inherit;
        font-weight: lighter;
        color: #212121;
        font-size: 22px;
        padding: 0 20px 20px;
        @include breakpoint($tab) {
            font-size: 26px;
        }
        &:after {
            position: absolute;
            width: 50px;
            height: 2px;
            margin-left: -25px;
            left: 50%;
            bottom: -10px;
            background-color: #212121;
            content: "";
        }
    }
    .content {
        font-family: Helvetica,Arial,sans-serif;
        font-size: 14px;
        line-height: 24px;
        color: #737373;
        /*#818181*/
        max-width: 640px;
        padding: 0 20px;
        margin: 0 auto;
    }
}

.node-type-webform {
    .field--name-body.field--type-text-with-summary {
        display: none;
    }
}

.bean-menu-marketing-post {
    img {
        width: 100%;
        height: auto;
        display: block;
        max-width: 200px;
        @include breakpoint($desk) {
            max-width: 300px;
            @include breakpoint($desk) {
                max-width: none;
            }
        }
    }
    .field--name-field-link {
        text-align: center;
        a {
            color: #8A733D;
        }
    }
}

.l-region--footer>div.espa {
    margin-top: 30px;
    @include breakpoint($tab) {
        width: 100%;
        padding-left: 0;
        @include breakpoint($desk) {
            width: 50%;
            margin-top: 0;
            padding-left: 20px;
            @include breakpoint($desk) {
                width: 33%;
            }
        }
    }
}

.espa-banner {
    text-align: center;
    margin: 10px auto;
    @include breakpoint($desk) {
        margin: 0;
        text-align: right;
    }
}

.block--simple-instagram-feed {
    margin-top: 30px;
    // @include breakpoint($tab) {
    //   margin-top: 80px;
    // }
}

.instagram-feed {
    @extend .container;
    max-width: 1400px;
}

.instagram_gallery {
    padding: 0;
    // margin-bottom: 80px;
    .instagram-image,
    .instagram-sidecar,
    .instagram-video {
        position: relative;
        img {
            width: 100%;
            height: auto;
        }
        .instagram-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            color: #fff;
            display: none;
            .instagram-like-wrapper {
                text-align: center;
                margin-bottom: 20px;
                .instagram-like {
                    position: relative;
                    padding-left: 30px;
                    display: inline-block;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translatey(-50%);
                        display: block;
                        background-image: url('../svg/heart-outlined.svg');
                        background-repeat: no-repeat;
                        background-size: 20px;
                        background-position: center;
                        width: 20px;
                        height: 20px;
                        filter: brightness(0) invert(1)
                    }
                }
            }
            .instagram-title {
                padding: 0 20px;
            }
        }
        &:hover {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
            }
            .instagram-content {
                display: block;
            }
        }
    }
}

.transition {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.transition-transform {
    -webkit-transition: webkit-transform 300ms ease-in-out;
    -moz-transition: moz-transform 300ms ease-in-out;
    -ms-transition: ms-transform 300ms ease-in-out;
    -o-transition: o-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
}

.transition-slow {
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
}

.transition-none {
    -webkit-transition: all 0ms ease-in-out;
    -moz-transition: all 0ms ease-in-out;
    -ms-transition: all 0ms ease-in-out;
    -o-transition: all 0ms ease-in-out;
    transition: all 0ms ease-in-out;
}

.page-node-1546 .node--page--full {
    max-width: 100%;
    padding: 0px 0px;
}
.page-node-1546 .rolex-native-info .subtitle,
.page-node-1546 .block--block-12 .subtitle{
    margin-top:0px;
}

.container,
.view-marketing-posts,
.eshop-info .block__content,
.l-breadcrumb,
.instagram-feed {
    width: 100%;
}

.header-top {
    background: #000;
    padding: 10px 0;
    text-align: center;
    .block__content {
        color: #fff;
    }
    p {
        margin: 0;
    }
}

.l-popup {
    position: fixed;
    background: #212121;
    padding: 20px;
    display: none;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    bottom: 40px;
    margin: 0 auto;
    @include breakpoint($tab) {
        right: 20px;
        left: unset;
        transform: none;
        width: auto;
        margin: 0;
    }
    .popup-block {
        .block__title {
            color: #fff;
            font-size: 22px;
            margin: 0 0 20px;
        }
        .block__content {
            color: #fff;
            text-align: center;
            font-size: 14px;
            line-height: 19px;
        }
    }
    .close-popup-block {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        background-image: url('../images/icons/close.svg');
        width: 15px;
        height: 15px;
        filter: brightness(0) invert(1);
        background-size: 15px;
        cursor: pointer;
    }
}

.show-popup {
    .l-popup {
        display: block;
    }
}


#mc_embed_signup .asterisk{
    color: #A32C14!important; //pre wcag #e85c41;
}
#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error{
    color: #A32C14!important; //pre wcag #e85c41;
}


.page-user ,
.page-user-addressbook,
.page-user-addressbook-shipping,
.page-user-addressbook-billing,
.page-user-orders
{

    .user-profile .user-links{
        text-align: center;
    }

    .commerce-order-commerce-order {
        .field--name-field-invoice .field__label, 
        .field--name-commerce-customer-shipping .field__label, 
        .field--name-commerce-customer-billing .field__label, 
        .field--name-field-company .field__label, 
        .field--name-field-job .field__label, 
        .field--name-field-vat .field__label, 
        .field--name-field-doy .field__label, 
        .field--name-field-country .field__label,
        .field--name-field-order-comments .field__label,
        .field-name-commerce-order-mail .field__label,
        .field-name-commerce-order-mail .field-label
        {
            font-family: "proxima-nova","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
            // font-size: 24px;
            // line-height: 24px;
            // font-weight: normal;
            margin-bottom: 5px;
            font-size: 17px;
            font-weight: bold;
            line-height: 1.25;
            color: #000;
        }
    }

    .field--name-commerce-customer-billing,
    .field--name-commerce-customer-shipping{
        margin-top:20px;
        margin-bottom:20px;
    } 
    .field--label-inline .field__label,
    .field--label-inline .field__items{
        float:unset;
    }
    .field--label-inline .field__label{
        display: inline-block;
        position: unset;
        font-size: 17px;
        line-height: 1.25;
        color: #000;
    }
    .view-commerce-addressbook-defaults .view-id-commerce_addressbook_defaults{
        background: #f0f0f0;
        padding: 20px;
        height:100%;
    }
    
    .view-commerce-addressbook .billing-addressbook #commerce-addressbook-billing-default .views-row .views-field-rendered-entity{
        background: #f0f0f0;
        padding: 20px;
        height:100%;
    }
}


.page-user-addressbook-billing .view-commerce-addressbook-defaults .views-row .views-field-rendered-entity,
.page-user-addressbook-shipping .view-commerce-addressbook-defaults .views-row .views-field-rendered-entity,
.billing-addressbook .view-commerce-addressbook-defaults .views-row .views-field-rendered-entity, 
.shipping-addressbook .view-commerce-addressbook-defaults .views-row .views-field-rendered-entity{
    width:fit-content;
    background: #f0f0f0;
    padding: 20px;
    height:100%;
    min-height:100%;
    min-height: 170px;
}

.billing-addressbook .view-commerce-addressbook-defaults .views-row .views-field-rendered-entity ,
.shipping-addressbook .view-commerce-addressbook-defaults .views-row .views-field-rendered-entity {
    background: #f0f0f0;
    padding: 20px;
}

.view-commerce-addressbook table tbody tr td .views-field-rendered-entity,
#addressbook-list .shipping-addressbook table tbody tr td .views-field-rendered-entity, 
#addressbook-list .billing-addressbook table tbody tr td .views-field-rendered-entity{
    min-height: 100%;
    min-height: 170px;
    height:100%;
}

.view-commerce-addressbook table tbody tr td,
#addressbook-list .shipping-addressbook table tbody tr td, 
#addressbook-list .billing-addressbook table tbody tr td {
    padding: 10px;
}

.view-commerce-addressbook table tbody tr:nth-child(2n+1),
#addressbook-list .shipping-addressbook table tbody tr:nth-child(2n+1), 
#addressbook-list .billing-addressbook table tbody tr:nth-child(2n+1){
    padding: 0 10px;
}

.view-commerce-addressbook {
    table tbody tr:nth-child(2n+1) {
        background: unset;
    }

    .views-field-rendered-entity{
         width: fit-content;
        background: #f0f0f0;
        padding: 20px;
    }
}

.view-commerce-addressbook table tbody tr td {
    padding: 10px;
}

.shipping-addressbook .view-commerce-addressbook-defaults .views-row{


}

#addressbook-list .shipping-addressbook table tbody tr:nth-child(2n+1), 
#addressbook-list .billing-addressbook table tbody tr:nth-child(2n+1){

}