// Font variables.
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&subset=greek');
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&subset=greek');

@import url('https://use.typekit.net/fxs6qrj.css');


/* This stylesheet generated by Transfonter (https://transfonter.org) on November 21, 2017 11:50 AM */

// @font-face {
//     font-family: 'Arial';
//     src: url('../type/Arial-BoldMT.eot');
//     src: url('../type/Arial-BoldMT.eot?#iefix') format('embedded-opentype'),
//         url('../type/Arial-BoldMT.woff') format('woff'),
//         url('../type/Arial-BoldMT.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Arial';
//     src: url('../type/ArialMT.eot');
//     src: url('../type/ArialMT.eot?#iefix') format('embedded-opentype'),
//         url('../type/ArialMT.woff') format('woff'),
//         url('../type/ArialMT.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Minion Greek';
//     src: url('../type/MinionGreekRegular.eot');
//     src: url('../type/MinionGreekRegular.eot?#iefix') format('embedded-opentype'),
//         url('../type/MinionGreekRegular.woff') format('woff'),
//         url('../type/MinionGreekRegular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Minion Greek';
//     src: url('../type/MinionGreekBold.eot');
//     src: url('../type/MinionGreekBold.eot?#iefix') format('embedded-opentype'),
//         url('../type/MinionGreekBold.woff') format('woff'),
//         url('../type/MinionGreekBold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }

// $custom_font: 'Helvetica Neue', Helvetica;
$custom_font: 'proxima-nova','Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$font-family-base: $custom_font;
$custom_font_serif: $custom_font;
$line-height-base: 1.25;
$font-size-base: 16px;
$font-size-large: 20px;
$font-size-small: 12px;
$font-size-h1: 30px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 14px;
$font-size-h6: 12px;
$headings-font-weight: 400;

// Other variables
$border-radius-base: 0;
$brand-primary: #000;
$text-color: #000;

// Breakpoint variables.
$mob: 320px;
$tab: 720px;
$desk: 960px;
$wide: 1220px;

// Grid variables.
$container-tablet: 720px;
$container-desktop: 1000px;

// Sidebar
$sidebar_tab: 150px;
$sidebar_desk: 210px;

$red: #e53935;
$orange: #f4511e;
$eden: #303030;
$grey: #fafafa;
$indigo: #3949ab;
$blue: #1e88e5;
$black: #000000;
$gold: #8A733D; /*#a37e2c*/;
$gold-dimitris: #cba944;
$gold-vasilis: #8A733D;
$gold-agelos: #8A733D;

$main: $gold-agelos;
$base: $black;
$neutral: $grey;

.helvetica {
    // font-family: 'Helvetica Neue', Helvetica;
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-weight: 300;
}

.helvetica-regular {
    // font-family: 'Helvetica Neue', Helvetica;
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-weight: 400;
}

.helvetica-bold {
    // font-family: 'Helvetica Neue', Helvetica;
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-weight: 700;
}


.minion {
    font-family: "minion-pro",serif;
    font-weight: 400;
}

.minion-bold {
    font-family: "minion-pro",serif;
    font-weight: 700;
}
