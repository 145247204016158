.block-newsletter {
  display: table;
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 2px solid #000;
  margin-bottom: 30px;
  padding: 10px 0 0 0;

  @include breakpoint($desk) {
    padding: 35px 0 30px 0;
  }

  .block__content {
    margin: 0 auto;
    max-width: 400px;

    @include breakpoint($desk) {
      max-width: 100%;
    }

    & > h3 {
      margin: 0 0 10px;
      font-weight: 400;
      position: relative;
      text-align: center;
      font-size: 25px;
      line-height: 1.3;

      @include breakpoint($desk) {
        font-size: 35px;
        text-align: left;
        float: left;
        margin: 0;
        width: 240px;

        @include breakpoint($wide) {
          width: 270px;
        }
      }

      small {
        display: block;
        font-size: 12px;

        @include breakpoint($desk) {
          position: absolute;
          left: 0;
          top: -7px;
        }
      }
    }

    & > p {
      @include letter-spacing(0);
      margin-bottom: 10px;
      padding-top: 8px;
      display: none;

      @include breakpoint($desk) {
        display: block;
        float: left;
        text-align: center;
        margin: 0;
        width: 380px;
      }
    }

    & > form {
      margin-bottom: 10px;
      border-bottom: 1px solid #000;
      padding: 15px 0 6px 0;
      position: relative;

      @include breakpoint($desk) {
        float: right;
        text-align: right;
        margin: 0;
        width: 280px;

        @include breakpoint($wide) {
          width: 340px;
        }
      }

      input.form-text {
        display:block;
        width:100%;
        padding: 0 70px 0 0;
        border:0;
        background: transparent;
        font-size:10px;
        letter-spacing: 1px;
        outline: none;

        @include breakpoint($desk) {

          @include breakpoint($wide) {

          }
        }
      }

      button.form-submit {
        position: absolute;
        top: 10px;
        right: 0;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 400;
        border: 0;
        background: transparent;
        cursor: pointer;
      }
    }
  }
}
