.node-type-rolex-page,
.node-type-rolex-page-iframe,
.node-type-rolex-stores {
	font-family: Helvetica,Arial,sans-serif;

	.ajax-progress,
	.ajax-progress-throbber {
	  position: fixed;
	  z-index: 112;
	  background: rgba(0,0,0,0.2);
	  width: 100%;
	  height: 100%;
	  top:  0;
	  left: 0;

	  .throbber {
	  	position: fixed;
	    background: none;
	    width: 80px;
	    height: 80px;
	    content: url('../svg/store-selected.svg');
	    top: 50%;
	    left: calc(50% - 40px);
	    transform: translate(-50%,-50%);
	    font-size: 50px;
			-webkit-animation: spin 2s infinite linear;
			animation: spin 2s infinite linear;
			z-index: 112;
	  }
	}

	a {
		&:hover {
			text-decoration: none;
		}
	}

	#page {
		background: #fff;
	}

	.page-title,
	.node__title {
		display: none;
	}

	.rolex-header-region {
		display: flex;
		align-items: center;
		max-width: calc(90% + 40px);
		margin: 0 auto;
		padding: 10px 5vw;
		position: relative;
		font-family: arial, helvetica, sans-serif;

		@include breakpoint($tab) {
			padding: 20px 5vw;
			max-width: 100%;
		}

		.rolex-logo {
			img {
				display: block;
			}
		}

		.rolex-menu-navigation {
			margin-left: auto;
			position: initial;

			.rolex-header-menu-button {
		    font-size: 14px;
		    font-weight: 400;
		    cursor: pointer;
		    transition: background-color .3s linear,color .3s linear,opacity .3s linear;
		    opacity: 30%;
		    color: #000;
		    position: relative;
		    padding-right: 20px;

		    &:before {
	        border-style: solid;
			    border-color: #000;
			    border-width: 2px 2px 0 0;
			    content: "";
			    height: 9px;
			    width: 9px;
			    position: absolute;
			    right: 0;
			    top: 75%;
			    margin-left: -6px;
			    margin-top: -4px;
			    transform: translateY(-50%) rotate(135deg);
    			// opacity: 30%;
		    }
			}

			&.open-rolex-menu {
				.rolex-header-menu-button {
					&:before {
				    top: 100%;
				    transform: translateY(-50%) rotate(-45deg);
			    }
				}

				.region--rolex-header {
					display: block;
				}
			}
		}

		.rolex-menu-navigation {
			margin-left: auto;
		}

		.region--rolex-header {
			margin-left: auto;
			display: none;
	    position: absolute;
	    left: 0;
	    top: 100%;
	    width: 100%;
	    right: 0;
	    background: #fff;
      z-index: 1;

			@include breakpoint($tab) {
				display: block;
				position: relative;
				top: unset;
				left: unset;
				width: auto;
			}

			nav.rolex-main-menu > ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-end;

				li {
			    margin-right: 0;
			    width: 100%;
			    text-align: center;
			    border-bottom: 1px solid #aaaaaa;
			    padding: 8px 0;

			    @include breakpoint($tab) {
						margin-right: 30px;
						width: auto;
						text-align: left;
						border-bottom: 0;
						padding: 5px 0;
					}

					&:first-child {
						border-top: 1px solid #aaaaaa;

						@include breakpoint($tab) {
							border-top: 0;
						}
					}

					&:last-child {
						margin-right: 0;
					}

					a {
						color: #000;
				    font-size: 16px;
				    font-weight: 400;
						transition: background-color .3s linear,color .3s linear,opacity .3s linear;
						line-height: 24px;
				    font-size: 14px;
				    padding: 0;

						@include breakpoint($tab) {   
					    font-size: 16px;
					  }

						&:hover {
							color: #127749;
					    opacity: 1;
						}
					}

					&.active-trail a {
						color: #127749;
					}
				}

				.rolex-btn {
					a {
						color: #000;
				    font-size: 16px;
				    font-weight: 400;
						transition: background-color .3s linear,color .3s linear,opacity .3s linear;
						line-height: 24px;
				    font-size: 14px;

				    @include breakpoint($tab) {  
					    display: inline-flex;
					    align-items: center;
					    font-size: 16px;
					    padding: 0 30px;
					    height: 40px;
					    border-radius: 10rem;
					    border: 1px solid;
					    cursor: pointer;
					  }
		      }

			    &.rolex-btn-outline-green a {
	    	    border-color: #127749;
						color: #127749;
						background-color: transparent;

						&:hover,
						&.active {
					    color: #fff;
					    background-color: #127749;
					    border-color: #127749;
						}
			    }

	        &.menu-item--active-trail.rolex-btn-outline-green {
	        	a {
	        		color: #fff;
					    background-color: #127749;
					    border-color: #127749;
	        	}
	        }

			    a:hover {
	    	    opacity: 1;
			    }
				}
			}
		}
	}

	.rolex-footer-region {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
		flex-wrap: wrap;

		.footer-logo-wrapper {
			width: 100%;
			text-align: center;
			margin-bottom: 20px; 

			img {
				max-width: 100px;
				display: block;
				margin: 0 auto;
			}

			&.till-mobile {
				margin-top: 30px;
				margin-bottom: 0;

				img {
					max-width: 70px;
				}
			}
		}

		.rolex-scroll-to-top-wrapper {
			width: 100%;
			text-align: center;
			margin-top: 30px; 

			button {
				height: 50px;
		    width: 50px;
		    background-color: #127749;
		    border: 1px solid #127749;
		    position: relative;
		    font-size: 0;
		    cursor: pointer;
		    display: inline-block;
		    border-radius: 50%;
		    padding: 0;
		    box-shadow: none;

		    &:before {
		    	border-style: solid;
			    border-color: #e6e6e6;
			    border-width: 1px 1px 0 0;
			    content: "";
			    height: 12px;
			    width: 12px;
			    position: absolute;
			    left: 50%;
			    top: 50%;
			    margin-left: -6px;
			    margin-top: -4px;
			    transform: rotate(-45deg);
		    }

		    &:hover {
		    	background-color: #fff;

		    	&:before {
		    		border-color: #127749;
		    	}
		    }
			}
		}

		.region-rolex-footer {
			width: 100%;
			border: 1px solid #e6e6e6;
			border-right: 0; 
			border-left: 0; 
			font-family: arial, helvetica, sans-serif;

			@include breakpoint($tab) {
				padding: 12px 0; 
			}

			nav.rolex-footer-menu > ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				padding: 0 10px;

				li {
					width: 100%;
					border-bottom: 1px solid #e6e6e6;
					text-align: center;
    			padding: 11px 0;

					@include breakpoint($tab) { 
						margin-right: 25px;
						width: auto;
						border-bottom: 0;
						text-align: left;
						padding: 5px 0;
					}

					&:last-child {
						border-bottom: 0;
					}

					&:last-child {
						margin-right: 0;
					}

					a {
						color: #000;
				    font-size: 16px;
				    font-weight: 400;
						transition: background-color .3s linear,color .3s linear,opacity .3s linear;
						line-height: 25px;

						@include breakpoint($tab) { 
					    font-size: 16px;
					    line-height: 16px;
					  }

						&:hover {
							color: #127749;
					    opacity: 1;
						}
					}

					&.menu-item--active-trail a {
						color: #127749;
					}
				}

				.rolex-btn {
					a {
				    display: inline-flex;
				    align-items: center;
				    font-size: 16px;
				    font-weight: 400;
				    padding: 0 30px;
				    height: 40px;
				    border-radius: 10rem;
				    border: 1px solid;
				    cursor: pointer;
		        transition: background-color .3s linear,color .3s linear,opacity .3s linear;
		      }

			    &.rolex-btn-outline-green a {
	    	    border-color: #127749;
						color: #127749;
						background-color: transparent;

						&:hover {
					    color: #fff;
					    background-color: #127749;
					    border-color: #127749;
						}
			    }

	        &.menu-item--active-trail.rolex-btn-outline-green {
	        	a {
	        		color: #fff;
					    background-color: #127749;
					    border-color: #127749;
	        	}
	        }

			    a:hover {
	    	    opacity: 1;
			    }
				}
			}
		}
	}

	.main-layout,
	.l-main > .l-content.container {
		max-width: 100%;
		padding: 0;

		.l-messages {
			@extend .container;
		}
	}
}

// .grid-1.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-paragraphs > .field__item,
.grid-1.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-multiple-paragraphs > .field__items > .field__item {
	width: 100%;
}

// .grid-2.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-paragraphs > .field__item,
.grid-2.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-multiple-paragraphs > .field__items> .field__item {
  width: calc(50% + 10px);
  padding: 0 10px;
  margin: 0 -10px;
}

// .grid-3.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-paragraphs > .field__item,
.grid-3.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-multiple-paragraphs > .field__items > .field__item {
	width: 50%;

  @include breakpoint($tab) {
		width: 33.333%;
	}
}

// .carousel.grid-3.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-paragraphs,
.carousel.grid-3.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-multiple-paragraphs {
	margin: 0 -10px;

	&:not(.slick-initialized) > .field__items > .field__item {
		display: none;
		width: 50%;

	  @include breakpoint($tab) {
			width: 33.333%;
			padding: 0 10px
		}

		&:nth-child(1),
		&:nth-child(2) {
			display: inline-block;
		}

		&:nth-child(3) {
			display: none;

			@include breakpoint($tab) {
				display: inline-block;
			}
		}
	}

	&.slick-initialized {
		.field__item {
			display: inline-block;
		}
	}
}

// .grid-4.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-paragraphs > .field__item,
.grid-4.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-multiple-paragraphs > .field__item {
	width: 25%;
}

.paragraph--type--rolex-hero-banner {
	font-family: arial, helvetica, sans-serif;

	img {
		width: 100%;
		height: auto;
		display: block;
	}

	.rolex-image,
	.rolex-mobile-image {
		margin-bottom: 50px;
	}

	.rolex-hero-banner-content {
    max-width: 650px;
    margin: 0 auto 50px;
    text-align: center;
    width: 90vw;

    @include breakpoint($tab) {
	    width: 100%;
    }

    p {
    	text-align: inherit;
    }
	}

	.field--name-field-rolex-subtitle {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #000;

    @include breakpoint($tab) {
	    font-size: 16px;
	  }
	}	

	.field--name-field-rolex-title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 20px;
    color: #000;
    letter-spacing: .1em;
    @include breakpoint($tab) {
	    font-size: 38px;
	  }
	}

	.field--name-field-rolex-body {
    font-size: 16px;
    font-weight: lighter;
    color: #000;
    line-height: 1.2;
	}

	.rolex-btn {
		margin-top: 30px;
		
		a {
	    display: inline-flex;
	    align-items: center;
	    font-weight: 400;
	    padding: 0 25px;
	    height: 36px;
      font-size: 14px;
	    border-radius: 10rem;
	    border: 1px solid;
	    cursor: pointer;
      transition: background-color .3s linear,color .3s linear,opacity .3s linear;

      @include breakpoint($tab) {
      	height: 40px;
      	font-size: 16px;
      	padding: 0 30px;
      }
    }

    &.rolex-btn-secondary a {
      border-color: #127749;
	    color: #fff;
	    background-color: #127749;

			&:hover {
        color: #127749;
    		background-color: #fff;
			}
    }

    a:hover {
	    opacity: 1;
    }
	}
}

.paragraph--type--rolex-multiple-banners {
	// .slick-initialized.field--name-field-rolex-paragraphs,
	.slick-initialized.field--name-field-rolex-multiple-paragraphs > .field__items {
		position: relative;
		margin: 0 -10px;
	}

	.slick-track {
		.slick-slide {
			padding: 0 10px;
		}
	}

	.slick-list {
    padding-bottom: 40px;
	}

	.slick-prev, 
	.slick-next {
		position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    box-shadow: none;
    display: none !important;

    @include breakpoint($tab) {
	    display: block !important;
	  }
	}

	.slick-prev {
    left: -25px;
    display: block;
    background-color: #fff;
    top: calc(50% - 25px);
    width: 28px;
    height: 28px;
    border-radius: 10rem;

    @include breakpoint($tab) {
	    left: -45px;
    }

    &:before {
	    display: block;
	    border-style: solid;
	    border-color: #127749;
	    border-width: 2.5px 2.5px 0 0;
	    content: "";
	    height: 12px;
	    width: 12px;
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    margin-left: -6px;
	    margin-top: -4px;
	    transform: translate(-50%,-50%) rotate(-135deg);
    }
	}

	.slick-next {
    right: -25px;
    display: block;
    background-color: #fff;
    top: calc(50% - 25px);
    width: 28px;
    height: 28px;
    border-radius: 10rem;

    @include breakpoint($tab) {
      right: -55px;
    }

    &:before {
	    display: block;
	    border-style: solid;
	    border-color: #127749;
	    border-width: 2.5px 2.5px 0 0;
	    content: "";
	    height: 12px;
	    width: 12px;
	    position: absolute;
      left: 55%;
	    top: 60%;
	    margin-left: -6px;
	    margin-top: -4px;
	    transform: translate(-50%, -50%) rotate(44deg);
    }
	}

	.slick-dots {
		position: relative;
		bottom: unset;
    display: flex !important;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;

		li {
	    width: 10px;
	    height: 4px;
	    padding: 0;
	    margin: 0 10px;
	    list-style-type: none;

	    @include breakpoint($tab) {
	    	width: 60px;

	    	@include breakpoint($desk) {
			    width: 80px;
			  }
		  }

			button {
				font-size: 0;
		    line-height: 0;
		    display: block;
		    width: 10px;
		    height: 20px;
		    padding: 5px;
		    cursor: pointer;
		    color: transparent;
		    border: 0;
		    outline: none;
		    background: transparent;
				box-shadow: none;
		    background-color: #767676;
		    height: 4px;
		    border-radius: 10rem;
        padding: 0;
        height: 10px;

		    @include breakpoint($tab) {
		    	width: 60px;
		    	height: 4px;

			    @include breakpoint($desk) {
				    width: 80px;
				  }
			  }

			  &:before {
			  	display: none;
			  }
			}

			&.slick-active button {
				background-color: #127749;
			}
		}
	}
}
.paragraph--type--rolex-multiple-banners {
  max-width: 1140px;
  margin: 0 auto 50px;
  width: calc(90vw - 10px);
  font-family: arial, helvetica, sans-serif;

  @include breakpoint($tab) {
	  width: 100%;
	}

	.rolex-multiple-banners-title {
		margin: 0;
		margin-bottom: 30px;
    font-size: 14px;
    font-weight: 700;

    @include breakpoint($tab) {
	    font-size: 16px;
		}

		h3,p {
			margin: 0;
	    font-size: 14px;
	    font-weight: 700;

	    @include breakpoint($tab) {
	    font-size: 16px;
	  }
		}
	}

	.rolex-multiple-banners-paragraphs {
		// .field--name-field-rolex-paragraphs,
		.field--name-field-rolex-multiple-paragraphs >  .field__items{
			display: flex;
			flex-wrap: wrap;
      justify-content: space-between;

			> .field__item {
		    position: relative;
			}
		}
	}
}

.grid-3.paragraph--type--rolex-multiple-banners .rolex-multiple-banners-paragraphs .field--name-field-rolex-multiple-paragraphs > .field__items {
	justify-content: unset;
}

.category-banner-wrapper {
  transition: background-color .3s linear,color .3s linear,opacity .3s linear;
  text-align: center;
  display: block;	
  padding: 0 10px 30px;
  font-family: arial, helvetica, sans-serif;

  &:hover {
    opacity: 1;
    color: #000;
  }

  img {
  	width: 100%;
  	height: auto;
  	display: block;
  }

  .field--name-field-rolex-subtitle {
    font-size: 11px;
    font-weight: 700;
    color: #000;
    line-height: 1.5;

    @include breakpoint($tab) {
	    font-size: 13px;
	  }
  }

  .rolex-title {
    font-size: 11px;
    font-weight: 700;
    line-height: 1.5;
    color: #000;

    @include breakpoint($tab) {
	    font-size: 14px;
	  }
  }

  .rolex-watch-grid-item-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.03);
    transition: background-color .3s linear,color .3s linear,opacity .3s linear;
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
	  .rolex-watch-grid-item-hover {
	  	opacity: 1;
	  }
	}
}

a:focus {
	outline: 0;
}

.multiple-banner-wrapper,
.paragraph--type--rolex-multiple-banner {
	font-family: arial, helvetica, sans-serif;
	&:hover {
    color: #000;
  }

  img {
  	width: 100%;
  	height: auto;
  	display: block;
  }

  .rolex-image {
  	margin-bottom: 10px;
  }

  .rolex-mobile-image {
  	margin-bottom: 10px;
  }

  .rolex-title {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    color: #000;

    @include breakpoint($tab) {
	    font-size: 18px;
	  }
  }
}

.paragraph--type--rolex-full-content-paragraph {
  max-width: 1140px;
  margin: 0 auto 50px;
  text-align: center;
  width: calc(90vw - 10px);
  font-family: arial, helvetica, sans-serif;

  @include breakpoint($tab) {
    width: 100%;
  }

  &.has-video {
  	max-width: 100%;
	  margin: 0 5vw 50px;
	}

	.video-wrapper {
		position: relative;
	}

	.rolex-video-controls {
    position: absolute;
    width: auto;
    height: auto;
    padding: 0;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;

    span {
  		background-color: #fff;
      color: #127749;
      border-radius: 50%;
      position: relative;
      &:hover {
        background-color: #127749;
      	color: #fff;
      }
    }
    .play-pause {
      width: 40px;
      height: 40px;
      display: inline-block;
      cursor: pointer;
      margin: 0 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      @include breakpoint($tab) {
        width: 60px;
				height: 60px;
      }
      &:before {
        @extend .font-icon;
        @extend .icon-pause;
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @include breakpoint($tab) {
            font-size: 26px;
        }
      }
      &.play {
      	display: block;
        &:before {
          @extend .font-icon;
          @extend .icon-play;
          font-size: 18px;
          position: absolute;
	        top: 50%;
	        left: 50%;
	        transform: translate(-50%,-50%);
          @include breakpoint($tab) {
              font-size: 26px;
          }
        }
      }

      &.pause {
      	display: none;
      }
    }
	}

  img {
		width: 100%;
		height: auto;
		display: block;
	}

	.field--name-field-rolex-video-embeded	{
		padding-bottom: 30px;

		@include breakpoint($tab) {
			padding-bottom: 60px;
		}

		.embedded-video {
			position: relative;
	    padding-bottom: 56.25%;
	    height: 0;
	    overflow: hidden;
	    max-width: 100%;

	    iframe {
	    	position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
	    }
		}
	}

	.rolex-image {
		margin-bottom: 50px;
	}

	.rolex-mobile-image {
		margin-bottom: 50px;
	}

  .rolex-full-content-paragraph-content {
    max-width: 650px;
    margin: 0 auto 50px;
    text-align: center;
    width: 90vw;

    @include breakpoint($tab) {
	    width: 100%;
	  }

    p {
    	text-align: inherit;
    }
	}

	.field--name-field-rolex-title {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 400;

    @include breakpoint($tab) {
	    font-size: 38px;
	  }
	}

	.field--name-field-rolex-body {
    font-size: 16px;
    font-weight: lighter;
    margin-bottom: 30px;
    line-height: 1.5;
	}

	.rolex-btn {
		a {
	    display: inline-flex;
	    align-items: center;
	    font-weight: 400;
	    padding: 0 25px;
	    height: 36px;
      font-size: 14px;
	    border-radius: 10rem;
	    border: 1px solid;
	    cursor: pointer;
      transition: background-color .3s linear,color .3s linear,opacity .3s linear;

      @include breakpoint($tab) {
      	height: 40px;
      	font-size: 16px;
      	padding: 0 30px;
      }
    }

    &.rolex-btn-secondary a {
      border-color: #127749;
	    color: #fff;
	    background-color: #127749;

			&:hover {
        color: #127749;
    		background-color: #fff;
			}
    }

    a:hover {
	    opacity: 1;
    }
	}
}

.paragraph--type--rolex-full-absolute-content-paragraph {
  // max-width: 1440px;
  margin: 0 auto 50px;
  font-family: arial, helvetica, sans-serif;

  .rolex-image {
  	overflow: hidden;
  }

  img {
		width: 100%;
		height: auto;
		display: block;
    transform: scale(1);
    transition: transform 0.5s linear 0s;
	}

	&:hover {
		img {
	    transform: scale(1.04);
		}
	}

	.rolex-full-absolute-wrapper {
		position: relative;
		display: block;
	}

  .rolex-full-absolute-content-paragraph-content {
    // max-width: 650px;
    // margin: 0 auto 50px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    text-align: center;

    @include breakpoint($tab) {
	  }

    p {
    	text-align: inherit;
    }
	}

	.field--name-field-rolex-subtitle {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;

    @include breakpoint($tab) {
	    font-size: 16px;
	  }
	}	

	.field--name-field-rolex-title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 20px;
    letter-spacing: .1em;
    @include breakpoint($tab) {
	    font-size: 38px;
	  }
	}

	.field--name-field-rolex-body {
    font-size: 16px;
    font-weight: lighter;
    line-height: 1.2;
	}

	.rolex-btn {
		// a {
	    display: inline-flex;
	    align-items: center;
	    font-weight: 400;
	    padding: 0 25px;
	    height: 36px;
      font-size: 14px;
	    border-radius: 10rem;
	    border: 1px solid;
	    cursor: pointer;
      transition: background-color .3s linear,color .3s linear,opacity .3s linear;

      @include breakpoint($tab) {
      	height: 40px;
      	font-size: 16px;
      	padding: 0 30px;
      }
    // }

    // &.rolex-btn-secondary a {
    &.rolex-btn-secondary {
      border-color: #127749;
	    color: #fff;
	    background-color: #127749;

			&:hover {
        color: #127749;
    		background-color: #fff;
			}
    }

    a:hover {
	    opacity: 1;
    }
	}
}

.paragraph--type--rolex-half-image-half-text {
	margin: 0 5vw 50px;
	display: flex;
	flex-wrap: wrap;
	font-family: arial, helvetica, sans-serif;

	img {
		width: 100%;
		height: auto;
		display: block;
	}
	.image-wrapper,
	.rolex-hero-banner-content {
		width: 100%;

		@include breakpoint($tab) {
			width: 50%;
		}
	}

	.rolex-hero-banner-content {
		@include breakpoint($tab) {
			order: 2;
		}
	}

	.image-wrapper {
		margin-bottom: 20px;
		@include breakpoint($tab) {
			margin-bottom: 0;
			order: 1;
		}
	}

	.rolex-hero-banner-content {
		position: relative;

		p {
			text-align: left;
		}

		.rolex-hero-banner-content-container {
			@include breakpoint($tab) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
		    width: 30vw;
		  }
		}
	}

	&.reverse {
		.rolex-hero-banner-content {
			@include breakpoint($tab) {
				order: 1;
			}
		}

		.image-wrapper {
			@include breakpoint($tab) {
				order: 2;
			}
		}
	}

	.field--name-field-rolex-title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 10px;

    @include breakpoint($tab) {
	    font-size: 28px;
	  }
	}

	.field--name-field-rolex-body {
    font-size: 16px;
    font-weight: lighter;
	}

	.rolex-btn {
		margin-top: 20px;
		a {
	    display: inline-flex;
	    align-items: center;
	    font-weight: 400;
	    padding: 0 25px;
	    height: 36px;
      font-size: 14px;
	    border-radius: 10rem;
	    border: 1px solid;
	    cursor: pointer;
      transition: background-color .3s linear,color .3s linear,opacity .3s linear;

      @include breakpoint($tab) {
      	height: 40px;
      	font-size: 16px;
      	padding: 0 30px;
      }
    }

    &.rolex-btn-secondary a {
    // &.rolex-btn-secondary {
      border-color: #127749;
	    color: #fff;
	    background-color: #127749;

			&:hover {
        color: #127749;
    		background-color: #fff;
			}
    }

    a:hover {
	    opacity: 1;
    }
	}
}

.paragraph--type--rolex-half-image-half-text-colou {
	margin: 0 auto 50px;
	display: flex;
	flex-wrap: wrap;
	font-family: arial, helvetica, sans-serif;

	.field__label {
		display: none;
	}

	img {
		width: 100%;
		height: auto;
		display: block;
	}
	.image-wrapper,
	.rolex-hero-banner-content {
		width: 100%;

		@include breakpoint($tab) {
			width: 50%;
		}
	}

	.rolex-hero-banner-content {
		@include breakpoint($tab) {
			order: 2;
		}
	}

	.image-wrapper {
		@include breakpoint($tab) {
			order: 1;
		}
	}

	.rolex-hero-banner-content {
		position: relative;
		padding: 20px 5vw;

		@include breakpoint($tab) {
			padding: 0;
		}

		p {
			text-align: left;
		}

		.rolex-hero-banner-content-container {
			@include breakpoint($tab) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
		    width: 30vw;
		  }
		}
	}

	&.reverse {
		.rolex-hero-banner-content {
			@include breakpoint($tab) {
				order: 1;
			}
		}

		.image-wrapper {
			@include breakpoint($tab) {
				order: 2;
			}
		}
	}

	.field--name-field-rolex-title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 10px;

    @include breakpoint($tab) {
	    font-size: 28px;
	  }
	}

	.field--name-field-rolex-body {
    font-size: 16px;
    font-weight: lighter;
	}
}

.field--name-field-breadcrumbs-link {
	display: flex;
	align-items: center;
  padding: 5px 5vw;
  font-family: arial, helvetica, sans-serif;
  // border-top: 1px solid #ececec;

 //  @include breakpoint($tab) {
	//   padding: 5px 5vw;
	// }

	.field__items {
		display: flex;
		align-items: center;
	}

	.field__item {
    letter-spacing: 0.05em;
    position: relative;
    display: none;
    padding-left: 15px;

    @include breakpoint($tab) {
		  display: block;
		  padding-left: 0;
		  padding-right: 27px;
    	margin-right: 20px;
		}

    &:before {
	    left: 0;
	    top: 50%;
	    font-size: 1rem;
	    border-style: solid;
	    border-color: #000;
	    border-width: 2px 2px 0 0;
	    content: "";
	    height: 8px;
	    width: 8px;
	    position: absolute;
	    margin-left: -6px;
	    margin-top: -4px;
	    transform: rotate(-140deg);

	    @include breakpoint($tab) {
	    	transform: rotate(45deg);
	    	right: 0;
	    	left: unset;
			}
    }

    a {
    	color: #212121 !important;
	    cursor: pointer;
	    transition: color 0.3s ease 0s;
	    display: inline-flex;
	    -webkit-box-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    align-items: center;
	    font-style: normal;
	    font-size: 15px;
	    flex-direction: row;
      	font-weight: 400;

	    &:hover,
	    &:focus {
	    	text-decoration: none;
	    }
    }

    &:last-child {
    	pointer-events: none;
		  cursor: default;
		  text-decoration: none;
		  color: #212121;
		  padding-right: 0;
		  margin-right: 0;
		  display: block;

		  &:before {
		  	@include breakpoint($tab) {
			  	display: none;
			  }
		  }
    }
	}
}
	
.node--rolex-stores {
	font-family: arial, helvetica, sans-serif;
	&.node--full {
		.rolex-store-main-container {
	    max-width: calc(90% + 40px);
	    margin: 0 auto;
	    padding: 0 5vw 10px;

	    @include breakpoint($tab) {
	      padding: 0 5vw 20px;
	    	max-width: 100%;
	    }
	  }

		.node__title {
			display: none;
		}

		.store-open-hours-wrapper {
			margin-bottom: 20px;
			position: relative;

			.store-open-hours-main-info {
				font-size: 16px;
				cursor: pointer;
				display: inline-block;
				position: relative;
				padding-right: 30px;

				&:before {
					content: '';
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					background-image: url('../svg/keyboard_arrow_down.svg');
					background-repeat: no-repeat;
					background-size: 20px;
					width: 20px;
					height: 20px;
				}

				span {
					padding-right: 10px;
				}

				&.store-open span {
					color: #12784a;
				}

				&.store-close span {
					color: #BE0100;
				}
			}

			.store-open-hours-current-date {
				font-size: 16px;
			}

			.store-open-hours-shedule-wrapper {
		    background: #fff;
		    position: absolute;
		    width: 100%;
		    z-index: 1;
		    top: calc(100% + 15px);
		    display: none;
		    padding: 15px;
			}

			&.open-shedule {
				.store-open-hours-main-info {
					&:before {
						transform: translateY(-50%) rotate(-90deg)
					}
				}
				.store-open-hours-shedule-wrapper {
					display: block;
				}
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: flex;
					justify-content: space-between;
					margin-bottom: 10px;

					span {

					}
				}
			}
		}

		.store-container {
			display: flex;
			flex-wrap: wrap;

			.left-side {
				width: 100%;

				@include breakpoint($tab) {
					width: 50%;
				}

				.map {
		      height: 550px;

		      @include breakpoint($tab) {
			      height: 450px;
			    }
			  }
			}

			.right-side {
				width: 100%;
				background-color: #f7f7f7;
				display: flex;
		    flex-direction: column;
		    justify-content: center;
		    // align-items: center;
		    flex: 1 1 0%;
		    @include breakpoint($tab) {
					width: 50%;
				}

		    .rolex-right-side-container {
		    	margin: 30px 10vw 40px;
		    	text-align: center;
		   		@include breakpoint($tab) {
		   			margin: 0 10vw;
		   			text-align: left;
		   		}
		    }

		    .rolex-subtitle {
		    	color: #12784a;
		    	text-transform: uppercase;
		    	font-size: 9px;
		    	margin-bottom: 5px;

		    	@include breakpoint($tab) {
		    		margin-bottom: 0;
		    	}
		    }

		    .rolex-title {
		    	color: #212121;
    	    text-transform: uppercase;
  				font-weight: 400;
  				font-size: 24px;
  				margin-bottom: 10px;

  				@include breakpoint($tab) {
	  				font-size: 28px;
	  			}
		    }

		    .rolex-body {
		    	margin-bottom: 10px;
		    	font-size: 16px;
		    }

		    .text-links {
		    	display: flex;
			    width:100%;
		    	justify-content: center;

		    	@include breakpoint($tab) {
		    		display: block;
		    	}

					.text-links-icon {
						// width: 30px;
		  		// 	height: 30px;
					 // 	border-radius: 50%;
					 // 	background-color: #fff;
					 	// margin: 0 10px;
					 // 	transition: background-color .3s linear;

						@include breakpoint($tab) {
							margin: 0;
							margin-bottom: 10px;
							width: auto;
							height: auto;
							border-radius: 0;
							background-color: transparent;
						}

					 	a {
					 		text-indent: -99999px;
					 		width: 30px;
		  				height: 30px;
					 		position: relative;
					 		color: transparent;
			 		    display: inline-block;
    					margin: 0 10px;

		  				@include breakpoint($tab) {
		  					margin: 0;
		  					width: auto;
		  					height: auto;
						 		display: inline-block;
						 		font-size: 14px;
						 		color: #000;
						 		padding-left: 50px;
						 		line-height: 40px;
			  				font-size: 16px;
	  				    text-indent: 0;
			  			}

							&:before {
								content: '';
								width: 30px;
				  			height: 30px;
				  			display: block;
							 	border-radius: 50%;
							 	background-color: #fff;
							 	transition: background-color .3s linear;
							 	position: absolute;
							 	top: 0;
							 	left: 0;

							 	@include breakpoint($tab) {
							 		width: 40px;
				  				height: 40px;
							 	}
							}

							&:after {
								content: '';
							 	position: absolute;
							 	top: 0;
							 	left: 0;
								width: 30px;
				  			height: 30px;
				  			display: block;
								background-repeat: no-repeat;
								background-position: center;
								transition: filter .3s linear;

								@include breakpoint($tab) {
							 		width: 40px;
				  				height: 40px;
							 	}
							}
						}

						&.phone a:after {
							background-image: url(../svg/phone-green.svg);
							background-size: 10px;
						}
						&.directions a:after {
							background-image: url(../svg/location-green.svg);
							background-size: 10px;
						}
						&.send-message a:after {
							background-image: url(../svg/mail.svg);
							background-size: 15px;
						}

						a:hover {
							&:before {
								background-color: #127749;
							}
							&:after {
								filter: brightness(0) invert(1);
							}
						}
					}
				}
			}
		}
	}

	&.node-teaser {
    background-color: #f7f7f7;
    padding: 50px 0;

		@include breakpoint($tab) {
    	height: 100%;
    }

    .store-container {
    	display: flex;
    	flex-wrap: wrap;
    	justify-content: center;
    	height: 100%;
    }

		.rolex-title {
			margin-bottom: 10px;
			font-size: 13px;
			font-weight: 400;
			width: 100%;

			@include breakpoint($tab) {
				font-size: 18px;
			}
		}

		.rolex-body {
			margin-bottom: 30px;
			width: 100%;
			font-size: 11px;

			@include breakpoint($tab) {
				font-size: 12px;
			}
		}

		.text-links {
	    display: flex;
	    width:100%;
    	justify-content: center;

			@include breakpoint($tab) {
				margin-top: auto;
			}

			.text-links-icon {
	 	    width: 30px;
  			height: 30px;
			 	border-radius: 50%;
			 	background-color: #fff;
			 	margin: 0 10px;
			 	transition: background-color .3s linear;

			 	a {
			 		text-indent: -99999px;
			 		width: 30px;
  				height: 30px;
  				border-radius: 50%;
			 		display: block;
					background-repeat: no-repeat;
					background-position: center;
					transition: filter .3s linear;
				}

				&.phone a {
					background-image: url(../svg/phone-green.svg);
					background-size: 10px;
				}
				&.directions a {
					background-image: url(../svg/location-green.svg);
					background-size: 10px;
				}
				&.store-link a {
					background-image: url(../svg/plus.svg);
					background-size: 15px;
				}

				&:hover {
					background-color: #127749;

					a {
						filter: brightness(0) invert(1);
					}
				}
			}
		}
	}

	&.node--map-teaser {
    background-color: #f7f7f7;
    padding: 0;

    .rolex-store-image {
    	img {
    		width: 100%;
    		height: auto;
    		display: block;
    	}
    }

    .map-teaser-info {
    	padding: 50px 20px;
    	position: relative;
    	text-align: center;
    }

    .rolex-close-button {
    	position: absolute;
    	right: 10px;
    	top: 10px;
    	text-indent: -9999px;
    	background-image: url(../svg/close.svg);
	    width: 15px;
    	height: 15px;
    	background-size: 15px;
    	background-repeat: no-repeat;
    	cursor: pointer;
    }

		.rolex-title {
			margin-bottom: 10px;
			font-size: 13px;
			font-weight: 400;

			@include breakpoint($tab) {
				font-size: 18px;
			}
		}

		.rolex-body {
			margin-bottom: 30px;
			font-size: 11px;

			@include breakpoint($tab) {
				font-size: 12px;
			}
		}

		.text-links {
	    display: flex;
    	justify-content: center;

			.text-links-icon {
	 	    width: 30px;
  			height: 30px;
			 	border-radius: 50%;
			 	background-color: #fff;
			 	margin: 0 10px;
			 	transition: background-color .3s linear;

			 	a {
			 		text-indent: -99999px;
			 		width: 30px;
  				height: 30px;
  				border-radius: 50%;
			 		display: block;
					background-repeat: no-repeat;
					background-position: center;
					transition: filter .3s linear;
				}

				&.phone a {
					background-image: url(../svg/phone-green.svg);
					background-size: 10px;
				}
				&.directions a {
					background-image: url(../svg/location-green.svg);
					background-size: 10px;
				}
				&.store-link a {
					background-image: url(../svg/plus.svg);
					background-size: 15px;
				}

				&:hover {
					background-color: #127749;

					a {
						filter: brightness(0) invert(1);
					}
				}
			}
		}
	}
}

.rolex-map-all {
	max-width: 90%;
	margin: 0 auto 20px;
	font-family: arial, helvetica, sans-serif;

	@include breakpoint($tab) {
		max-width: 1140px;
	}

	.map-rolex-stores-tabs-wrapper {
		margin-bottom: 20px;

		.map-rolex-stores-tabs {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			margin: 0 -10px;

			li {
				color: #707070;
				font-size: 14px;
		    font-weight: 400;
		    margin: 0 10px;
		    position: relative;

		    @include breakpoint($tab) {
			    font-size: 16px;
			  }

			  &:before {
			  	content: '';
			  	height: 1px;
			  	width: 10px;
			  	background-color: #707070;
			  	position: absolute;
			  	top: 50%;
			  	right: -15px;
			  	transform: translateY(-50%);
			  	display: block;
			  }

			  &:last-child {
			  	&:before {
			  		display: none;
			  	}
			  }

			  &:not(.active-tab) {
			  	cursor: pointer;
			  }

				&.active-tab {
					color: #12784a;
				}
			}
		}
	}

	.tab-content-wrapper {
		.tab-content {
			display: none;

			&.active-tab-content {
				display: flex;
			}

			&.map {
				flex-wrap: wrap;
				background-color: #f7f7f7;

				#rolex-ajax-node {
					&.open-store {
						width: 100%;
						order: 2;

						@include breakpoint($tab) {
							max-width: 367px;
							order: 1;
						}
					}
				}

				#map_rolex_stores {
					width: 100%;

					&.open-store {
						max-width: 100%;
						order: 1;

						@include breakpoint($tab) {
							max-width: calc(100% - 367px);
							order: 2;
						}
					}
				}
			}
		}
	}

	#map_rolex_stores {
    height: 460px;

    @include breakpoint($tab) {
	    height: 700px;
	  }
	}
}

.rolex-stores-teasers {
	max-width: 1140px;
	margin: 0 auto;
  width: 100%;
  font-family: arial, helvetica, sans-serif;

	.view-content {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;

		.views-row {
			width: 100%;
			padding: 0 10px 20px;
			text-align: center;
			@include breakpoint($tab) {
				width: 33.333%;
			}
		}
	}
}

.rolex-contact-form {
	max-width: 650px;
	margin: 40px auto;
	width: 90%;
	font-family: arial, helvetica, sans-serif;

	@include breakpoint($tab) {
		width: 100%;
		margin: 80px auto;
	}


	.messages.messages--error {
		background: #BE0100;
    padding: 20px;
    color: white;
    margin-bottom: 40px;
    border: 1px solid #BE0100;
    font-family: arial, helvetica, sans-serif;

    button {
    	display: none;
    }

    ul {
    	margin: 0;
    	list-style: none;
    }
	}

	.webform-confirmation + .links {
		display: none;
	}

	.block__title {
		text-align: left;
		margin: 0 0 20px;
		font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: arial, helvetica, sans-serif;

		@include breakpoint($tab) {
			font-size: 28px;
		}
	}

	.node--webform.node-teaser {
		.field--name-body {
			font-size: 16px;
			margin-bottom: 20px;

			@include breakpoint($tab) {
				margin-bottom: 40px;
			}

			p {
				margin: 0;
			}
		}

		.webform-client-form {
			margin: 0 -10px;

		  input:-webkit-autofill {
		    -webkit-box-shadow: 0 0 0 1000px #fff inset;
		    -moz-box-shadow: 0 0 0 1000px #fff inset;
		    box-shadow: 0 0 0 1000px #fff inset;
		    -webkit-transition: all 0.7s ease 0s;
		    -moz-transition: all 0.7s ease 0s;
		    -o-transition: all 0.7s ease 0s;
		    transition: all 0.7s ease 0s;
			}

			.form-item {
	    	position: relative;

	    	&.webform-component--title,
	    	&.webform-component--first-name,
	    	&.webform-component--last-name {
	    		margin-bottom: 20px;

	    		@include breakpoint($tab) {
	    			margin-bottom: 40px;
	    		}
	    	}

				&.webform-component--email-address,
				&.webform-component--phone-number {
					width: 100%;
					margin-bottom: 20px;

					@include breakpoint($tab) {
						margin-bottom: 40px;
						width: 50%;
					}
				}

				&.webform-component--boutique-city {
					width: 100%;
					margin-bottom: 40px;

					@include breakpoint($tab) {
						margin-bottom: 60px;
					}
				}

				&.webform-component--your-message {
					label {
						font-size: 12px;
						text-transform: none;
						color: #212121;

						@include breakpoint($tab) {
							font-size: 14px;
						}
					}
				}

				&.webform-component-textarea {
					margin-bottom: 30px;

					label {
						margin-bottom: 10px;
						font-size: 12px;
						text-transform: none;
						color: #212121;
						font-weight: 300;

						@include breakpoint($tab) {
							font-size: 14px;
						}
					}

					textarea {
				    height: 100px;
  					border-radius: 0;
  					color: #212121;
  					border: 1px solid #212121;
  					opacity: 0.2;
					}

					::placeholder {
						font-size: 12px;
						text-transform: none;
						color: #212121;
						font-weight: 300;

						@include breakpoint($tab) {
							font-size: 14px;
						}
					}

					&.input-has-focus {
						::placeholder {
							color: #212121;
						}

						textarea {
					    opacity: 1;
						}
		    	}
				}

				&.webform-component-checkboxes {
					width: 100%;
					display: flex;
					margin-bottom: 14px;

					> label {
						display: none;
					}

					.form-type-checkbox {
						width: 100%;
						padding: 0;
						margin: 0;

						input {
							display: none;
						}

						label {
							font-size: 12px;
							text-transform: none;
							color: #212121;
							position: relative;
							padding-left: 32px;
							padding-right: 6px;
							line-height: 15px;
							display: block;

							@include breakpoint($tab) {
								display: initial;
								line-height: 22px;
								font-size: 14px;
							}

							&:before {
								content: '';
								width: 20px;
								height: 20px;
								display: block;
								border: 1px solid #212121;
								opacity: .2;
								position: absolute;
								top: 50%;
								left: 0;
								transform: translateY(-50%);
							}
						}

						input:checked + label {
							&:after {
								content: '';
								width: 14px;
								height: 14px;
								background-color: #127749;
								display: block;
								position: absolute;
								top: 50%;
								left: 3px;
								transform: translateY(-50%);
							}
						}

						input.error + label {
							color: #BE0100;
							&:before {
								border: 1px solid #BE0100;
							}

							a {
								color: #BE0100;
							}
						}
					}

					.description {
				    position: relative;
  					left: -15px;

  					@include breakpoint($tab) {
  						left: 0;
  					}
					}

					a {
						color: #127749;

						&:hover {
							text-decoration: underline;
						}
					}
				}

				&.webform-component-textfield,
				&.webform-component-email {
					display: flex;
					label {
				    margin: 0 0 10px;
				    position: absolute;
				    transform: translateY(60%);
				    color: #212121;
				    opacity: .5;
				    font-weight: 300;
				    text-transform: none;
				    font-size: 12px;
				    transition: transform .2s linear,opacity .2s linear,font-size .2s linear;
				    order: 2;

						@include breakpoint($tab) {
							font-size: 14px;
						}
		    	}

		    	input {
		    		order: 1;
		    		color: transparent;

		    		&.error {
		    			border-bottom: 1px solid #BE0100;
		    		}
		    	}

		    	input.error + label {
	    			color: #BE0100;
	    		}

		    	&.input-has-focus {
		    		label {
			    		opacity: 1;
			    		transform: translateY(-5px);
			    		margin-bottom: 0;
	    				font-size: 11px;

							@include breakpoint($tab) {
								font-size: 12px;
							}
						}

						input {
							color: #212121;
					    font-size: 12px;
					    opacity: 1;
							@include breakpoint($tab) {
								font-size: 14px;
							}
						}
		    	}
		    }
			}

			.form-actions {
				text-align: right;

				input {
					margin-top: 26px;
					margin-bottom: 40px;
					display: inline-flex;
			    align-items: center;
			    font-weight: 400;
			    padding: 0 25px;
			    height: 42px;
		      font-size: 14px;
			    border-radius: 10rem;
			    border: 1px solid;
			    cursor: pointer;
			    color: #fff;
			    background-color: #127749;
			    border-color: #127749;
		      transition: background-color .3s linear,color .3s linear,opacity .3s linear;

		      @include breakpoint($tab) {
		      	height: 40px;
		      	font-size: 16px;
		      	padding: 0 30px;
		      }

					&:hover {
		      	color: #127749;
				    background-color: #fff;
		      }
				}

				.rolex-contact-form-description {
					margin-bottom: 10px;
					font-size: 11px;
					color: #212121;
					opacity: .5;

					@include breakpoint($tab) {
		      	font-size: 12px;
		      }
				}
			}
		}

		.selector {
			border: 0;
	    margin: 0 0 20px;
	    display: inline-block;
	    width: 100%;

	    @include breakpoint($tab) {
	    	margin: 0 10px;
	    	float: left;
	   	 	width: calc(33.333% - 20px) !important;
	    }

	    .form-select {
  	    border: 0;
		    opacity: 1;
		    outline: 0;
		    background: #fff;
	    }
		}

		input[type="text"], 
		input[type="email"],
		.selector {
			border: 0;
			border-bottom: 1px solid #212121;
			opacity: 0.2;
    	border-radius: 0;
    	padding: 10px 0 3px;
    	color: #212121;
	    font-size: 12px;
			@include breakpoint($tab) {
				font-size: 14px;
			}
		}

		.selector {
			opacity: 0.5;
			border-bottom: 1px solid rgba(33,33,33,.4);

			&.input-has-focus {
				border-bottom: 1px solid rgba(33,33,33,1);
				opacity: 1;
			}
		}
	}
}

.rolex-contact-form .node--webform.node-teaser fieldset.rkpt-rolex-stores-element {
	width: 100%;

	> .fieldset-wrapper > .selector {
		width: 50%!important;
	}

	.form-item {
    width: 50%;
	}

	.selector {
    width: 100%!important;
    margin: 0;
	}
}

.page-node-3898 {
	.paragraph--type--rolex-full-content-paragraph {
		max-width: unset;
		width: unset;
    margin: 0 5vw 50px;

		.rolex-image {
			max-width: 1140px;
			margin: 0 auto 50px;
			width: calc(90vw - 10px);

			@include breakpoint($tab) {
				width: 100%;
			}
		}
	}
}
